import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { Attachment, User } from 'graphql/graphqlTypes';
import {
  ChangeSet,
  EditingState,
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  SearchState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Box, Button } from '@mui/material';
import {
  Grid,
  TableEditColumn,
  TableEditRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  GridEditActions,
  SelectedRow,
  StubCellComponent,
  SymphonyTable,
  SymphonyTableHeaderRow,
  TableEditCell,
  TableEditRows,
} from 'components/gridFormatters';
import { Getter } from '@devexpress/dx-react-core';
import { attachmentColumns } from './columnSettings/attachmentColumns';
import {
  gridColumnsSidePanelClosed,
  gridColumnsSidePanelOpen,
} from './columnSettings/gridColumns';
import { AttachmentEditCell } from './columnFormatters/attachmentEditCell';
import { AttachmentLabelCell } from './columnFormatters/attachmentLabelCell';
import SelectCellWithoutRowClick from 'components/home/grid/selectors/SelectCellWithoutRowClick';
import HeaderSelectCell from 'components/home/grid/selectors/HeaderSelectCell';
import Icon, { ICONS } from 'components/icon';
import {
  setIsInEditMode,
  updateDirtyTabs,
} from 'store/patientdetails/patientDetailsSlice';
import Loader from 'components/loader';
import { COLORS } from 'consts/styles';
import {
  getAvailableId,
  rearrangeColumnsEditPrelast,
} from './attachments.helpers';
import {
  closeUploadFile,
  openUploadFile,
} from 'store/fileUpload/fileUploadSlice';
import {
  commitAttachments,
  updateSelectedAttachmentIds,
} from 'store/patientdetails/patientDetails.attachment.slice';
import {
  attachmentColumnExtension,
  attachmentColumnExtensionsSidebarOpen,
} from 'components/home/attachmentsList/columnSettings/attachmentColumnExtension';
import SearchBar from 'components/searchbar';
import { InstantSearchBar } from 'components/searchbar/InstantSearchBar';
import UploadFilesDialog from 'components/uploadFiles';
import styled from 'styled-components';
import Information from 'components/modal/Information';

export interface IAttachmentsNewProps {
  attachments: Attachment[];
  episodeId?: number;
  isFetching: boolean;
  canAddFiles?: boolean;
  showInstantSearch?: boolean;
  readonly?: boolean;
  canSelectRow?: boolean;
  isAttachmentsTab?: boolean;
}

const StyledOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
`;

const AttachmentsGrid = (props: IAttachmentsNewProps) => {
  const {
    attachments,
    episodeId,
    isFetching,
    canAddFiles,
    showInstantSearch,
    readonly,
    canSelectRow,
    isAttachmentsTab,
  } = props;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [editingRowIds, setEditingRowIds] = useState<number[]>([]);
  const [editEnabled, setEditEnabled] = useState(true);
  const [loading] = useState(false);
  const [errors, setErrors] = useState<boolean>(false);

  const showUploadFilesDialog = useSelector(
    (state: IState) => state.fileUpload.open
  );
  const selectedAttachmentIds = useSelector(
    (state: IState) =>
      state.patientDetailsAttachment?.selectedAttachmentIds ?? []
  );
  const uploadedFiles = useSelector(
    (state: IState) => state.fileUpload.uploadedFiles
  );

  const selectedAttachmentToMove = useSelector(
    (state: IState) => state.patientDetailsAttachment.moveAttachmentId
  );

  const dispatch = useDispatch();

  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const currentUserName = useSelector(
    (state: IState) => state.user.currentUser.name
  );

  const gridColumns =
    selectedAttachmentToMove > 0
      ? gridColumnsSidePanelOpen
      : gridColumnsSidePanelClosed;

  const gridColumnExtensions =
    selectedAttachmentToMove > 0
      ? attachmentColumnExtensionsSidebarOpen
      : attachmentColumnExtension;

  const onAddFileClick = () => {
    dispatch(openUploadFile());
  };

  const handleCloseUploadFilesDialog = () => {
    dispatch(closeUploadFile());
  };

  useEffect(() => {
    dispatch(setIsInEditMode({ isInEditMode: !editEnabled }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEnabled]);

  const commitChanges = useCallback(
    (changeSet: ChangeSet) => {
      const { added = [], changed = {} } = changeSet;

      const hasErrors = [
        ...added,
        ...Object.entries(changed).map(([id, changes]) => ({
          ...attachments.find((att) => att.id === Number(id)),
          ...changes,
        })),
      ].some(
        (attachment) =>
          !attachment?.attachmentCategory?.id &&
          attachment.attachmentType === null
      );
      setErrors(hasErrors);

      if (!hasErrors) {
        dispatch(commitAttachments({ patientId, episodeId, changeSet }));
        dispatch(updateDirtyTabs(episodeId ? 'Episodes' : 'Attachments'));
      }
    },
    [dispatch, episodeId, patientId, attachments]
  );

  const handleAddFilesDialog = useCallback(() => {
    const availableId = getAvailableId(attachments, (x) => x.id);
    const newAttachments = uploadedFiles.map((file, index) => {
      return {
        id: availableId - index,
        description: 'Imported',
        createdByUser: {
          fullName: currentUserName,
        } as User,
        name: file.name,
        fileExtension: file?.name?.split('.').pop(),
        location: file?.fileId,
        createdOn: file.createdOn,
        attachmentCategory: { id: file.categoryId },
      };
    });
    const changeSet = { added: newAttachments };
    dispatch(commitAttachments({ patientId, episodeId, changeSet }));
    setEditEnabled(true);
    dispatch(closeUploadFile());
    dispatch(updateDirtyTabs(episodeId ? 'Episodes' : 'Attachments'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments, currentUserName, episodeId, patientId, uploadedFiles]);

  const handleSelectionChange = useCallback(
    (curSelection: (string | number)[]) => {
      const indices = curSelection.map(Number);
      dispatch(updateSelectedAttachmentIds(indices));
    },
    [dispatch]
  );

  return (
    <Box style={{ position: 'relative' }}>
      {selectedAttachmentToMove > 0 ? <StyledOverlay /> : null}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb="18px"
      >
        {showInstantSearch ? (
          <InstantSearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        ) : (
          <SearchBar onSubmit={setSearchTerm} placeholder="Search..." />
        )}
        {canAddFiles ? (
          <Button
            variant="contained"
            size="small"
            color="primary"
            data-cy="add-file"
            startIcon={<Icon icon={ICONS.Add} size={14} color={COLORS.WHITE} />}
            disabled={!editEnabled}
            onClick={onAddFileClick}
          >
            Add File
          </Button>
        ) : null}
      </Box>

      <Grid
        rows={attachments.filter((x) => !x.isDeleted)}
        columns={gridColumns}
        getRowId={(row: Attachment) => row.id}
      >
        <SortingState
          defaultSorting={[
            { columnName: attachmentColumns.createdOn, direction: 'desc' },
          ]}
          columnExtensions={gridColumnExtensions}
        />
        <SelectionState
          selection={selectedAttachmentIds}
          onSelectionChange={handleSelectionChange}
        />
        <IntegratedSelection />
        <EditingState
          onCommitChanges={commitChanges}
          columnExtensions={gridColumnExtensions}
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={(curEditingRowIds: Array<number | string>) => {
            setEditingRowIds(curEditingRowIds as number[]);
            setEditEnabled(curEditingRowIds.length === 0);
          }}
        />
        <IntegratedSorting columnExtensions={gridColumnExtensions} />
        <SearchState value={searchTerm} />
        <IntegratedFiltering />
        <SymphonyTable
          cellComponent={(tableProps) => (
            <AttachmentLabelCell
              {...tableProps}
              episodeId={episodeId}
              readonly={readonly}
              isAttachmentsTab={isAttachmentsTab}
              isActionsDisabled={!editEnabled}
            />
          )}
          columnExtensions={gridColumnExtensions}
        />
        {canSelectRow && (
          <TableSelection
            cellComponent={SelectCellWithoutRowClick}
            headerCellComponent={HeaderSelectCell}
            rowComponent={SelectedRow}
            showSelectAll
          />
        )}
        {!readonly && (
          <TableEditRow
            cellComponent={AttachmentEditCell}
            rowComponent={TableEditRows}
          />
        )}
        {!readonly && (
          <TableEditColumn
            showEditCommand={selectedAttachmentToMove === 0}
            cellComponent={TableEditCell}
            headerCellComponent={StubCellComponent}
            commandComponent={(editProps) => (
              <GridEditActions
                {...editProps}
                editEnabled={editEnabled}
                itemName={'attachment'}
              />
            )}
            width="50px"
          />
        )}
        <SymphonyTableHeaderRow showSortingControls />
        {!readonly && (
          <Getter name="tableColumns" computed={rearrangeColumnsEditPrelast} />
        )}
      </Grid>
      {showUploadFilesDialog && (
        <UploadFilesDialog
          open={showUploadFilesDialog}
          onClose={handleCloseUploadFilesDialog}
          onAddFiles={handleAddFilesDialog}
        />
      )}
      <Information
        open={errors}
        okEvent={() => {
          setErrors(false);
        }}
        title="Attachments"
      >
        <div>Please ensure attachment have a category assigned.</div>
      </Information>
      <Loader active={loading || isFetching} />
    </Box>
  );
};

export default AttachmentsGrid;
