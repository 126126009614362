import moment from 'moment';
import { getCareSiteNow } from './helpers/dateTimeHelpers';
import { TextAttributeType } from 'graphql/graphqlTypes';
import { IDropdownItem } from 'components/actions/sections/SectionBody/Components/types';

export interface IValidationResult {
  hasError: boolean;
  message?: string | null;
}

export interface IComponentValidationResult extends IValidationResult {
  categoryId: string;
  fieldName: string;
}

export const validateValueNotInTheOptions = (
  value: string | Date | number | null | undefined,
  options: IDropdownItem[] | null | undefined
): IValidationResult => {
  if (
    value !== null &&
    value !== undefined &&
    value.toString().trim().length !== 0 &&
    options &&
    options.every((o) => o.value !== value)
  ) {
    return {
      hasError: true,
      message:
        'A selected option is not in the list of available options. Please select an available option',
    };
  }
  return { hasError: false };
};

export const validateRequired = (
  value: string | Date | number | null | undefined,
  required: boolean
): IValidationResult => {
  if (
    required &&
    (value === null ||
      value === undefined ||
      value.toString().trim().length === 0)
  ) {
    return { hasError: true, message: 'Fill in required field' };
  }
  return { hasError: false };
};

export const validateEndDate = (
  startDate: Date | null,
  endDate: Date | null
): IValidationResult => {
  return startDate && endDate && moment(endDate).isBefore(startDate)
    ? {
        hasError: true,
        message: 'End date must be greater than start date',
      }
    : { hasError: false };
};

export const validateMaxLength = (
  value: string,
  maxLength: number
): IValidationResult => {
  if (maxLength > 0 && value && value.length > 0 && value.length > maxLength) {
    return {
      hasError: true,
      message: `Field cannot be more than ${maxLength} characters.`,
    };
  }
  return { hasError: false };
};

export const validateMinLength = (
  value: string,
  minLength: number
): IValidationResult => {
  if (minLength > 0 && value && value.length > 0 && value.length < minLength) {
    return {
      hasError: true,
      message: `Field cannot be less than ${minLength} characters.`,
    };
  }
  return { hasError: false };
};

export const validateNumber = (value: string): IValidationResult => {
  if (!/^\d+$/g.test(value)) {
    return {
      hasError: true,
      message: 'Field must be a number.',
    };
  }
  const intValue = parseInt(value, 10);
  if (intValue > 2147483647) {
    return {
      hasError: true,
      message:
        'The entered number is too big (should be less than 2147483647).',
    };
  }
  return { hasError: false };
};

export const validateRegexMask = (
  value: string,
  props?: IValidateTextFieldProps
): IValidationResult => {
  const regexMask = props?.textAttribute?.regexMask ?? '';
  const regExp = new RegExp(regexMask);
  return !regExp.test(value)
    ? {
        hasError: true,
        message:
          props?.textAttribute?.regexErrorText ??
          'Field must be in correct format',
      }
    : { hasError: false };
};

export const validateFloat = (value: string): IValidationResult => {
  let message: string | null = null;
  if (!/^(?!0\d)\d*(\.\d+)?$/g.test(value)) {
    message = 'Field must be a float.';
  } else {
    const floatValue = parseFloat(value);
    if (floatValue > 2147483647) {
      message =
        'The entered number is too big (should be less than 2147483647).';
    }
  }
  return {
    hasError: message !== null,
    message: message,
  };
};

export const validateMinValue = (
  value: string,
  minValue: number
): IValidationResult => {
  if (minValue && minValue !== 0 && minValue > parseInt(value, 10)) {
    return {
      hasError: true,
      message: `Field cannot be less than ${minValue}.`,
    };
  }
  return { hasError: false };
};

export const validateMinAmountValues = (
  value: number,
  minValue: number
): IValidationResult => {
  if (minValue && minValue !== 0 && minValue > value) {
    return {
      hasError: true,
      message: `Count of values cannot be less than ${minValue}.`,
    };
  }
  return { hasError: false };
};

export const validateMaxValue = (
  value: string,
  maxValue: number
): IValidationResult => {
  if (maxValue && maxValue !== 0 && maxValue < parseInt(value, 10)) {
    return {
      hasError: true,
      message: `Field cannot be more than ${maxValue}.`,
    };
  }
  return { hasError: false };
};

export enum TEXT_FIELD_TYPE {
  TEXT = 'text',
  NUMBER = 'number',
  FLOAT = 'float',
}

export interface IValidateTextFieldProps {
  required: boolean;
  type?: string;
  maxLen?: number;
  minLen?: number;
  maxVal?: number;
  minVal?: number;
  textAttribute?: TextAttributeType | null;
}

export const validateTextField = (
  inputValue: string,
  props: IValidateTextFieldProps
): IValidationResult => {
  let result = validateRequired(inputValue, props.required);
  if (result.hasError) {
    return result;
  }
  if (!inputValue) {
    return { hasError: false };
  }

  if (props.type === TEXT_FIELD_TYPE.NUMBER) {
    result = validateTextFieldNumberValue(inputValue, props);
    if (result.hasError) {
      return result;
    }
  } else if (props.type === TEXT_FIELD_TYPE.FLOAT) {
    result = validateTextFieldFloatValue(inputValue, props);
    if (result.hasError) {
      return result;
    }
  } else {
    result = validateTextFieldStringValue(inputValue, props);
    if (result.hasError) {
      return result;
    }
  }
  return { hasError: false };
};

export const validateTextFieldNumberValue = (
  inputValue: string,
  props: IValidateTextFieldProps
) => {
  let result = validateNumber(inputValue);
  if (result.hasError) {
    return result;
  }
  result = validateMaxValue(inputValue, props.maxVal || 0);
  if (result.hasError) {
    return result;
  }
  result = validateMinValue(inputValue, props.minVal || 0);
  if (result.hasError) {
    return result;
  }
  if (props?.textAttribute?.regexMask) {
    result = validateRegexMask(inputValue, props);
    if (result.hasError) {
      return result;
    }
  }

  return { hasError: false };
};

export const validateTextFieldFloatValue = (
  inputValue: string,
  props: IValidateTextFieldProps
) => {
  let result = validateFloat(inputValue);
  if (result.hasError) {
    return result;
  }
  result = validateMaxValue(inputValue, props.maxVal || 0);
  if (result.hasError) {
    return result;
  }
  result = validateMinValue(inputValue, props.minVal || 0);
  if (result.hasError) {
    return result;
  }
  return { hasError: false };
};

export const validateTextFieldStringValue = (
  inputValue: string,
  props: IValidateTextFieldProps
) => {
  let result;
  if (props.maxLen && props.maxLen > 0) {
    result = validateMaxLength(inputValue, props.maxLen || 0);
    if (result.hasError) {
      return result;
    }
  }
  if (props.minLen && props.minLen > 0) {
    result = validateMinLength(inputValue, props.minLen || 0);
    if (result.hasError) {
      return result;
    }
  }
  if (props?.textAttribute?.regexMask) {
    result = validateRegexMask(inputValue, props);
    if (result.hasError) {
      return result;
    }
  }
  return { hasError: false };
};

export const validateDate = (
  date: Date,
  daysBeforeLimit?: number | null,
  daysAfterLimit?: number | null
): IValidationResult => {
  if (!moment(date).isValid()) {
    return {
      hasError: true,
      message: 'Please enter a correct date (mm/dd/yyyy).',
    };
  }

  const careSiteNowMoment = getCareSiteNow();

  const currentYear = careSiteNowMoment.year();
  const year = date.getFullYear();
  if (year < currentYear - 120 || year > currentYear + 50) {
    return {
      hasError: true,
      message: 'Please enter a correct date',
    };
  }

  if (hasValue(daysBeforeLimit)) {
    const minDate = careSiteNowMoment
      .startOf('day')
      .subtract(daysBeforeLimit, 'd')
      .toDate();
    if (date < minDate) {
      return {
        hasError: true,
        message: `Date must be within the last ${daysBeforeLimit} days.`,
      };
    }
  }
  if (hasValue(daysAfterLimit)) {
    const maxDate = careSiteNowMoment
      .endOf('day')
      .add(daysAfterLimit, 'd')
      .toDate();
    if (date > maxDate) {
      return {
        hasError: true,
        message: `Date must be within the next ${daysAfterLimit} days.`,
      };
    }
  }
  return { hasError: false };
};

export interface IValidateDateFieldProps {
  required: boolean;
  daysBeforeLimit?: number | null;
  daysAfterLimit?: number | null;
}

export const validateDateRange = (
  date: Date | null,
  startDate: Date | null,
  endDate: Date | null,
  required: boolean,
  message: string
): IValidationResult => {
  const result = validateRequired(date, required);
  if (result.hasError) {
    return result;
  }
  if (date) {
    if (startDate && date < startDate) {
      return {
        hasError: true,
        message: message,
      };
    }
    if (endDate && date > endDate) {
      return {
        hasError: true,
        message: message,
      };
    }
  }

  return result;
};

export const validateDateField = (
  date: Date | null,
  props: IValidateDateFieldProps
): IValidationResult => {
  const result = validateRequired(date, props.required);
  if (result.hasError) {
    return result;
  }
  if (date) {
    return validateDate(date, props.daysBeforeLimit, props.daysAfterLimit);
  }
  return result;
};

// https://stackoverflow.com/a/67089554/793381
// Ensures unnecessary checks aren't performed - only a valid call if
// value could be nullable *and* could be non-nullable
type NullPart<T> = T & (null | undefined);

type MustBeAmbiguouslyNullable<T> = NullPart<T> extends never
  ? never
  : NonNullable<T> extends never
  ? never
  : T;

export const hasValue = <T,>(
  value: MustBeAmbiguouslyNullable<T>
): value is NonNullable<MustBeAmbiguouslyNullable<T>> =>
  (value as unknown) !== undefined && (value as unknown) !== null;
