import { GetPatientCardInfoQuery } from 'graphql/hooks/getPatientCardInfo';
import { ChecklistStorageType } from 'store/actions/types';
import { PatientCardWrapper } from 'pages/workflow/PatientCardWrapper';
import NavBar from 'components/actions/navbar';
import React from 'react';
import styled from 'styled-components';
import LinkEpisodeNavbar from 'components/actions/navbar/LinkEpisodeNavbar';

interface IActionSidebarProps {
  patientData: GetPatientCardInfoQuery | undefined;
  isFinishPage: boolean;
  isReviewPage: boolean;
  checklistStorageType: ChecklistStorageType;
}

const StyledContainer = styled.div`
  position: sticky;
  top: 88px;
  grid-column: 1;
  grid-row: 1;
  min-width: 0;
`;

export const ActionSidebar = ({
  checklistStorageType,
  isFinishPage,
  isReviewPage,
  patientData,
}: IActionSidebarProps) => {
  return (
    <StyledContainer>
      {patientData?.getPatientById && (
        <PatientCardWrapper
          patientData={patientData}
          storageType={checklistStorageType}
        />
      )}

      {!isFinishPage && (
        <>
          <LinkEpisodeNavbar
            isReview={isReviewPage}
            storageType={checklistStorageType}
          />
          <NavBar isReview={isReviewPage} storageType={checklistStorageType} />
        </>
      )}
    </StyledContainer>
  );
};
