import { FooterContainer } from 'components/footer/FooterContainer';
import Box from '@mui/material/Box';
import { ChecklistStorageType } from 'store/actions/types';
import { Button } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { openLetters } from 'store/ui/modals/letters';
import React, { useEffect } from 'react';
import { useAmendChecklistMutation } from 'graphql/hooks/amendChecklist';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { useLazyGeneratePdfQuery } from 'graphql/hooks/generatePdf';
import { IDownloadFile } from 'backend/types/downloadFile';
import { base64ToByteArray } from 'util/helpers/base64Helper';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import { ROUTE } from 'consts/ROUTE';
import { setWorkflowStep } from 'store/workflow/workflowSlice';
import { MozartOutOfTurnSteps } from 'features/mozart/components';
import { useNavigate } from 'react-router';

interface IFinishFooterProps {
  isAmendAvailable?: boolean;
  storageType: ChecklistStorageType;
  isLettersAvailable?: boolean;
  patientId?: string;
}

const StyledDivider = styled(Divider)`
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 20px;
  height: 100%;
`;

const FinishFooter = ({
  isAmendAvailable,
  storageType,
  isLettersAvailable,
  patientId,
}: IFinishFooterProps) => {
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );
  const episodeId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].episodeId
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [
    amendChecklist,
    {
      isLoading: isAmendingLoading,
      isSuccess: isSuccessAmendDone,
      data: amendChecklistResult,
    },
  ] = useAmendChecklistMutation();

  const [
    printPdf,
    {
      data: printDoneResult,
      isSuccess: isSuccessPrintDone,
      isFetching: isPrintingLoading,
    },
  ] = useLazyGeneratePdfQuery();

  useEffect(() => {
    if (isSuccessAmendDone) {
      navigate(`/Checklist/Actions/${amendChecklistResult?.amendChecklist}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAmendDone]);

  useEffect(() => {
    if (isSuccessPrintDone) {
      openDownloadResponse({
        fileName: printDoneResult?.generatePdf?.fileName ?? '',
        content: printDoneResult?.generatePdf?.content ?? '',
        mimeType: printDoneResult?.generatePdf?.mimeType ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printDoneResult]);

  const openDownloadResponse = (file: IDownloadFile) => {
    const openFile = new Blob([base64ToByteArray(file.content)], {
      type: file.mimeType,
    });
    const openFileURL = URL.createObjectURL(openFile);
    if (openFileURL) {
      window.open(openFileURL, '_blank', 'noopener,noreferrer');
    }
  };
  const onAmendChecklist = () => {
    if (isAmendAvailable) {
      amendChecklist({ checklistId: Number(checklistId) });
    }
  };

  const handleNewScenario = () => {
    dispatch(setWorkflowStep({ step: 1, name: 'Scenario' }));
    navigate(`/Checklist/Problems?patientId=${patientId}`);
  };

  return (
    <FooterContainer
      data-testid="FinishFooter"
      loading={isPrintingLoading || isAmendingLoading}
    >
      <Box>
        {storageType === ChecklistStorageType.MOZART ? (
          <MozartOutOfTurnSteps />
        ) : null}
        {isAmendAvailable && (
          <Button
            onClick={onAmendChecklist}
            color="primary"
            data-cy="back-to-scenario"
            size="large"
            startIcon={
              <Icon icon={ICONS.Back} size={22} color={COLORS.SYMPHONY_BLUE} />
            }
          >
            Amend
          </Button>
        )}
      </Box>
      <Box display="flex">
        <Button
          onClick={() =>
            printPdf({
              checklistId: Number(checklistId),
              schemaId: Number(0),
            })
          }
          color="primary"
          data-cy="print"
          size="large"
          style={{
            marginLeft: '12px',
            marginRight: '12px',
          }}
          startIcon={
            <Icon icon={ICONS.Print} size={22} color={COLORS.SYMPHONY_BLUE} />
          }
          variant="outlined"
        >
          Print
        </Button>
        {isLettersAvailable && (
          <Button
            color="primary"
            data-cy="open-letters"
            size="large"
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
            startIcon={
              <Icon
                icon={ICONS.Letter}
                size={22}
                color={COLORS.SYMPHONY_BLUE}
              />
            }
            variant="outlined"
            onClick={() =>
              dispatch(
                openLetters({
                  episodeId: episodeId ?? 0,
                  patientId: Number(patientId ?? 0),
                })
              )
            }
          >
            Letters
          </Button>
        )}
        <StyledDivider orientation="vertical" variant="middle" flexItem />
        <Button
          color="primary"
          size="large"
          onClick={handleNewScenario}
          data-cy="new-scenario"
          style={{
            marginLeft: '12px',
            marginRight: '12px',
          }}
          variant="outlined"
        >
          New Scenario
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginLeft: '12px' }}
          onClick={() => navigate(ROUTE.Home)}
          data-cy="return-home"
        >
          Return Home
        </Button>
      </Box>
    </FooterContainer>
  );
};

export default FinishFooter;
