import { DecisionAction, DeterminationDecision, hsVersion } from './types';
import { differenceInDays } from 'date-fns';
import { Dispatch } from 'redux';
import { ChecklistStorageType } from 'store/actions/types';
import {
  setChecklistComponentValidation,
  updateCheckListItemIsVisible,
} from 'store/actions/checklistSlice';
import { notMaybe } from 'util/helpers/arrayFunctions';
import moment from 'moment';
import {
  ChecklistItem,
  ChecklistItemHealthServices,
  ChecklistViewModel,
  HealthServiceCodesActionModel,
  HealthServiceCodesActionValue,
  ILookupValue,
  LookupValue,
  Maybe,
  SearchResult,
  UpdateActionValueModelInput,
} from 'graphql/graphqlTypes';
import { MOMENT_DATE_FORMAT } from 'components/constants';
import { useUpdateActionValue } from '../UpdateActionValue.helpers';

export const getApprovedUnits = (
  item: HealthServiceCodesActionValue,
  decision: DeterminationDecision
) => {
  switch (decision) {
    case 'Approved':
      return item.healthServiceActionValue?.requestedUnits;
    case 'Denied':
      return 0;
    default:
      return null;
  }
};

export const getRequestedUnits = (
  unitsTypeValue: string,
  hsModel: HealthServiceCodesActionModel,
  requestedUnits: Maybe<number> | undefined
) => {
  return unitsTypeValue === 'Days' &&
    hsModel.overallReqStartDate &&
    hsModel.overallReqEndDate
    ? differenceInDays(
        moment(hsModel.overallReqEndDate).toDate(),
        moment(hsModel.overallReqStartDate).toDate()
      )
    : requestedUnits;
};

export const getApprovedStartDate = (
  item: HealthServiceCodesActionValue,
  decision: DeterminationDecision
) =>
  decision === 'Approved'
    ? item.healthServiceActionValue?.requestedStartDate
    : null;

export const getApprovedEndDate = (
  item: HealthServiceCodesActionValue,
  decision: DeterminationDecision
) =>
  decision === 'Approved'
    ? item.healthServiceActionValue?.requestedEndDate
    : null;

export const getApprovedUnitTypes = (
  item: HealthServiceCodesActionValue,
  decision: DeterminationDecision
) =>
  decision === 'Approved' || decision === 'Denied'
    ? item.healthServiceActionValue?.requestedUnitType
    : null;

const getHsTotalUnits = (
  unitsTypeValue: string,
  newHsValues: Array<Maybe<HealthServiceCodesActionValue>>,
  hsModel: HealthServiceCodesActionModel,
  version: string | null
): number | null | undefined => {
  if (unitsTypeValue === 'Days') {
    return getRequestedUnits(unitsTypeValue, hsModel, hsModel.totalReqUnits);
  }
  if (version === hsVersion) {
    return hsModel.totalReqUnits;
  }
};

const getUnitTypeValue = (hsModel: HealthServiceCodesActionModel): string => {
  const defaultUnitTypeName = 'Units';
  return hsModel?.overallReqUnitType?.name ?? defaultUnitTypeName;
};

export const updateDecisionAndReason = (
  hsModel: HealthServiceCodesActionModel,
  decision: DeterminationDecision,
  reason: LookupValue | null,
  version: string | null
): HealthServiceCodesActionModel => {
  const unitTypeValue = getUnitTypeValue(hsModel);
  const newHsValues: HealthServiceCodesActionValue[] =
    hsModel.healthServiceCodesActionValues.filter(notMaybe).map((item) => ({
      ...item,
      healthServiceActionValue: {
        ...item.healthServiceActionValue,
        determinationDecision: decision === 'NonSet' ? null : decision,
        determinationReason: reason,
        approvedUnits: getApprovedUnits(item, decision),
        approvedStartDate: getApprovedStartDate(item, decision),
        approvedEndDate: getApprovedEndDate(item, decision),
        approvedUnitType: getApprovedUnitTypes(item, decision),
        requestedUnits: getRequestedUnits(
          unitTypeValue,
          hsModel,
          item.healthServiceActionValue?.requestedUnits
        ),
      },
    }));
  const totalReqUnits = getHsTotalUnits(
    unitTypeValue,
    newHsValues,
    hsModel,
    version
  );
  const newModel: HealthServiceCodesActionModel = {
    ...hsModel,
    healthServiceCodesActionValues: newHsValues,
    totalReqUnits: totalReqUnits,
    totalApprUnits:
      decision === 'Approved' && version === hsVersion
        ? totalReqUnits
        : getTotalApprUnits(newHsValues),
  };
  newModel.overallApprStartDate =
    version === hsVersion && newModel.overallApprStartDate == null
      ? hsModel.overallReqStartDate
      : newModel.overallApprStartDate;
  newModel.overallApprEndDate =
    version === hsVersion && newModel.overallApprEndDate == null
      ? hsModel.overallReqEndDate
      : newModel.overallApprEndDate;
  newModel.overallDeterminationDecision =
    decision === 'NonSet' ? null : decision;
  newModel.overallDeterminationReason = reason;
  newModel.overallApprUnitType = hsModel.overallReqUnitType;
  return newModel;
};

export const addMedicationCode = (
  newCode: SearchResult,
  hsModel: HealthServiceCodesActionModel,
  unitTypes: ILookupValue[],
  version: string | null
): HealthServiceCodesActionModel => {
  const overallReqUnitTypeName = unitTypes.find(
    (x) => x.id === hsModel.overallReqUnitType?.id
  )?.name;

  const decision =
    hsModel?.healthServiceCodesActionValues[0]?.healthServiceActionValue
      ?.determinationDecision;

  const defaultUnitTypeName = 'Units';

  const unitsTypeValue = overallReqUnitTypeName ?? defaultUnitTypeName;

  const newHSValue: HealthServiceCodesActionValue = {
    id: newCode.id,
    code: newCode.code,
    description: newCode.description,
    healthServiceActionValue: {
      requestedStartDate: hsModel.overallReqStartDate,
      requestedEndDate: hsModel.overallReqEndDate,
      requestedUnitType: unitsTypeValue,
      requestedUnits:
        unitsTypeValue === 'Days' &&
        hsModel.overallReqStartDate &&
        hsModel.overallReqEndDate
          ? differenceInDays(
              moment(hsModel.overallReqEndDate).toDate(),
              moment(hsModel.overallReqStartDate).toDate()
            )
          : null,
    },
  };

  const allHSValues = [...hsModel.healthServiceCodesActionValues, newHSValue];

  const totalReqUnits = getHsTotalUnits(
    unitsTypeValue,
    allHSValues,
    hsModel,
    version
  );
  const newModel: HealthServiceCodesActionModel = {
    ...hsModel,
    healthServiceCodesActionValues: allHSValues,
    totalReqUnits:
      version !== hsVersion ? totalReqUnits : hsModel.totalReqUnits,
    totalApprUnits:
      decision === 'Approved' && version === hsVersion
        ? totalReqUnits
        : hsModel.totalApprUnits,
  };
  return newModel;
};

export const useSaveHealthService = () => {
  const { updateActionValueExtended } = useUpdateActionValue();
  return (
    value: HealthServiceCodesActionModel,
    checklistId: string | undefined,
    item: ChecklistItemHealthServices,
    autoSave: boolean,
    updateChecklistItemInputValueOnSave: (
      item: ChecklistItem,
      index: number,
      data: UpdateActionValueModelInput
    ) => void,
    orderableIndex: number
  ) => {
    const saveData = {
      checklistId: Number(checklistId) ?? 0,
      componentId: 'i',
      id: item.uid,
      type: 'string',
      value: JSON.stringify(value),
    };

    if (autoSave) {
      updateActionValueExtended(saveData, item.uid);
    } else {
      updateChecklistItemInputValueOnSave(item, orderableIndex, saveData);
    }
  };
};

const getTotalReqUnits = (items: Array<Maybe<HealthServiceCodesActionValue>>) =>
  getTotalUnits(
    items,
    (val) => val?.healthServiceActionValue?.requestedUnits ?? 0
  );

const getTotalApprUnits = (
  items: Array<Maybe<HealthServiceCodesActionValue>>
) =>
  getTotalUnits(
    items,
    (val) => val?.healthServiceActionValue?.approvedUnits ?? 0
  );

const getTotalUnits = (
  items: Array<Maybe<HealthServiceCodesActionValue>>,
  unitSelector: (arg: HealthServiceCodesActionValue | null) => number
) => items?.map(unitSelector).reduce((sum, current) => sum + current, 0);

const findSomeHealthServiceDecision = (
  model: HealthServiceCodesActionModel,
  decisionAction: DecisionAction
) => {
  return model.healthServiceCodesActionValues.some(
    (x) => x?.healthServiceActionValue?.determinationDecision == decisionAction
  );
};

const findEveryHealthServiceDecision = (
  model: HealthServiceCodesActionModel,
  decisionAction: DecisionAction
) => {
  return model.healthServiceCodesActionValues.every(
    (x) => x?.healthServiceActionValue?.determinationDecision == decisionAction
  );
};

const getOverallDeterminationDecision = (
  model: HealthServiceCodesActionModel
) => {
  const isAllApproved = findEveryHealthServiceDecision(
    model,
    DecisionAction.approved
  );
  const isAnyPending = findSomeHealthServiceDecision(
    model,
    DecisionAction.pending
  );
  const isAnyPartial = findSomeHealthServiceDecision(
    model,
    DecisionAction.partial
  );
  const isAnyDenied = findSomeHealthServiceDecision(
    model,
    DecisionAction.denied
  );
  const isAllDenied = findEveryHealthServiceDecision(
    model,
    DecisionAction.denied
  );

  if (isAnyPending) {
    return DecisionAction.pending;
  }
  if (isAllApproved) {
    return DecisionAction.approved;
  }
  if (isAllDenied) {
    return DecisionAction.denied;
  }
  if (isAnyPartial || isAnyDenied) {
    return DecisionAction.partial;
  }
};

export const onHealthServiceItemValueChange = (
  model: HealthServiceCodesActionModel,
  updatedValue: HealthServiceCodesActionValue
) => {
  const newModel: HealthServiceCodesActionModel = {
    ...model,
    healthServiceCodesActionValues: model.healthServiceCodesActionValues.map(
      (x) => (x?.id === updatedValue.id ? updatedValue : x)
    ),
  };

  newModel.totalReqUnits = getTotalReqUnits(
    newModel.healthServiceCodesActionValues
  );

  newModel.totalApprUnits = getTotalApprUnits(
    newModel.healthServiceCodesActionValues
  );

  const isPartiallyApproved = findSomeHealthServiceDecision(
    newModel,
    DecisionAction.approved
  );

  newModel.overallDeterminationDecision =
    getOverallDeterminationDecision(newModel);

  newModel.overallApprStartDate = isPartiallyApproved
    ? model.overallReqStartDate
    : null;

  newModel.overallApprEndDate = isPartiallyApproved
    ? model.overallReqEndDate
    : null;

  newModel.overallApprUnitType = isPartiallyApproved
    ? model.overallReqUnitType
    : null;

  return newModel;
};

export const deleteItemHealthService = (
  model: HealthServiceCodesActionModel,
  id: number,
  version: string | null
) => {
  const newValuesArray = model.healthServiceCodesActionValues.filter(
    (x) => x?.id !== id
  );
  const decision =
    model?.healthServiceCodesActionValues[0]?.healthServiceActionValue
      ?.determinationDecision;
  return {
    ...model,
    totalReqUnits:
      version === hsVersion
        ? model.totalReqUnits
        : getTotalReqUnits(newValuesArray),
    totalApprUnits:
      decision === 'Approved' && version === hsVersion
        ? model.totalReqUnits
        : getTotalApprUnits(newValuesArray),
    healthServiceCodesActionValues: newValuesArray,
  };
};

export const isValidDate = (date: string | null): boolean => {
  return !!date && new Date(date).toDateString() !== 'Invalid Date';
};
export const formatValidDate = (date: Date | null) => {
  return date && date.toDateString() !== 'Invalid Date'
    ? moment(date).format(MOMENT_DATE_FORMAT)
    : null;
};

export const getValidationDispatch =
  (
    dispatch: Dispatch,
    uid: string,
    categoryId: string,
    storageType: ChecklistStorageType,
    fieldName: string
  ) =>
  (errorMessage: string, error: boolean, id?: string) => {
    const uuid = uid + (id ? `-${id}` : '');
    dispatch(
      setChecklistComponentValidation({
        storageType,
        error: {
          uuid: uuid,
          error: error ? errorMessage : '',
          fieldName,
          categoryId,
          isValid: !error,
        },
      })
    );
  };

export const getHsVersion = (
  hsAuthorizationType: number,
  hsEpisode: number
) => {
  return hsAuthorizationType === hsEpisode ? hsVersion : null;
};

export const initHsGroupDecision = (hsModel: HealthServiceCodesActionModel) => {
  return (
    (hsModel?.overallDeterminationDecision as DeterminationDecision) ??
    (hsModel?.healthServiceCodesActionValues[0]?.healthServiceActionValue
      ?.determinationDecision as DeterminationDecision) ??
    DecisionAction.nonSet
  );
};

export const applyOnHsDecisionGroup = (
  dispatch: Dispatch,
  storageType: ChecklistStorageType,
  checklist: ChecklistViewModel,
  item: ChecklistItemHealthServices,
  decision: DeterminationDecision
) => {
  item?.conditions?.forEach((condition) => {
    const uid = checklist?.categories.map(
      (categorie) =>
        categorie?.items?.find(
          (item) => item.orderableId === condition.orderableId
        )?.uid
    )[0] as string;
    if (uid) {
      dispatch(
        updateCheckListItemIsVisible({
          storageType,
          itemId: uid,
          decision: decision,
          condition: condition,
        })
      );
    }
  });
};

export const onHealthServiceOverallItemValueChange = (
  newModel: HealthServiceCodesActionModel,
  version: string | null
) => {
  const unitsTypeValue = getUnitTypeValue(newModel);
  const newHsValues: HealthServiceCodesActionValue[] =
    newModel.healthServiceCodesActionValues.filter(notMaybe).map((item) => ({
      ...item,
      healthServiceActionValue: {
        ...item.healthServiceActionValue,
        requestedUnitType: newModel.overallReqUnitType?.name,
        approvedUnitType:
          item.healthServiceActionValue?.approvedUnitType ??
          newModel.overallReqUnitType?.name,
        requestedUnits: getRequestedUnits(unitsTypeValue, newModel, null),
        approvedUnits: getInitialApprovedUnits(newModel, item),
      },
    }));
  const updatedHsValues: HealthServiceCodesActionModel = {
    ...newModel,
    healthServiceCodesActionValues: newHsValues,
    totalReqUnits: getHsTotalUnits(
      unitsTypeValue,
      newHsValues,
      newModel,
      version
    ),
  };
  updatedHsValues.overallApprStartDate =
    version === hsVersion
      ? newModel.overallReqStartDate
      : updatedHsValues.overallApprStartDate;
  updatedHsValues.overallApprEndDate =
    version === hsVersion
      ? newModel.overallReqEndDate
      : updatedHsValues.overallApprEndDate;
  updatedHsValues.overallApprUnitType =
    version === hsVersion
      ? newModel.overallReqUnitType
      : updatedHsValues.overallApprUnitType;
  return updatedHsValues;
};

export const getDateDifferenceInDays = (startDate: Date, endDate: Date) => {
  const getDiff = () => {
    return Math.max(
      0,
      differenceInDays(moment(endDate).toDate(), moment(startDate).toDate())
    );
  };

  return startDate && endDate ? getDiff() : null;
};

export const getInitialRequestedUnits = (
  newModel: HealthServiceCodesActionModel,
  item: HealthServiceCodesActionValue
) => {
  return newModel.overallReqUnitType?.name == 'Days'
    ? getDateDifferenceInDays(
        item.healthServiceActionValue?.requestedStartDate,
        item.healthServiceActionValue?.requestedEndDate
      )
    : null;
};

export const getInitialApprovedUnits = (
  newModel: HealthServiceCodesActionModel,
  item: HealthServiceCodesActionValue
) => {
  return newModel.overallReqUnitType?.name == 'Days'
    ? getDateDifferenceInDays(
        item.healthServiceActionValue?.approvedStartDate,
        item.healthServiceActionValue?.approvedEndDate
      )
    : null;
};
