import { IAuthUser } from 'backend/types/authUser';
import {
  AUTH_CHANGE_PASSWORD,
  AUTH_FAIL,
  AUTH_FORGOT_PASSWORD,
  AUTH_LOGOUT,
  AUTH_MFA_STEP,
  AUTH_SELECT_PROVIDER,
  AUTH_SELECT_ROLE,
  AUTH_START,
  AUTH_SUCCESS,
  ILoginActions,
} from './action';
import { IAuthState, ILoginUser } from './types';

export const initialAuthState: IAuthState = {
  currentUser: {} as IAuthUser,
  error: '',
  step: 'start',
  isLoggedIn: false,
  isCaptchaRequired: false,
  auth: {} as ILoginUser,
  loading: false,
  cnt: 0,
  authFinished: false,
  phone: '',
  mfaType: '',
  isUserLoggedOut: false,
};

const authReducer = (
  state: IAuthState = initialAuthState,
  action: ILoginActions
): IAuthState => {
  switch (action.type) {
    case AUTH_START:
      return {
        ...state,
        loading: true,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        currentUser: action.user ? action.user : ({} as IAuthUser),
        authFinished: true,
        loading: false,
      };
    case AUTH_FAIL:
      return {
        ...state,
        error: action.error,
        isLoggedIn: false,
        isCaptchaRequired: action.isCaptchaRequired,
        authFinished: true,
        loading: false,
      };

    case AUTH_LOGOUT:
      return {
        ...initialAuthState,
        authFinished: true,
        isUserLoggedOut: action.shouldRefreshPage,
      };

    case AUTH_SELECT_ROLE:
      return {
        ...state,
        step: 'roles',
        auth: action.user,
      };

    case AUTH_SELECT_PROVIDER:
      return {
        ...state,
        step: 'selectProvider',
        loading: false,
        auth: action.user,
      };

    case AUTH_CHANGE_PASSWORD:
      return {
        ...state,
        step: 'changePassword',
        auth: action.user,
        code: action.code,
      };

    case AUTH_FORGOT_PASSWORD:
      return {
        ...state,
        step: 'forgotPassword',
      };

    case AUTH_MFA_STEP:
      return {
        ...state,
        step: action.step,
        auth: action.user,
        phone: action.phone,
        mfaType: action.mfaType,
      };

    default:
      return state;
  }
};
export default authReducer;
