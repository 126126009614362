import React, { useState } from 'react';
import { Button, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE } from 'consts/ROUTE';
import { ChecklistStorageType } from 'store/actions/types';
import { IState } from 'store';
import { useDiscardMozartWorkflowMutation } from 'graphql/hooks/discardMozartWorkflow';
import { useFinishMozartWorkflowChecklistMutation } from 'graphql/hooks/finishMozartWorkflowChecklist';
import { MozartProgressState, MozartActionType } from 'graphql/graphqlTypes';
import {
  resetChecklist,
  setErrorBarVisible,
} from 'store/actions/checklistSlice';
import {
  mozartResetChecklist,
  mozartStartPolling,
} from 'store/actions/mozartSlice';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { useShowValidation } from 'hooks/useShowValidation';
import { setWorkflowStep } from 'store/workflow/workflowSlice';
import Loader from 'components/loader';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router';
import { clearFailedRequests } from 'store/failedRequests/failedRequestsSlice';
import Confirmation from 'components/modal/Confirmation';

const StyledButton = styled(Button)`
  margin-left: 16px;
  margin-right: 32px;
  width: 150px;
`;

const StyledNextButton = styled(Button)`
  margin-left: 32px;
  width: 150px;
`;
const StyledDiscardButton = styled(Button)`
  width: 150px;
  margin-right: 16px;
`;

const StyledDivider = styled(Divider)`
  height: 32px;
  margin: 0;
`;

export type IMozartNExtStepFooterProps = {
  handleResetState: () => void;
  isWorkflowFailed: boolean;
  isMozartWorkflowInRoleCareSite: boolean;
};
export const MozartNextStepFooter = ({
  handleResetState,
  isWorkflowFailed,
  isMozartWorkflowInRoleCareSite,
}: IMozartNExtStepFooterProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isSaving = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART].isSaving
  );

  const checklistId = useSelector(
    (state: IState) => state.mozart.workflowChecklistId
  );

  const mozartInstanceId = useSelector(
    (state: IState) => state.mozart.mozartInstanceId
  );

  const isUserAuthorizedMozartNextStep = useSelector(
    (state: IState) => state.mozart.isUserAuthorizedMozartNextStep
  );

  const isPausedWorkflow = useSelector(
    (state: IState) => state.mozart.isWorkflowPaused
  );
  const mozartViewOnly = useSelector(
    (state: IState) => state.mozart.mozartViewOnly
  );

  const canContinueWorkflow = useSelector(
    (state: IState) => state.mozart.canContinueWorkflow
  );

  const patientId = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.MOZART].patientId
  );

  const patientIdForReOpeningPatientDetails = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  const hasFailedRequests = useSelector(
    (state: IState) => (state.failedRequest.requests ?? []).length > 0
  );

  const { hasValidationRules } = useShowValidation(ChecklistStorageType.MOZART);

  if (
    hasValidationRules &&
    window.location.href.includes(ROUTE.RerunValidation)
  ) {
    dispatch(
      setErrorBarVisible({
        storageType: ChecklistStorageType.MOZART,
        visible: true,
      })
    );
    location.hash = '';
    const cleanUri = window.location.toString().split('#')[0];
    window.history.pushState({}, document.title, cleanUri);
  }
  const [discardChecklist, { isLoading: isDiscardingChecklist }] =
    useDiscardMozartWorkflowMutation();

  const [finishChecklist, { isLoading: isFinishingChecklist }] =
    useFinishMozartWorkflowChecklistMutation();

  const handleGoToHome = () => {
    handleResetState();
    navigate(ROUTE.Home);
  };

  const handleNewScenario = () => {
    handleResetState();
    dispatch(setWorkflowStep({ step: 1, name: 'Scenario' }));
    navigate(`/Checklist/Problems?patientId=${patientId}`);
  };

  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const mozartMessages = useSelector(
    (state: IState) => state.mozart.mozartMessages
  );
  const isRemovalForAnEpisode = !mozartMessages.some(
    (message) =>
      message.actionType === MozartActionType.UserInput &&
      message.state === MozartProgressState.Completed
  );

  const popupMessage = `Are you sure you want to discard this ${
    isRemovalForAnEpisode ? 'Episode' : 'Step'
  }?`;

  const handleDiscard = () => {
    discardChecklist({
      id: mozartInstanceId,
      checklistId: Number(checklistId),
    }).then(handleGoToHome);
    dispatch(clearFailedRequests());
  };
  const handleNextStep = () => {
    if (hasValidationRules) {
      dispatch(
        setErrorBarVisible({
          storageType: ChecklistStorageType.MOZART,
          visible: true,
        })
      );
    } else {
      finishChecklist({
        id: mozartInstanceId,
      }).then((result) => {
        if ('data' in result) {
          if (
            result.data?.finishMozartWorkflowChecklist
              ?.isDataValidationExceptionOccured
          ) {
            navigate(ROUTE.RerunValidation);
            navigate(0);
          } else {
            dispatch(resetChecklist(ChecklistStorageType.MOZART));
            dispatch(mozartResetChecklist());
            dispatch(mozartStartPolling());
          }
        } else if ('error' in result) {
          dispatch(
            showErrorPopup({
              message: result.error?.message ?? 'Error finishing checklist.',
            })
          );
        }
      });
    }
  };

  if (
    !isUserAuthorizedMozartNextStep ||
    isPausedWorkflow ||
    isWorkflowFailed ||
    isMozartWorkflowInRoleCareSite
  ) {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleNewScenario}
          data-cy="new-scenario"
          data-testid="mozart-new-scenario"
        >
          New Scenario
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginLeft: '20px' }}
          onClick={handleGoToHome}
          data-cy="return-home"
          data-testid="mozart-go-home"
        >
          {patientIdForReOpeningPatientDetails
            ? 'Return to Member Details'
            : 'Return Home'}
        </Button>
      </>
    );
  }

  return (
    <>
      <StyledDiscardButton
        onClick={() => setShowDiscardConfirmation(true)}
        color="primary"
        data-cy="discard-scenario"
        size="large"
        disabled={checklistId <= 0 || mozartViewOnly}
      >
        Discard Step
      </StyledDiscardButton>

      <Confirmation
        open={showDiscardConfirmation}
        title="Confirm Discard"
        okButtonText="Discard"
        okEvent={() => {
          handleDiscard();
          setShowDiscardConfirmation(false);
        }}
        cancelEvent={() => setShowDiscardConfirmation(false)}
        disableBackdropClick
      >
        <Typography>{popupMessage}</Typography>
      </Confirmation>

      <StyledButton
        color="primary"
        size="large"
        onClick={handleGoToHome}
        data-cy="finish-later"
        variant="outlined"
        disabled={mozartViewOnly || isSaving || hasFailedRequests}
      >
        Finish Later
      </StyledButton>
      <StyledDivider orientation="vertical" />
      {mozartViewOnly ? (
        <StyledNextButton
          variant="contained"
          color="primary"
          size="large"
          style={{ marginLeft: '20px' }}
          onClick={handleGoToHome}
          data-cy="return-home"
          data-testid="mozart-go-home"
          disabled={isSaving}
        >
          Return Home
        </StyledNextButton>
      ) : (
        <StyledNextButton
          variant="contained"
          color="primary"
          size="large"
          onClick={handleNextStep}
          data-cy="return-home"
          data-testid="mozart-finish"
          disabled={
            isWorkflowFailed ||
            mozartViewOnly ||
            !canContinueWorkflow ||
            isSaving ||
            hasFailedRequests
          }
        >
          Next
        </StyledNextButton>
      )}
      <Loader active={isFinishingChecklist || isDiscardingChecklist} />
    </>
  );
};
