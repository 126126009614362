import 'regenerator-runtime/runtime';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagsProvider from 'components/home/grid/providers/TagsProvider';
import ReminderImportanceProvider from 'components/home/grid/providers/ReminderImportanceProvider';
import PatientMRNProvider from 'components/home/grid/providers/PatientMRNProvider';
import EpisodeNumberProvider from 'components/home/grid/providers/EpisodeNumberProvider';
import InProgressProvider from 'components/home/grid/providers/InProgressProvider';
import {
  NoDataCellComponent,
  RowComponent,
  SelectedRow,
  SymphonyTableHeaderRow,
  TableComponent,
} from 'components/gridFormatters';
import ReminderReasonProvider from 'components/home/grid/providers/ReminderReasonProvider';
import ReminderOverdueProvider from 'components/home/grid/providers/ReminderOverdueProvider';
import {
  CustomPaging,
  Filter,
  FilteringState,
  Grid as GridBase,
  IntegratedSelection,
  PagingState,
  SelectionState,
  Sorting,
  SortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Grid,
  PagingPanel,
  TableColumnResizing,
  TableFilterRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  resetSelection,
  updateColumnWidths,
  updateFilters,
  updatePage,
  updatePageSize,
  updateSelection,
  updateSorting,
} from 'store/home/action';
import { IRowRequest, SearchTab } from 'store/home/types';
import FilterEditor from 'components/home/grid/FilterEditor';
import FilterCell from 'components/home/grid/FilterCell';
import SelectCell from 'components/home/grid/selectors/SelectCell';
import HeaderSelectCell from 'components/home/grid/selectors/HeaderSelectCell';
import PagingContainer from 'components/home/grid/pager';
import { IState } from 'store';
import FilterRow from './grid/FilterRow';
import {
  MOMENT_DATETIME_FORMAT,
  PATIENT_LIST_SIZE,
} from 'components/constants';
import {
  getColumns,
  getColumnsExtensions,
  getNoDataMessage,
  getSortingColumnExtensions,
  getTableColumnsExtensions,
  getUpdatedColumnWidths,
} from './HomeGrid.helpers';
import RisingRiskProvider from './grid/providers/RisingRiskProvider';
import TooltipProvider from 'components/home/grid/providers/TooltipProvider';
import EpisodesListClockProvider from './grid/providers/EpisodesListClockProvider';
import { useSetPreferenceMutation } from 'graphql/hooks/setPreference';
import { useSaveColumnWidthMutation } from 'graphql/hooks/saveColumnWidth';
import styled from 'styled-components';
import theme from 'theme';
import { createSelector } from '@reduxjs/toolkit';
import { usePrevious } from 'hooks';
import { fetchGridRows, saveFilterSelection } from 'store/home/middlewares';
import { isEqual } from 'lodash';
import { HomeCellComponent } from './HomeCellComponent';
import NextStepProvider from './grid/providers/NextStepProvider';
import MozartActionProvider from './grid/providers/MozartActionProvider';
import EpisodeStatusProvider from 'components/home/grid/providers/EpisodeStatusProvider';
import saveAs from 'file-saver';
import { Workbook } from 'exceljs';
import moment from 'moment';
import { IExportOption } from 'components/home/TabsBody';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';
import { getSelectedRow } from './grid/GridHeaderPanel.helper';

const StyledGridRoot = styled(Grid.Root)({
  height: '69vh',
  [theme.breakpoints.up('md')]: {
    height: '67vh',
  },
  [theme.breakpoints.down('lg')]: {
    height: '75vh',
  },
  [theme.breakpoints.down('md')]: {
    height: '67vh',
  },
});

const GridRoot = (props: GridBase.RootProps) => {
  return <StyledGridRoot {...props} />;
};

export const homeModelSelector = createSelector(
  (state: IState) => state.home.homeModel,
  (model) => model
);

export const homeGridData = createSelector(
  (state: IState) => state.home.data,
  (model) => model
);

const HomeGrid = ({
  isLoadingCompleted,
  handleSetLoadingComplete,
  handleTriggerExportGridData,
  gridExportOptions,
}: {
  isLoadingCompleted: boolean;
  handleSetLoadingComplete: (loadingComplete: boolean) => void;
  handleTriggerExportGridData: (exportOptions: IExportOption) => void;
  gridExportOptions: IExportOption;
}) => {
  const homeModel = useSelector(homeModelSelector);
  const columns = useSelector((state: IState) => state.home.columns ?? []);
  const filterValues = useSelector(
    (state: IState) => state.home.filterValues ?? []
  );
  const showProgress = useSelector((state: IState) => state.home.showProgress);
  const gridData = useSelector(homeGridData);
  const selection = useSelector(
    (state: IState) => state.home?.homeModel?.selection ?? []
  );
  const refetchData = useSelector((state: IState) => state.home.forceUpdate);
  const isLoading = useSelector((state: IState) => state.home.loading);

  const dispatch = useDispatch();

  const [setUserPreference] = useSetPreferenceMutation();
  const [saveColumnWidth] = useSaveColumnWidthMutation();

  const request = {
    first:
      Number(homeModel?.page ?? 0) *
        Number(homeModel?.settings?.pageSize ?? 50) +
      1,
    pageSize: Number(homeModel?.settings?.pageSize ?? 50),
    tabId: homeModel?.activeTab,
    isActive: homeModel?.settings?.showActive as boolean,
    filterBy: homeModel?.filterBy?.map((x) => ({
      columnName: x.columnName,
      operation: x.operation,
      value:
        x.columnName === 'Episode_WorkflowCurrentStepName' &&
        x.value?.toLowerCase() === 'completed'
          ? 'Close Episode'
          : x.value,
    })),
    orderBy: homeModel?.orderBy?.map((x) => ({
      columnName: x.columnName,
      direction: x.direction === 'asc' ? 0 : 1,
    })),
    episodeState: homeModel?.episodeState,
    fullSearch: homeModel?.fullSearch?.trim(),
    advancedSearchOption: homeModel?.advancedSearchOption,
  } as IRowRequest;

  const prevRequest = usePrevious(request);
  const isRequestUpdated = !isEqual(request, prevRequest);

  useEffect(() => {
    if (refetchData) {
      dispatch(fetchGridRows(request));
      return;
    }
    if (
      isLoadingCompleted &&
      !isLoading &&
      request.episodeState !== undefined
    ) {
      dispatch(fetchGridRows(request));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchData, isLoadingCompleted, isRequestUpdated]);

  useEffect(() => {
    if (
      gridData?.rows.length > 0 &&
      getSelectedRow(gridData, selection[0]) === null
    ) {
      dispatch(resetSelection());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);
  const handleSetSelection = (curSelection: (string | number)[]) => {
    const indices = curSelection.map(Number);
    dispatch(updateSelection(indices));
  };

  const handleSetSorting = (sorting: Sorting[]) => {
    handleSetLoadingComplete(true);
    dispatch(updateSorting(sorting));
  };

  const handleSetFilters = (filters: Filter[]) => {
    handleSetLoadingComplete(true);
    dispatch(updateFilters(filters));
  };

  const handleSetCurrentPage = (currentPage: number) => {
    handleSetLoadingComplete(true);
    dispatch(updatePage(currentPage));
  };

  const handleSetPageSize = (currentSize: number) => {
    handleSetLoadingComplete(true);
    dispatch(updatePageSize(currentSize));
    setUserPreference({
      name: PATIENT_LIST_SIZE,
      value: currentSize.toString(),
    });
  };

  const handleColumnWidthChange = (changed: TableColumnWidthInfo[]) => {
    const { width, modifiedWidth } = getUpdatedColumnWidths(
      columnWidths,
      changed,
      homeModel.activeTab
    );

    if (modifiedWidth) {
      dispatch(updateColumnWidths(width));

      if (homeModel.activeTab !== SearchTab) {
        saveColumnWidth({ model: modifiedWidth });
      }
    }
  };

  const {
    gridColumns,
    gridColumnsExtensions,
    sortingColumnExtensions,
    tableColumnExtensions,
  } = useMemo(
    () => ({
      gridColumns: getColumns(columns, filterValues),
      gridColumnsExtensions: getColumnsExtensions(columns, showProgress),
      tableColumnExtensions: getTableColumnsExtensions(columns, showProgress),
      sortingColumnExtensions: getSortingColumnExtensions(
        columns,
        showProgress
      ),
    }),
    [columns, filterValues, showProgress]
  );

  const noData = getNoDataMessage(homeModel);

  const onSave = useCallback(
    (workbook: Workbook) => {
      workbook.worksheets[0].name = 'MemberData';
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `MemberData_${moment().format(MOMENT_DATETIME_FORMAT)}.xlsx`
        );
        dispatch(saveFilterSelection(request));
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRequestUpdated]
  );

  const exporterRef = useRef<typeof GridExporter>(null);

  const startExport = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (options: any) => {
      exporterRef.current?.exportGrid(options);
    },
    [exporterRef]
  );

  useEffect(() => {
    if (gridExportOptions.isExportingGridData) {
      startExport({
        selectedOnly: gridExportOptions.exportSelection,
      });
      handleTriggerExportGridData({ isExportingGridData: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridExportOptions]);

  // fix invalid widths
  const columnWidths = (
    homeModel?.columnWidth ?? ([] as TableColumnWidthInfo[])
  ).map((info) => ({
    ...info,
    width: Math.max(0, info.width as number),
    tabIndex: homeModel.activeTab,
  }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getExportCellValue = (row: any, columnName: string) => {
    const column = row[columnName];
    switch (columnName) {
      case 'Episode_AuthorizationStatus':
      case 'Episode_AppealStatus':
        return column?.name ?? '';
      case 'Episode_Clock':
        return column?.state
          ? `State: ${column?.state} Due Date: ${formatDateTime(
              column?.dueDateTime
            )} Remaining: ${column?.timeRemaining}`
          : '';
      case 'Episode_Counter':
        return column?.state
          ? `State: ${column?.state} Elapsed: ${column?.timeElapsed}`
          : '';
      case 'Patient_Tags':
        return column?.length > 0
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            column?.map((item: any) => item.name).join(', ')
          : '';
      case 'PatientReminder_Reason':
      case 'Patient_ReminderReason':
        return column?.name ?? '';
      default:
        return column ?? '';
    }
  };

  return (
    <>
      <Grid
        rows={gridData?.rows ?? []}
        columns={gridColumns}
        rootComponent={GridRoot}
        getRowId={(row) => row.id}
      >
        <TooltipProvider for={gridColumns.map((i) => i.name)} />
        <ReminderImportanceProvider for={['Patient_ReminderIcon']} />
        <ReminderOverdueProvider for={['Patient_ReminderOverdue']} />
        <RisingRiskProvider for={['Patient_RisingRiskAlert']} />
        <InProgressProvider for={['Episode_Progress']} />
        <EpisodesListClockProvider for={['Episode_Clock']} />
        <EpisodesListClockProvider for={['Episode_Counter']} />
        <TagsProvider for={['Patient_Tags']} />
        <ReminderReasonProvider
          for={['Patient_ReminderReason', 'PatientReminder_Reason']}
        />
        <PatientMRNProvider for={['Patient_MRN']} />
        <EpisodeNumberProvider for={['Episode_EpisodeNumber']} />
        <NextStepProvider for={['Episode_WorkflowCurrentStepName']} />
        <EpisodeStatusProvider for={['Episode_AuthorizationStatus']} />
        <EpisodeStatusProvider for={['Episode_AppealStatus']} />
        <MozartActionProvider for={['Episode_AvailableOOTSteps']} />
        <PagingState
          currentPage={Number(homeModel?.page ? homeModel.page : 0)}
          onCurrentPageChange={handleSetCurrentPage}
          onPageSizeChange={handleSetPageSize}
          pageSize={Number(homeModel?.settings?.pageSize ?? 50)}
        />
        <CustomPaging totalCount={Number(gridData?.count ?? 0)} />
        <FilteringState
          filters={homeModel?.filterBy ?? ([] as Filter[])}
          columnExtensions={gridColumnsExtensions}
          onFiltersChange={handleSetFilters}
        />
        <SortingState
          sorting={homeModel?.orderBy ?? ([] as Sorting[])}
          onSortingChange={handleSetSorting}
          columnExtensions={sortingColumnExtensions}
        />
        <SelectionState
          selection={selection}
          onSelectionChange={handleSetSelection}
        />
        <IntegratedSelection />
        <VirtualTable
          tableComponent={TableComponent}
          cellComponent={HomeCellComponent}
          rowComponent={RowComponent}
          columnExtensions={tableColumnExtensions}
          noDataCellComponent={NoDataCellComponent}
          messages={{ noData }}
        />
        <TableColumnResizing
          columnWidths={columnWidths}
          onColumnWidthsChange={handleColumnWidthChange}
          resizingMode="widget"
          minColumnWidth={47}
        />

        <SymphonyTableHeaderRow showSortingControls />
        <TableFilterRow
          cellComponent={FilterCell}
          editorComponent={FilterEditor}
          rowComponent={FilterRow}
        />
        <TableSelection
          cellComponent={SelectCell}
          headerCellComponent={HeaderSelectCell}
          rowComponent={SelectedRow}
          highlightRow
          selectByRowClick
          showSelectAll
          selectionColumnWidth={40}
        />
        <PagingPanel
          pageSizes={[15, 50, 100, 250]}
          containerComponent={PagingContainer}
        />
      </Grid>
      <GridExporter
        ref={exporterRef}
        columns={gridColumns}
        rows={gridData?.rows ?? []}
        selection={selection}
        onSave={onSave}
        getCellValue={getExportCellValue}
      />
    </>
  );
};

export default HomeGrid;
