import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableEditColumn,
  TableEditRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  ChangeSet,
  Column,
  EditingState,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  GridEditActions,
  StubCellComponent,
  SymphonyTable,
  SymphonyTableHeaderRow,
  TableEditCell,
} from 'components/gridFormatters';
import {
  CareGapsColumnName,
  careGapsColumnsExtensionEditing,
  careGapsColumnsExtensionSorting,
  careGapsColumnsExtensionStyles,
} from './careGaps';
import { Box, Button, Typography } from '@mui/material';
import { ILookupValue, PatientCareGap } from 'graphql/graphqlTypes';
import { Getter } from '@devexpress/dx-react-core';
import { debounce } from 'lodash';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { MOMENT_DATE_FORMAT } from 'components/constants';
import { IState } from 'store';
import { useSelector } from 'react-redux';
import { rearrangeColumnsEditLast } from 'components/home/attachmentsList/attachments.helpers';
import { hasAnyError } from 'components/home/patientdetails/tabscontent/careTeamTab/careTeam.helpers';
import moment from 'moment';

interface IGridProps<T> {
  title: string;
  itemName: string;
  getRowId: (item: T) => number;
  rows: T[];
  commitChanges: (changeSet: ChangeSet) => void;
  saveChanges: (changeSet: ChangeSet) => void;
  columns: Column[];
  labelCell: React.ComponentType<Table.DataCellProps>;
  editCell: React.ComponentType<TableEditRow.CellProps>;
  handleUpdatePatientTabValid: (valid: boolean) => void;
  editEnabled: boolean;
  setEditEnabled: (status: boolean) => void;
}

const CareGapsGrid = <T extends PatientCareGap>(props: IGridProps<T>) => {
  const {
    title,
    itemName,
    getRowId,
    rows,
    columns,
    commitChanges,
    saveChanges,
    labelCell,
    editCell,
    handleUpdatePatientTabValid,
    editEnabled,
    setEditEnabled,
  } = props;
  const currentUser = useSelector((state: IState) => state.user.currentUser);
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const [editingRowIds, setEditingRowIds] = useState<number[]>([]);

  const addCareGap = () => {
    const ids = (rows ?? []).map((x) => Math.abs(x.id)) ?? [];
    const nextId = ids.length > 0 ? -1 * (Math.max(...ids) + 1) : -1;
    const newRow = {
      id: nextId,
      patientId: patientId,
      createdOn: moment().format(MOMENT_DATE_FORMAT),
      createdBy: {
        id: currentUser.id,
        name: currentUser.name,
      } as ILookupValue,
    } as PatientCareGap;
    commitChanges({ added: [newRow] });
    setEditingRowIds([...editingRowIds, nextId]);
    setEditEnabled(false);
  };

  const [errors, setErrors] = useState({});

  const validate = (
    rowsToValidate: PatientCareGap[],
    columnsToValidate: Column[]
  ) =>
    Object.entries(rowsToValidate).reduce(
      (acc, [rowId, row]) => ({
        ...acc,
        [rowId]: columnsToValidate.some(
          (column) =>
            (column.name === CareGapsColumnName.CareGap &&
              (row.careGap === undefined || row.careGap === '')) ||
            (column.name === CareGapsColumnName.Priority &&
              (row.priority === undefined ||
                row?.priority?.name === '' ||
                row?.priority?.name === undefined)) ||
            (column.name === CareGapsColumnName.Status &&
              (row.status === undefined ||
                row?.status?.name === '' ||
                row?.status?.name === undefined))
        ),
      }),
      {}
    );

  const updatePatientTabValidity = () => {
    const validation = validate(rows, columns);
    handleUpdatePatientTabValid(!hasAnyError(validation));
  };

  const validateAndSetErrors = (rowsToValidate: PatientCareGap[]) => {
    const [id] = editingRowIds;
    const columnToValidate =
      id > 0 && rowsToValidate[id]
        ? columns.filter((x) =>
            Object.keys(rowsToValidate[id]).includes(x.name)
          )
        : columns;
    const validation = validate(rowsToValidate, columnToValidate);
    updatePatientTabValidity();
    setErrors(validation);
  };

  const onEdited = debounce((edited) => {
    validateAndSetErrors(edited);
  }, 250);

  const onCancel = () => {
    const [id] = editingRowIds;
    if (id > 0) {
      return;
    }
    const cancelRow = rows.find((x) => x.id === id)!;
    const validation = validate([cancelRow], columns);
    const hasErrors = hasAnyError(validation);

    if (hasErrors) {
      commitChanges({ deleted: [id] });
    }
  };

  useEffect(() => {
    validateAndSetErrors(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, rows]);

  return (
    <Box mb="32px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="16px"
      >
        <Typography variant="subtitle1">{title}</Typography>
        <Box>
          <Button
            onClick={addCareGap}
            color="primary"
            size="small"
            variant="contained"
            startIcon={<Icon icon={ICONS.Add} size="14" color={COLORS.WHITE} />}
            disabled={!editEnabled}
          >
            {`Add ${itemName}`}
          </Button>
        </Box>
      </Box>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <EditingState
          onCommitChanges={saveChanges}
          columnExtensions={careGapsColumnsExtensionEditing}
          onRowChangesChange={onEdited}
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={(curEditingRowIds: Array<number | string>) => {
            setEditingRowIds(curEditingRowIds as number[]);
            setEditEnabled(curEditingRowIds.length === 0);
          }}
        />
        <SortingState
          defaultSorting={[
            { columnName: CareGapsColumnName.UpdatedOn, direction: 'desc' },
            { columnName: CareGapsColumnName.CreatedOn, direction: 'desc' },
          ]}
          columnExtensions={careGapsColumnsExtensionSorting}
        />
        <IntegratedSorting />
        <SymphonyTable
          cellComponent={labelCell}
          columnExtensions={careGapsColumnsExtensionStyles}
        />
        <SymphonyTableHeaderRow showSortingControls />
        <TableEditRow cellComponent={editCell} />
        <TableEditColumn
          showEditCommand={editEnabled}
          showDeleteCommand={editEnabled}
          headerCellComponent={StubCellComponent}
          cellComponent={TableEditCell}
          width={160}
          commandComponent={(comProps) => (
            <GridEditActions
              {...comProps}
              errors={hasAnyError(errors)}
              editEnabled={editEnabled}
              itemName={'gap'}
              onCancel={onCancel}
            />
          )}
        />
        <Getter name="tableColumns" computed={rearrangeColumnsEditLast} />
      </Grid>
    </Box>
  );
};

export default CareGapsGrid;
