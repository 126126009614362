import { Dispatch } from 'redux';
import {
  authChangeMfaStep,
  authChangePassword,
  authFail,
  authLogout,
  authSelectProvider,
  authSelectRole,
  authStart,
  authSuccess,
} from './action';
import axiosGitHubGraphQL from 'util/axiosHttp';
import { Cookies } from 'react-cookie';
import {
  IChangePassword,
  IChangeProvider,
  ILoginUser,
  ILogoutUser,
  IMfaStep,
  ITokenRequest,
} from './types';
import { IAuthUser } from 'backend/types/authUser';
import { authSuccessful, clearAuthUser } from 'util/authUtils';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { AxiosResponse } from 'axios';
import { NavigateFunction } from 'react-router/dist/lib/hooks';

const INTERNAL_SERVER_ERROR = 'Cannot Login: Internal Server Error';

export const checkAuthentication =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(authStart());
    const cookies = new Cookies();
    const token = cookies.get('.AspNetCore.Application.Id');
    if (token) {
      const user = localStorage?.getItem('authUser') ?? '{}';
      const keyOf = <T>(key: keyof T) => key;
      const userData: IAuthUser = JSON.parse(user, (key, value) => {
        return key === keyOf<IAuthUser>('tokenExpiryInClientTime') ||
          key === keyOf<IAuthUser>('tokenEarliestRefreshInClientTime')
          ? new Date(value)
          : value;
      });
      dispatch(authSuccess(userData));
    } else {
      localStorage.removeItem('authUser');
      dispatch(authLogout());
    }
  };

//Only for initial UI testing. Most of the logic below should be on the server.
export const loginUser =
  (user: ILoginUser, loginType = 'login') =>
  async (dispatch: Dispatch): Promise<void> =>
    axiosGitHubGraphQL
      .post(routeMap[loginType], user)
      .then((result) => {
        if (result.data.isError) {
          dispatch(authFail(result.data.message, result.data.captchaRequired));
          return;
        }
        handleNextAction(dispatch, user, result, {} as IMfaStep);
      })
      .catch((err) => {
        handleError(dispatch, err, INTERNAL_SERVER_ERROR);
      });

const handleError = (
  dispatch: Dispatch,
  error: string,
  errorMessage: string,
  captchaRequired = false
) => {
  dispatch(showErrorPopup({ message: errorMessage }));
  dispatch(authFail(error, captchaRequired));
};

const routeMap: { [key: string]: string } = {
  login: 'login/startjwt',
  passwordReset: 'login/ResetPassword',
};

const handleNextAction = (
  dispatch: Dispatch,
  user: ILoginUser,
  result: AxiosResponse,
  mfaStepCtx: IMfaStep
) => {
  const stepMap: { [key: string]: string } = {
    verifyCode: 'verifyCode',
    confirm: 'phoneVerified',
    updatePassword: 'updatePassword',
    selectProvider: 'selectProvider',
    returnUser: 'verifyCode',
    enterPhone: 'enterPhone',
    passwordResetCode: 'verifyCode',
  };
  switch (result.data.step) {
    case 'needToChangePassword':
    case 'changePassword':
      dispatch(authChangePassword(user));
      break;
    case 'passwordResetConfirm':
      dispatch(authChangePassword(user, mfaStepCtx.code));
      break;
    case 'roles':
      dispatch(authSelectRole(user));
      break;
    case 'selectProvider':
      authSuccessful(result, dispatch, false);
      user.login = result.data.login;
      dispatch(authSelectProvider(user));
      break;
    case 'enterPhone':
    case 'passwordResetCode':
    case 'returnUser': {
      const { phone, mfaType, step } = result.data;
      dispatch(authChangeMfaStep(user, phone, mfaType, stepMap[step]));
      break;
    }
    case 'confirm':
    case 'verifyCode':
    case 'updatePassword':
      dispatch(
        authChangeMfaStep(
          user,
          mfaStepCtx.phone,
          mfaStepCtx.mfaType,
          stepMap[result.data.step]
        )
      );
      break;
    default:
      authSuccessful(result, dispatch);
  }
};

export const changePassword =
  (passwordCtx: IChangePassword, code?: string | null) =>
  async (dispatch: Dispatch): Promise<void> =>
    axiosGitHubGraphQL
      .post('login/changepassword', { ...passwordCtx, code: code })
      .then((result) => {
        if (result.data.isError) {
          handleError(dispatch, 'error', result.data.message);
          return;
        }
        const user: ILoginUser = {
          login: passwordCtx.login,
          password: passwordCtx.newpassword,
          captcha: '',
        };
        handleNextAction(dispatch, user, result, {} as IMfaStep);
      })
      .catch((err) => {
        handleError(dispatch, err, INTERNAL_SERVER_ERROR);
      });

export const changeMfaStep =
  (mfaStepCtx: IMfaStep, step: string) =>
  async (dispatch: Dispatch): Promise<void> =>
    axiosGitHubGraphQL
      .post('login/MfaStep', { ...mfaStepCtx, step: step })
      .then((result) => {
        if (result.data.isError) {
          handleError(dispatch, 'error', result.data.message);
          return;
        }
        const user: ILoginUser = {
          login: mfaStepCtx.login,
          password: mfaStepCtx.password,
          captcha: '',
        };
        handleNextAction(dispatch, user, result, mfaStepCtx);
      })
      .catch((err) => {
        handleError(dispatch, err, INTERNAL_SERVER_ERROR);
      });

export const logoutUser =
  (
    userCtx: ILogoutUser,
    navigate: NavigateFunction,
    doRedirect: boolean,
    shouldRefresh?: boolean
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    axiosGitHubGraphQL
      .post('login/logout', userCtx)
      .then((result) => {
        if (result.data.isError) {
          handleError(dispatch, 'error', result.data.message);
          return;
        }
        clearAuthUser();
        const refresh =
          doRedirect && result.data.redirectTo ? false : Boolean(shouldRefresh); // no refresh, because it cancels redirect
        dispatch(authLogout(refresh));
        if (doRedirect) {
          if (result.data.redirectTo) {
            window.location.href = result.data.redirectTo;
          } else {
            navigate(`/`);
          }
        }
      })
      .catch(() => {
        dispatch(
          showErrorPopup({ message: 'Cannot Logout: Internal Server Error' })
        );
        if (doRedirect) {
          navigate(`/`);
        }
        dispatch(authLogout());
      });
  };

export const refreshToken =
  (tokenRequest: ITokenRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await axiosGitHubGraphQL
        .post('login/refreshtoken', tokenRequest)
        .then((result) => {
          if (result.data.isError) {
            dispatch(showErrorPopup({ message: result.data.message }));
            dispatch(authLogout());
            return;
          }
          authSuccessful(result, dispatch);
        })
        .catch(() => {
          dispatch(
            showErrorPopup({
              message: 'Cannot Refresh Session: Internal Server Error',
            })
          );
          dispatch(authLogout());
        });
    } catch (Exception) {
      dispatch(
        showErrorPopup({
          message: 'Cannot Refresh Session: Internal Server Error',
        })
      );
    }
  };

export const changeProvider =
  (providerCtx: IChangeProvider, callback?: (success: boolean) => void) =>
  async (dispatch: Dispatch): Promise<void> =>
    axiosGitHubGraphQL
      .post('login/changeProvider', providerCtx)
      .then((result) => {
        if (result.data.isError) {
          handleError(dispatch, 'error', result.data.message);
          if (callback) {
            callback(false);
          }
          return;
        }
        handleNextAction(dispatch, {} as ILoginUser, result, {} as IMfaStep);
        if (callback) {
          callback(true);
        }
      })
      .catch((err) => {
        handleError(dispatch, err, INTERNAL_SERVER_ERROR);
        if (callback) {
          callback(false);
        }
      });
