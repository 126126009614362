import { IComponentProps } from '../types';
import { EntityAttributeComponent } from 'graphql/graphqlTypes';
import { ILookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';
import React from 'react';
import { Radio } from '@mui/material';
import { IRadioSelection } from './LookupAttribute';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';

const StyledVerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledFormControlLabel = styled(FormControlLabel)({
  alignSelf: 'flex-start',
  marginRight: 0,
});

export interface ILookupRadiobuttonListProps
  extends IComponentProps<EntityAttributeComponent> {
  lookup?: ILookup;
  selectedValues: ILookupValue[];
  isReadOnly: boolean;
}

export const LookupRadiobuttonList = (props: ILookupRadiobuttonListProps) => {
  const {
    component,
    lookup,
    selectedValues,
    handleChecklistInputChange,
    isReadOnly,
  } = props;

  const [selectedValue, setSelectedValue] =
    React.useState<IRadioSelection | null>(() => {
      if (selectedValues.length === 0) {
        return null;
      } else {
        return {
          radioValue: selectedValues[0].id,
          radioId: component.id,
        } as IRadioSelection;
      }
    });

  const radioName = `lookup_${component.id}_${lookup?.id}`;
  const handleRadioInputChange = (value: number) => {
    setSelectedValue({
      radioId: component.id,
      radioValue: value,
    });
    handleChecklistInputChange(
      value.toString(),
      {
        ...props.component,
        groupId: null,
        type: 'string',
      },
      component.id
    );
  };

  return (
    <StyledVerticalBox>
      {lookup?.values.map((lv: ILookupValue, lvIndex: number) => (
        <StyledFormControlLabel
          key={lvIndex}
          value={lv.id}
          control={
            <Radio
              color="primary"
              disabled={isReadOnly}
              checked={selectedValue?.radioValue === lv.id}
              name={radioName}
              value={lv.id}
              onChange={() => handleRadioInputChange(lv.id)}
            />
          }
          label={lv.name}
          labelPlacement="end"
          color={component.color ? component.color : undefined}
        />
      ))}
    </StyledVerticalBox>
  );
};
