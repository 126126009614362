import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetCarePlanQueryVariables = Types.Exact<{
  request: Types.PatientCarePlanFilterModelInput;
}>;

export type GetCarePlanQuery = {
  __typename?: 'EntitiesQuery';
  getCarePlan?: {
    __typename?: 'PatientCarePlanViewModel';
    checklist: {
      __typename?: 'ChecklistViewModel';
      id: string;
      episodeId?: number | null;
      episodeNumber?: string | null;
      patientId: number;
      type: Types.ChecklistType;
      createdOn: any;
      updatedOn?: any | null;
      finishedOn?: any | null;
      scenarioId?: number | null;
      scenarioName?: string | null;
      amended: boolean;
      status: Types.ChecklistStatus;
      canAmend: boolean;
      updated: boolean;
      lettersIsAvailableForUser: boolean;
      author: { __typename?: 'User'; fullName?: string | null };
      reasonForNote?: {
        __typename?: 'LookupValue';
        name?: string | null;
      } | null;
      owner: { __typename?: 'User'; fullName?: string | null };
      updatedBy?: { __typename?: 'User'; fullName?: string | null } | null;
      categories: Array<{
        __typename?: 'ChecklistCategory';
        id: string;
        name: string;
        isVisible: boolean;
        items: Array<
          | { __typename?: 'ChecklistItemConditions' }
          | { __typename?: 'ChecklistItemDuplicateCheck' }
          | { __typename?: 'ChecklistItemHealthServices' }
          | { __typename?: 'ChecklistItemLetterSummary' }
          | {
              __typename?: 'ChecklistItemMedicalCodes';
              uid: string;
              orderableId: string;
              type: string;
              isSelected: boolean;
              isVisible: boolean;
              options: {
                __typename?: 'MedicationCodesOrderableOptions';
                minRequired: number;
                maxAllowed: number;
                itemType: Types.MedicationCodeItemType;
                type: Types.MedicationCodeType;
                descriptionLabel: string;
                codeLabel: string;
                category?: {
                  __typename?: 'LookupValue';
                  id: number;
                  name?: string | null;
                } | null;
              };
              value?: Array<{
                __typename?: 'MedicationCodesActionValue';
                id: number;
                code: string;
                description?: string | null;
                notificationMessage?: string | null;
              } | null> | null;
            }
          | { __typename?: 'ChecklistItemMedications' }
          | {
              __typename?: 'ChecklistItemNote';
              uid: string;
              orderableId: string;
              type: string;
              preview?: string | null;
              isSelected: boolean;
              isVisible: boolean;
            }
          | {
              __typename?: 'ChecklistItemOrderable';
              uid: string;
              orderableId: string;
              type: string;
              listStyleType: Types.ListStyleType;
              score?: number | null;
              scoringSystemGroup?: number | null;
              isSelected: boolean;
              isVisible: boolean;
              hasRepeater: boolean;
              createdOn?: any | null;
              createdBy?: string | null;
              orderSet: {
                __typename?: 'OrderSetViewModel';
                problems?: Array<{
                  __typename?: 'Problem';
                  id: number;
                  name?: string | null;
                }> | null;
              };
              repeaterOptions?: {
                __typename?: 'RepeaterOrderableOptions';
                buttonLabel?: string | null;
                canRepeat?: boolean | null;
                readOnlyOnFinish?: boolean | null;
                showDescription?: boolean | null;
              } | null;
              qualityMeasure?: {
                __typename?: 'QualityMeasure';
                displayName?: string | null;
                id: string;
                isReadOnly: boolean;
                measureLevel?: string | null;
                name?: string | null;
                userChoice: number;
              } | null;
              conditions: Array<{
                __typename?: 'ConditionViewModel';
                type: Types.OrderableDependentConditionType;
                targetType: Types.ConditionTarget;
                value?: string | null;
                categoryId?: string | null;
                orderableId?: string | null;
              }>;
              lines: Array<{
                __typename?: 'ComponentsLine';
                components: Array<
                  | {
                      __typename?: 'ActionButtonComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      required: boolean;
                      type?: string | null;
                      actionButtonStyle?: string | null;
                      entity?: string | null;
                      providerAuthorizationRole?: string | null;
                      attributeForUpdate?: string | null;
                      attributeForSource?: string | null;
                      metComponentId?: string | null;
                      comment?: string | null;
                      isDrawableOnFinish: boolean;
                      displayOnlyWhenSelected: boolean;
                    }
                  | { __typename?: 'AutobalancerComponent' }
                  | {
                      __typename?: 'CheckboxRadioButtonComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      required: boolean;
                      type?: string | null;
                      editable: boolean;
                      groupId?: string | null;
                      selected: boolean;
                      scoringGroup?: string | null;
                      score?: string | null;
                      groupRequired: boolean;
                      checked: boolean;
                      problem?: string | null;
                      hasValue: boolean;
                      clearAfterFinish: boolean;
                      labelSource?: string | null;
                      displayOnlyWhenSelected: boolean;
                      hidden: boolean;
                    }
                  | { __typename?: 'ClipToolComponent' }
                  | {
                      __typename?: 'ComputedValueComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      required: boolean;
                      computedValueType?: Types.ComputedValueType | null;
                      scoringGroupId?: number | null;
                      mode?: Types.TimerValueType | null;
                      fieldId?: string | null;
                      fieldSourceId?: string | null;
                      attributeForUpdate?: string | null;
                      showTargetDate?: boolean | null;
                      daysType?: Types.TimerDaysType | null;
                      dangerValue?: number | null;
                      additionalValue?: number | null;
                      timeScale?: Types.TimerScaleType | null;
                      displayOnlyWhenSelected: boolean;
                      targetDate?: any | null;
                    }
                  | {
                      __typename?: 'DateComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      linkMessage?: string | null;
                      mirrorGroup?: string | null;
                      oneWaySrc?: string | null;
                      required: boolean;
                      useCurrentDate: boolean;
                      pastDatesNotAllowed: boolean;
                      daysBeforeLimit?: number | null;
                      daysAfterLimit?: number | null;
                      displayOnlyWhenSelected: boolean;
                    }
                  | {
                      __typename?: 'DateTimeComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      linkMessage?: string | null;
                      mirrorGroup?: string | null;
                      oneWaySrc?: string | null;
                      required: boolean;
                      useSeconds: boolean;
                      useCurrentDate: boolean;
                      pastDatesNotAllowed: boolean;
                      daysBeforeLimit?: number | null;
                      daysAfterLimit?: number | null;
                      displayOnlyWhenSelected: boolean;
                    }
                  | {
                      __typename?: 'DropdownComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      dropDownType: Types.DropdownType;
                      linkMessage?: string | null;
                      mirrorGroup?: string | null;
                      oneWaySrc?: string | null;
                      required: boolean;
                      autocomplete: boolean;
                      displayOnlyWhenSelected: boolean;
                      isBigTable: boolean;
                      choices?: Array<string> | null;
                      lookupId?: string | null;
                      entityName?: string | null;
                    }
                  | {
                      __typename?: 'EntityAttributeComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      linkMessage?: string | null;
                      mirrorGroup?: string | null;
                      oneWaySrc?: string | null;
                      required: boolean;
                      readonly: boolean;
                      displayOnlyWhenSelected: boolean;
                      entityName?: string | null;
                      fieldName?: string | null;
                      multiValueType?: string | null;
                      showDateTime: boolean;
                      selectDateTime: boolean;
                      multiline: boolean;
                      useSeconds: boolean;
                      useCurrentDate: boolean;
                      daysBeforeLimit?: number | null;
                      daysAfterLimit?: number | null;
                      maxWidth: number;
                      autocomplete: boolean;
                      isBigTable: boolean;
                      attributeType?: string | null;
                    }
                  | {
                      __typename?: 'ExpressionComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      componentType: string;
                      userDefinedId?: string | null;
                      uniqueID: string;
                      name?: string | null;
                      value?: string | null;
                      required: boolean;
                      attributeForUpdate?: string | null;
                      expressionString?: string | null;
                      readonlyAfterFinish?: boolean | null;
                      isReadonlyAfterFinishSet?: boolean | null;
                      isDrawableOnFinish: boolean;
                      displayOnlyWhenSelected: boolean;
                    }
                  | { __typename?: 'ExternalUrlComponent' }
                  | {
                      __typename?: 'FixedTextComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      value?: string | null;
                      displayOnlyWhenSelected: boolean;
                      hidden: boolean;
                    }
                  | {
                      __typename?: 'ReminderComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      required: boolean;
                      notes?: string | null;
                      daysForward: number;
                      dayType: Types.DayType;
                      daysForwardFrom?: string | null;
                      reasonIdSelected: number;
                      reasonDisplayValue?: string | null;
                      reasonImageValue?: string | null;
                      importanceIdSelected: Types.ReminderComponentImportance;
                      defaultUserToRemind: Types.ReminderComponentDefaultUser;
                      reminderType: Types.ReminderType;
                      teamMemberIdSelected: number;
                      teamMemberDisplayValue?: string | null;
                      reminderId: number;
                      dateDue: any;
                      comment?: string | null;
                      displayOnlyWhenSelected: boolean;
                      isReminderCreated?: boolean | null;
                      isReminderModified?: boolean | null;
                    }
                  | {
                      __typename?: 'UserFreeTextComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      linkMessage?: string | null;
                      mirrorGroup?: string | null;
                      oneWaySrc?: string | null;
                      useAutocomplete: boolean;
                      required: boolean;
                      type?: string | null;
                      maxLen: number;
                      minLen: number;
                      maxVal: number;
                      minVal: number;
                      maxWidth: number;
                      multiline: boolean;
                      displayAsIcon: boolean;
                      displayOnlyWhenSelected: boolean;
                      readonly: boolean;
                    }
                >;
              }>;
            }
          | {
              __typename?: 'ChecklistItemPriorAuthCodes';
              uid: string;
              orderableId: string;
              type: string;
              isVisible: boolean;
              isSelected: boolean;
              options: {
                __typename?: 'PriorAuthCodesOrderableOptions';
                minRequired: number;
                maxAllowed: number;
                type: Types.MedicationCodeType;
                itemType: Types.MedicationCodeItemType;
                codeLabel: string;
                descriptionLabel: string;
                pAAllowSelection: boolean;
                pAShowCheckPolicy: boolean;
                category?: {
                  __typename?: 'LookupValue';
                  id: number;
                  name?: string | null;
                } | null;
              };
              pAValue?: {
                __typename?: 'PriorAuthCodesActionModel';
                id: number;
                reqStartDate?: any | null;
                reqEndDate?: any | null;
                status?: Types.PriorAuthInsuranceStatus | null;
                notificationMessage?: string | null;
                priorAuthCodesActionValues: Array<{
                  __typename?: 'PriorAuthCodesActionValue';
                  id: number;
                  code: string;
                  byCodeFound: boolean;
                  description?: string | null;
                  notificationMessage?: string | null;
                  isPARequired: boolean;
                  externalURL?: string | null;
                  priorAuthRequirement?: Types.PaRequirement | null;
                  primaryMessage?: string | null;
                  secondaryMessage?: string | null;
                  isDeleted?: boolean | null;
                }>;
              } | null;
            }
          | { __typename?: 'ChecklistItemReminder' }
          | {
              __typename?: 'ChecklistItemSubheader';
              uid: string;
              orderableId: string;
              type: string;
              preview?: string | null;
              isSelected: boolean;
              isVisible: boolean;
            }
        >;
        subCategories: Array<{
          __typename?: 'ChecklistCategory';
          id: string;
          name: string;
          items: Array<
            | { __typename?: 'ChecklistItemConditions' }
            | { __typename?: 'ChecklistItemDuplicateCheck' }
            | { __typename?: 'ChecklistItemHealthServices' }
            | { __typename?: 'ChecklistItemLetterSummary' }
            | {
                __typename?: 'ChecklistItemMedicalCodes';
                uid: string;
                orderableId: string;
                type: string;
                isSelected: boolean;
                isVisible: boolean;
                options: {
                  __typename?: 'MedicationCodesOrderableOptions';
                  minRequired: number;
                  maxAllowed: number;
                  itemType: Types.MedicationCodeItemType;
                  type: Types.MedicationCodeType;
                  descriptionLabel: string;
                  codeLabel: string;
                  category?: {
                    __typename?: 'LookupValue';
                    id: number;
                    name?: string | null;
                  } | null;
                };
                value?: Array<{
                  __typename?: 'MedicationCodesActionValue';
                  id: number;
                  code: string;
                  description?: string | null;
                  notificationMessage?: string | null;
                } | null> | null;
              }
            | { __typename?: 'ChecklistItemMedications' }
            | {
                __typename?: 'ChecklistItemNote';
                uid: string;
                orderableId: string;
                type: string;
                preview?: string | null;
                isSelected: boolean;
                isVisible: boolean;
              }
            | {
                __typename?: 'ChecklistItemOrderable';
                uid: string;
                orderableId: string;
                type: string;
                listStyleType: Types.ListStyleType;
                score?: number | null;
                scoringSystemGroup?: number | null;
                isSelected: boolean;
                isVisible: boolean;
                hasRepeater: boolean;
                createdOn?: any | null;
                createdBy?: string | null;
                orderSet: {
                  __typename?: 'OrderSetViewModel';
                  problems?: Array<{
                    __typename?: 'Problem';
                    id: number;
                    name?: string | null;
                  }> | null;
                };
                repeaterOptions?: {
                  __typename?: 'RepeaterOrderableOptions';
                  buttonLabel?: string | null;
                  canRepeat?: boolean | null;
                  readOnlyOnFinish?: boolean | null;
                  showDescription?: boolean | null;
                } | null;
                qualityMeasure?: {
                  __typename?: 'QualityMeasure';
                  displayName?: string | null;
                  id: string;
                  isReadOnly: boolean;
                  measureLevel?: string | null;
                  name?: string | null;
                  userChoice: number;
                } | null;
                conditions: Array<{
                  __typename?: 'ConditionViewModel';
                  type: Types.OrderableDependentConditionType;
                  targetType: Types.ConditionTarget;
                  value?: string | null;
                  categoryId?: string | null;
                  orderableId?: string | null;
                }>;
                lines: Array<{
                  __typename?: 'ComponentsLine';
                  components: Array<
                    | {
                        __typename?: 'ActionButtonComponent';
                        id: string;
                        style?: string | null;
                        color?: string | null;
                        width: number;
                        uniqueID: string;
                        userDefinedId?: string | null;
                        componentType: string;
                        name?: string | null;
                        value?: string | null;
                        required: boolean;
                        type?: string | null;
                        actionButtonStyle?: string | null;
                        entity?: string | null;
                        providerAuthorizationRole?: string | null;
                        attributeForUpdate?: string | null;
                        attributeForSource?: string | null;
                        metComponentId?: string | null;
                        comment?: string | null;
                        isDrawableOnFinish: boolean;
                        displayOnlyWhenSelected: boolean;
                      }
                    | { __typename?: 'AutobalancerComponent' }
                    | {
                        __typename?: 'CheckboxRadioButtonComponent';
                        id: string;
                        style?: string | null;
                        color?: string | null;
                        width: number;
                        uniqueID: string;
                        userDefinedId?: string | null;
                        componentType: string;
                        name?: string | null;
                        value?: string | null;
                        required: boolean;
                        type?: string | null;
                        editable: boolean;
                        groupId?: string | null;
                        selected: boolean;
                        scoringGroup?: string | null;
                        score?: string | null;
                        groupRequired: boolean;
                        checked: boolean;
                        problem?: string | null;
                        hasValue: boolean;
                        clearAfterFinish: boolean;
                        labelSource?: string | null;
                        displayOnlyWhenSelected: boolean;
                        hidden: boolean;
                      }
                    | { __typename?: 'ClipToolComponent' }
                    | {
                        __typename?: 'ComputedValueComponent';
                        id: string;
                        style?: string | null;
                        color?: string | null;
                        width: number;
                        uniqueID: string;
                        userDefinedId?: string | null;
                        componentType: string;
                        name?: string | null;
                        value?: string | null;
                        required: boolean;
                        computedValueType?: Types.ComputedValueType | null;
                        scoringGroupId?: number | null;
                        mode?: Types.TimerValueType | null;
                        fieldId?: string | null;
                        fieldSourceId?: string | null;
                        attributeForUpdate?: string | null;
                        showTargetDate?: boolean | null;
                        daysType?: Types.TimerDaysType | null;
                        dangerValue?: number | null;
                        additionalValue?: number | null;
                        timeScale?: Types.TimerScaleType | null;
                        displayOnlyWhenSelected: boolean;
                        targetDate?: any | null;
                      }
                    | {
                        __typename?: 'DateComponent';
                        id: string;
                        style?: string | null;
                        color?: string | null;
                        width: number;
                        uniqueID: string;
                        userDefinedId?: string | null;
                        componentType: string;
                        name?: string | null;
                        value?: string | null;
                        linkMessage?: string | null;
                        mirrorGroup?: string | null;
                        oneWaySrc?: string | null;
                        required: boolean;
                        useCurrentDate: boolean;
                        pastDatesNotAllowed: boolean;
                        daysBeforeLimit?: number | null;
                        daysAfterLimit?: number | null;
                        displayOnlyWhenSelected: boolean;
                      }
                    | {
                        __typename?: 'DateTimeComponent';
                        id: string;
                        style?: string | null;
                        color?: string | null;
                        width: number;
                        uniqueID: string;
                        userDefinedId?: string | null;
                        componentType: string;
                        name?: string | null;
                        value?: string | null;
                        linkMessage?: string | null;
                        mirrorGroup?: string | null;
                        oneWaySrc?: string | null;
                        required: boolean;
                        useSeconds: boolean;
                        useCurrentDate: boolean;
                        pastDatesNotAllowed: boolean;
                        daysBeforeLimit?: number | null;
                        daysAfterLimit?: number | null;
                        displayOnlyWhenSelected: boolean;
                      }
                    | {
                        __typename?: 'DropdownComponent';
                        id: string;
                        style?: string | null;
                        color?: string | null;
                        width: number;
                        uniqueID: string;
                        userDefinedId?: string | null;
                        componentType: string;
                        name?: string | null;
                        value?: string | null;
                        dropDownType: Types.DropdownType;
                        linkMessage?: string | null;
                        mirrorGroup?: string | null;
                        oneWaySrc?: string | null;
                        required: boolean;
                        autocomplete: boolean;
                        displayOnlyWhenSelected: boolean;
                        isBigTable: boolean;
                        choices?: Array<string> | null;
                        lookupId?: string | null;
                        entityName?: string | null;
                      }
                    | {
                        __typename?: 'EntityAttributeComponent';
                        id: string;
                        style?: string | null;
                        color?: string | null;
                        width: number;
                        uniqueID: string;
                        userDefinedId?: string | null;
                        componentType: string;
                        name?: string | null;
                        value?: string | null;
                        linkMessage?: string | null;
                        mirrorGroup?: string | null;
                        oneWaySrc?: string | null;
                        required: boolean;
                        readonly: boolean;
                        displayOnlyWhenSelected: boolean;
                        entityName?: string | null;
                        fieldName?: string | null;
                        multiValueType?: string | null;
                        showDateTime: boolean;
                        selectDateTime: boolean;
                        multiline: boolean;
                        useSeconds: boolean;
                        useCurrentDate: boolean;
                        daysBeforeLimit?: number | null;
                        daysAfterLimit?: number | null;
                        maxWidth: number;
                        autocomplete: boolean;
                        isBigTable: boolean;
                        attributeType?: string | null;
                      }
                    | {
                        __typename?: 'ExpressionComponent';
                        id: string;
                        style?: string | null;
                        color?: string | null;
                        width: number;
                        componentType: string;
                        userDefinedId?: string | null;
                        uniqueID: string;
                        name?: string | null;
                        value?: string | null;
                        required: boolean;
                        attributeForUpdate?: string | null;
                        expressionString?: string | null;
                        readonlyAfterFinish?: boolean | null;
                        isReadonlyAfterFinishSet?: boolean | null;
                        isDrawableOnFinish: boolean;
                        displayOnlyWhenSelected: boolean;
                      }
                    | { __typename?: 'ExternalUrlComponent' }
                    | {
                        __typename?: 'FixedTextComponent';
                        id: string;
                        style?: string | null;
                        color?: string | null;
                        width: number;
                        uniqueID: string;
                        userDefinedId?: string | null;
                        componentType: string;
                        value?: string | null;
                        displayOnlyWhenSelected: boolean;
                        hidden: boolean;
                      }
                    | {
                        __typename?: 'ReminderComponent';
                        id: string;
                        style?: string | null;
                        color?: string | null;
                        width: number;
                        uniqueID: string;
                        userDefinedId?: string | null;
                        componentType: string;
                        name?: string | null;
                        required: boolean;
                        notes?: string | null;
                        daysForward: number;
                        dayType: Types.DayType;
                        daysForwardFrom?: string | null;
                        reasonIdSelected: number;
                        reasonDisplayValue?: string | null;
                        reasonImageValue?: string | null;
                        importanceIdSelected: Types.ReminderComponentImportance;
                        defaultUserToRemind: Types.ReminderComponentDefaultUser;
                        reminderType: Types.ReminderType;
                        teamMemberIdSelected: number;
                        teamMemberDisplayValue?: string | null;
                        reminderId: number;
                        dateDue: any;
                        comment?: string | null;
                        displayOnlyWhenSelected: boolean;
                        isReminderCreated?: boolean | null;
                        isReminderModified?: boolean | null;
                      }
                    | {
                        __typename?: 'UserFreeTextComponent';
                        id: string;
                        style?: string | null;
                        color?: string | null;
                        width: number;
                        uniqueID: string;
                        userDefinedId?: string | null;
                        componentType: string;
                        name?: string | null;
                        value?: string | null;
                        linkMessage?: string | null;
                        mirrorGroup?: string | null;
                        oneWaySrc?: string | null;
                        useAutocomplete: boolean;
                        required: boolean;
                        type?: string | null;
                        maxLen: number;
                        minLen: number;
                        maxVal: number;
                        minVal: number;
                        maxWidth: number;
                        multiline: boolean;
                        displayAsIcon: boolean;
                        displayOnlyWhenSelected: boolean;
                        readonly: boolean;
                      }
                  >;
                }>;
              }
            | {
                __typename?: 'ChecklistItemPriorAuthCodes';
                uid: string;
                orderableId: string;
                type: string;
                isVisible: boolean;
                isSelected: boolean;
                options: {
                  __typename?: 'PriorAuthCodesOrderableOptions';
                  minRequired: number;
                  maxAllowed: number;
                  type: Types.MedicationCodeType;
                  itemType: Types.MedicationCodeItemType;
                  codeLabel: string;
                  descriptionLabel: string;
                  pAAllowSelection: boolean;
                  pAShowCheckPolicy: boolean;
                  category?: {
                    __typename?: 'LookupValue';
                    id: number;
                    name?: string | null;
                  } | null;
                };
                pAValue?: {
                  __typename?: 'PriorAuthCodesActionModel';
                  id: number;
                  reqStartDate?: any | null;
                  reqEndDate?: any | null;
                  status?: Types.PriorAuthInsuranceStatus | null;
                  notificationMessage?: string | null;
                  priorAuthCodesActionValues: Array<{
                    __typename?: 'PriorAuthCodesActionValue';
                    id: number;
                    code: string;
                    byCodeFound: boolean;
                    description?: string | null;
                    notificationMessage?: string | null;
                    isPARequired: boolean;
                    externalURL?: string | null;
                    priorAuthRequirement?: Types.PaRequirement | null;
                    primaryMessage?: string | null;
                    secondaryMessage?: string | null;
                    isDeleted?: boolean | null;
                  }>;
                } | null;
              }
            | { __typename?: 'ChecklistItemReminder' }
            | {
                __typename?: 'ChecklistItemSubheader';
                uid: string;
                orderableId: string;
                type: string;
                preview?: string | null;
                isSelected: boolean;
                isVisible: boolean;
              }
          >;
        }>;
      }>;
      bigTableValues: Array<{
        __typename?: 'Entity';
        name?: string | null;
        values?: Array<
          | { __typename?: 'CareSite'; id: number; name?: string | null }
          | { __typename?: 'EpisodeType'; id: number; name?: string | null }
          | { __typename?: 'Lookup'; id: number; name?: string | null }
          | { __typename?: 'LookupValue'; id: number; name?: string | null }
          | { __typename?: 'Pharmacy'; id: number; name?: string | null }
          | { __typename?: 'Problem'; id: number; name?: string | null }
          | { __typename?: 'Provider'; id: number; name?: string | null }
          | { __typename?: 'Role'; id: number; name?: string | null }
          | { __typename?: 'Scenario'; id: number; name?: string | null }
          | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
          | { __typename?: 'Tag'; id: number; name?: string | null }
          | { __typename?: 'User'; id: number; name?: string | null }
        > | null;
      }>;
    };
    problems: Array<{
      __typename?: 'ChecklistProblemViewModel';
      id: string;
      inCarePlan: boolean;
      isActive: boolean;
      name: string;
      note?: string | null;
      selected: boolean;
      sortIndex: number;
    }>;
  } | null;
};

export type ModuleItemCarePlan_ChecklistItemConditions_Fragment = {
  __typename?: 'ChecklistItemConditions';
};

export type ModuleItemCarePlan_ChecklistItemDuplicateCheck_Fragment = {
  __typename?: 'ChecklistItemDuplicateCheck';
};

export type ModuleItemCarePlan_ChecklistItemHealthServices_Fragment = {
  __typename?: 'ChecklistItemHealthServices';
};

export type ModuleItemCarePlan_ChecklistItemLetterSummary_Fragment = {
  __typename?: 'ChecklistItemLetterSummary';
};

export type ModuleItemCarePlan_ChecklistItemMedicalCodes_Fragment = {
  __typename?: 'ChecklistItemMedicalCodes';
  uid: string;
  orderableId: string;
  type: string;
  isSelected: boolean;
  isVisible: boolean;
  options: {
    __typename?: 'MedicationCodesOrderableOptions';
    minRequired: number;
    maxAllowed: number;
    itemType: Types.MedicationCodeItemType;
    type: Types.MedicationCodeType;
    descriptionLabel: string;
    codeLabel: string;
    category?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
  };
  value?: Array<{
    __typename?: 'MedicationCodesActionValue';
    id: number;
    code: string;
    description?: string | null;
    notificationMessage?: string | null;
  } | null> | null;
};

export type ModuleItemCarePlan_ChecklistItemMedications_Fragment = {
  __typename?: 'ChecklistItemMedications';
};

export type ModuleItemCarePlan_ChecklistItemNote_Fragment = {
  __typename?: 'ChecklistItemNote';
  uid: string;
  orderableId: string;
  type: string;
  preview?: string | null;
  isSelected: boolean;
  isVisible: boolean;
};

export type ModuleItemCarePlan_ChecklistItemOrderable_Fragment = {
  __typename?: 'ChecklistItemOrderable';
  uid: string;
  orderableId: string;
  type: string;
  listStyleType: Types.ListStyleType;
  score?: number | null;
  scoringSystemGroup?: number | null;
  isSelected: boolean;
  isVisible: boolean;
  hasRepeater: boolean;
  createdOn?: any | null;
  createdBy?: string | null;
  orderSet: {
    __typename?: 'OrderSetViewModel';
    problems?: Array<{
      __typename?: 'Problem';
      id: number;
      name?: string | null;
    }> | null;
  };
  repeaterOptions?: {
    __typename?: 'RepeaterOrderableOptions';
    buttonLabel?: string | null;
    canRepeat?: boolean | null;
    readOnlyOnFinish?: boolean | null;
    showDescription?: boolean | null;
  } | null;
  qualityMeasure?: {
    __typename?: 'QualityMeasure';
    displayName?: string | null;
    id: string;
    isReadOnly: boolean;
    measureLevel?: string | null;
    name?: string | null;
    userChoice: number;
  } | null;
  conditions: Array<{
    __typename?: 'ConditionViewModel';
    type: Types.OrderableDependentConditionType;
    targetType: Types.ConditionTarget;
    value?: string | null;
    categoryId?: string | null;
    orderableId?: string | null;
  }>;
  lines: Array<{
    __typename?: 'ComponentsLine';
    components: Array<
      | {
          __typename?: 'ActionButtonComponent';
          id: string;
          style?: string | null;
          color?: string | null;
          width: number;
          uniqueID: string;
          userDefinedId?: string | null;
          componentType: string;
          name?: string | null;
          value?: string | null;
          required: boolean;
          type?: string | null;
          actionButtonStyle?: string | null;
          entity?: string | null;
          providerAuthorizationRole?: string | null;
          attributeForUpdate?: string | null;
          attributeForSource?: string | null;
          metComponentId?: string | null;
          comment?: string | null;
          isDrawableOnFinish: boolean;
          displayOnlyWhenSelected: boolean;
        }
      | { __typename?: 'AutobalancerComponent' }
      | {
          __typename?: 'CheckboxRadioButtonComponent';
          id: string;
          style?: string | null;
          color?: string | null;
          width: number;
          uniqueID: string;
          userDefinedId?: string | null;
          componentType: string;
          name?: string | null;
          value?: string | null;
          required: boolean;
          type?: string | null;
          editable: boolean;
          groupId?: string | null;
          selected: boolean;
          scoringGroup?: string | null;
          score?: string | null;
          groupRequired: boolean;
          checked: boolean;
          problem?: string | null;
          hasValue: boolean;
          clearAfterFinish: boolean;
          labelSource?: string | null;
          displayOnlyWhenSelected: boolean;
          hidden: boolean;
        }
      | { __typename?: 'ClipToolComponent' }
      | {
          __typename?: 'ComputedValueComponent';
          id: string;
          style?: string | null;
          color?: string | null;
          width: number;
          uniqueID: string;
          userDefinedId?: string | null;
          componentType: string;
          name?: string | null;
          value?: string | null;
          required: boolean;
          computedValueType?: Types.ComputedValueType | null;
          scoringGroupId?: number | null;
          mode?: Types.TimerValueType | null;
          fieldId?: string | null;
          fieldSourceId?: string | null;
          attributeForUpdate?: string | null;
          showTargetDate?: boolean | null;
          daysType?: Types.TimerDaysType | null;
          dangerValue?: number | null;
          additionalValue?: number | null;
          timeScale?: Types.TimerScaleType | null;
          displayOnlyWhenSelected: boolean;
          targetDate?: any | null;
        }
      | {
          __typename?: 'DateComponent';
          id: string;
          style?: string | null;
          color?: string | null;
          width: number;
          uniqueID: string;
          userDefinedId?: string | null;
          componentType: string;
          name?: string | null;
          value?: string | null;
          linkMessage?: string | null;
          mirrorGroup?: string | null;
          oneWaySrc?: string | null;
          required: boolean;
          useCurrentDate: boolean;
          pastDatesNotAllowed: boolean;
          daysBeforeLimit?: number | null;
          daysAfterLimit?: number | null;
          displayOnlyWhenSelected: boolean;
        }
      | {
          __typename?: 'DateTimeComponent';
          id: string;
          style?: string | null;
          color?: string | null;
          width: number;
          uniqueID: string;
          userDefinedId?: string | null;
          componentType: string;
          name?: string | null;
          value?: string | null;
          linkMessage?: string | null;
          mirrorGroup?: string | null;
          oneWaySrc?: string | null;
          required: boolean;
          useSeconds: boolean;
          useCurrentDate: boolean;
          pastDatesNotAllowed: boolean;
          daysBeforeLimit?: number | null;
          daysAfterLimit?: number | null;
          displayOnlyWhenSelected: boolean;
        }
      | {
          __typename?: 'DropdownComponent';
          id: string;
          style?: string | null;
          color?: string | null;
          width: number;
          uniqueID: string;
          userDefinedId?: string | null;
          componentType: string;
          name?: string | null;
          value?: string | null;
          dropDownType: Types.DropdownType;
          linkMessage?: string | null;
          mirrorGroup?: string | null;
          oneWaySrc?: string | null;
          required: boolean;
          autocomplete: boolean;
          displayOnlyWhenSelected: boolean;
          isBigTable: boolean;
          choices?: Array<string> | null;
          lookupId?: string | null;
          entityName?: string | null;
        }
      | {
          __typename?: 'EntityAttributeComponent';
          id: string;
          style?: string | null;
          color?: string | null;
          width: number;
          uniqueID: string;
          userDefinedId?: string | null;
          componentType: string;
          name?: string | null;
          value?: string | null;
          linkMessage?: string | null;
          mirrorGroup?: string | null;
          oneWaySrc?: string | null;
          required: boolean;
          readonly: boolean;
          displayOnlyWhenSelected: boolean;
          entityName?: string | null;
          fieldName?: string | null;
          multiValueType?: string | null;
          showDateTime: boolean;
          selectDateTime: boolean;
          multiline: boolean;
          useSeconds: boolean;
          useCurrentDate: boolean;
          daysBeforeLimit?: number | null;
          daysAfterLimit?: number | null;
          maxWidth: number;
          autocomplete: boolean;
          isBigTable: boolean;
          attributeType?: string | null;
        }
      | {
          __typename?: 'ExpressionComponent';
          id: string;
          style?: string | null;
          color?: string | null;
          width: number;
          componentType: string;
          userDefinedId?: string | null;
          uniqueID: string;
          name?: string | null;
          value?: string | null;
          required: boolean;
          attributeForUpdate?: string | null;
          expressionString?: string | null;
          readonlyAfterFinish?: boolean | null;
          isReadonlyAfterFinishSet?: boolean | null;
          isDrawableOnFinish: boolean;
          displayOnlyWhenSelected: boolean;
        }
      | { __typename?: 'ExternalUrlComponent' }
      | {
          __typename?: 'FixedTextComponent';
          id: string;
          style?: string | null;
          color?: string | null;
          width: number;
          uniqueID: string;
          userDefinedId?: string | null;
          componentType: string;
          value?: string | null;
          displayOnlyWhenSelected: boolean;
          hidden: boolean;
        }
      | {
          __typename?: 'ReminderComponent';
          id: string;
          style?: string | null;
          color?: string | null;
          width: number;
          uniqueID: string;
          userDefinedId?: string | null;
          componentType: string;
          name?: string | null;
          required: boolean;
          notes?: string | null;
          daysForward: number;
          dayType: Types.DayType;
          daysForwardFrom?: string | null;
          reasonIdSelected: number;
          reasonDisplayValue?: string | null;
          reasonImageValue?: string | null;
          importanceIdSelected: Types.ReminderComponentImportance;
          defaultUserToRemind: Types.ReminderComponentDefaultUser;
          reminderType: Types.ReminderType;
          teamMemberIdSelected: number;
          teamMemberDisplayValue?: string | null;
          reminderId: number;
          dateDue: any;
          comment?: string | null;
          displayOnlyWhenSelected: boolean;
          isReminderCreated?: boolean | null;
          isReminderModified?: boolean | null;
        }
      | {
          __typename?: 'UserFreeTextComponent';
          id: string;
          style?: string | null;
          color?: string | null;
          width: number;
          uniqueID: string;
          userDefinedId?: string | null;
          componentType: string;
          name?: string | null;
          value?: string | null;
          linkMessage?: string | null;
          mirrorGroup?: string | null;
          oneWaySrc?: string | null;
          useAutocomplete: boolean;
          required: boolean;
          type?: string | null;
          maxLen: number;
          minLen: number;
          maxVal: number;
          minVal: number;
          maxWidth: number;
          multiline: boolean;
          displayAsIcon: boolean;
          displayOnlyWhenSelected: boolean;
          readonly: boolean;
        }
    >;
  }>;
};

export type ModuleItemCarePlan_ChecklistItemPriorAuthCodes_Fragment = {
  __typename?: 'ChecklistItemPriorAuthCodes';
  uid: string;
  orderableId: string;
  type: string;
  isVisible: boolean;
  isSelected: boolean;
  options: {
    __typename?: 'PriorAuthCodesOrderableOptions';
    minRequired: number;
    maxAllowed: number;
    type: Types.MedicationCodeType;
    itemType: Types.MedicationCodeItemType;
    codeLabel: string;
    descriptionLabel: string;
    pAAllowSelection: boolean;
    pAShowCheckPolicy: boolean;
    category?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
  };
  pAValue?: {
    __typename?: 'PriorAuthCodesActionModel';
    id: number;
    reqStartDate?: any | null;
    reqEndDate?: any | null;
    status?: Types.PriorAuthInsuranceStatus | null;
    notificationMessage?: string | null;
    priorAuthCodesActionValues: Array<{
      __typename?: 'PriorAuthCodesActionValue';
      id: number;
      code: string;
      byCodeFound: boolean;
      description?: string | null;
      notificationMessage?: string | null;
      isPARequired: boolean;
      externalURL?: string | null;
      priorAuthRequirement?: Types.PaRequirement | null;
      primaryMessage?: string | null;
      secondaryMessage?: string | null;
      isDeleted?: boolean | null;
    }>;
  } | null;
};

export type ModuleItemCarePlan_ChecklistItemReminder_Fragment = {
  __typename?: 'ChecklistItemReminder';
};

export type ModuleItemCarePlan_ChecklistItemSubheader_Fragment = {
  __typename?: 'ChecklistItemSubheader';
  uid: string;
  orderableId: string;
  type: string;
  preview?: string | null;
  isSelected: boolean;
  isVisible: boolean;
};

export type ModuleItemCarePlanFragment =
  | ModuleItemCarePlan_ChecklistItemConditions_Fragment
  | ModuleItemCarePlan_ChecklistItemDuplicateCheck_Fragment
  | ModuleItemCarePlan_ChecklistItemHealthServices_Fragment
  | ModuleItemCarePlan_ChecklistItemLetterSummary_Fragment
  | ModuleItemCarePlan_ChecklistItemMedicalCodes_Fragment
  | ModuleItemCarePlan_ChecklistItemMedications_Fragment
  | ModuleItemCarePlan_ChecklistItemNote_Fragment
  | ModuleItemCarePlan_ChecklistItemOrderable_Fragment
  | ModuleItemCarePlan_ChecklistItemPriorAuthCodes_Fragment
  | ModuleItemCarePlan_ChecklistItemReminder_Fragment
  | ModuleItemCarePlan_ChecklistItemSubheader_Fragment;

export const ModuleItemCarePlanFragmentDoc = `
    fragment moduleItemCarePlan on ChecklistItem {
  ... on ChecklistItemOrderable {
    uid
    orderableId
    type
    listStyleType
    score
    scoringSystemGroup
    isSelected
    isVisible
    hasRepeater
    createdOn
    createdBy
    orderSet {
      problems {
        id
        name
      }
    }
    repeaterOptions {
      buttonLabel
      canRepeat
      readOnlyOnFinish
      showDescription
    }
    qualityMeasure {
      displayName
      id
      isReadOnly
      measureLevel
      name
      userChoice
    }
    conditions {
      type
      targetType
      value
      categoryId
      orderableId
    }
    lines {
      components {
        ... on FixedTextComponent {
          id
          style
          color
          width
          uniqueID
          userDefinedId
          componentType
          value
          displayOnlyWhenSelected
          hidden
        }
        ... on UserFreeTextComponent {
          id
          style
          color
          width
          uniqueID
          userDefinedId
          componentType
          name
          value
          linkMessage
          mirrorGroup
          oneWaySrc
          useAutocomplete
          required
          type
          maxLen
          minLen
          maxVal
          minVal
          maxWidth
          multiline
          displayAsIcon
          displayOnlyWhenSelected
          readonly
        }
        ... on DateComponent {
          id
          style
          color
          width
          uniqueID
          userDefinedId
          componentType
          name
          value
          linkMessage
          mirrorGroup
          oneWaySrc
          required
          useCurrentDate
          pastDatesNotAllowed
          daysBeforeLimit
          daysAfterLimit
          displayOnlyWhenSelected
        }
        ... on DateTimeComponent {
          id
          style
          color
          width
          uniqueID
          userDefinedId
          componentType
          name
          value
          linkMessage
          mirrorGroup
          oneWaySrc
          required
          useSeconds
          useCurrentDate
          pastDatesNotAllowed
          daysBeforeLimit
          daysAfterLimit
          displayOnlyWhenSelected
        }
        ... on DropdownComponent {
          id
          style
          color
          width
          uniqueID
          userDefinedId
          componentType
          name
          value
          dropDownType
          linkMessage
          mirrorGroup
          oneWaySrc
          required
          autocomplete
          displayOnlyWhenSelected
          isBigTable
          choices
          lookupId
          entityName
        }
        ... on EntityAttributeComponent {
          id
          style
          color
          width
          uniqueID
          userDefinedId
          componentType
          name
          value
          linkMessage
          mirrorGroup
          oneWaySrc
          required
          readonly
          displayOnlyWhenSelected
          entityName
          fieldName
          multiValueType
          showDateTime
          selectDateTime
          multiline
          useSeconds
          useCurrentDate
          daysBeforeLimit
          daysAfterLimit
          maxWidth
          autocomplete
          isBigTable
          attributeType
        }
        ... on ActionButtonComponent {
          id
          style
          color
          width
          uniqueID
          userDefinedId
          componentType
          name
          value
          required
          type
          actionButtonStyle
          entity
          providerAuthorizationRole
          attributeForUpdate
          attributeForSource
          metComponentId
          comment
          isDrawableOnFinish
          displayOnlyWhenSelected
        }
        ... on CheckboxRadioButtonComponent {
          id
          style
          color
          width
          uniqueID
          userDefinedId
          componentType
          name
          value
          required
          type
          editable
          groupId
          selected
          scoringGroup
          score
          groupRequired
          checked
          problem
          hasValue
          clearAfterFinish
          labelSource
          displayOnlyWhenSelected
          hidden
        }
        ... on ReminderComponent {
          id
          style
          color
          width
          uniqueID
          userDefinedId
          componentType
          name
          required
          notes
          daysForward
          dayType
          daysForwardFrom
          reasonIdSelected
          reasonDisplayValue
          reasonImageValue
          importanceIdSelected
          defaultUserToRemind
          reminderType
          teamMemberIdSelected
          teamMemberDisplayValue
          reminderId
          dateDue
          comment
          displayOnlyWhenSelected
          isReminderCreated
          isReminderModified
        }
        ... on ComputedValueComponent {
          id
          style
          color
          width
          uniqueID
          userDefinedId
          componentType
          name
          value
          required
          computedValueType
          scoringGroupId
          mode
          fieldId
          fieldSourceId
          attributeForUpdate
          showTargetDate
          daysType
          dangerValue
          additionalValue
          timeScale
          displayOnlyWhenSelected
          targetDate
        }
        ... on ExpressionComponent {
          id
          style
          color
          width
          componentType
          userDefinedId
          uniqueID
          name
          value
          required
          attributeForUpdate
          expressionString
          readonlyAfterFinish
          isReadonlyAfterFinishSet
          isDrawableOnFinish
          displayOnlyWhenSelected
        }
      }
    }
  }
  ... on ChecklistItemSubheader {
    uid
    orderableId
    type
    preview
    isSelected
    isVisible
  }
  ... on ChecklistItemNote {
    uid
    orderableId
    type
    preview
    isSelected
    isVisible
  }
  ... on ChecklistItemMedicalCodes {
    uid
    orderableId
    type
    isSelected
    isVisible
    options {
      minRequired
      maxAllowed
      itemType
      type
      descriptionLabel
      codeLabel
      category {
        id
        name
      }
    }
    value {
      id
      code
      description
      notificationMessage
    }
  }
  ... on ChecklistItemPriorAuthCodes {
    uid
    orderableId
    type
    isVisible
    isSelected
    options {
      minRequired
      maxAllowed
      type
      itemType
      category {
        id
        name
      }
      codeLabel
      descriptionLabel
      pAAllowSelection
      pAShowCheckPolicy
    }
    pAValue {
      id
      reqStartDate
      reqEndDate
      status
      notificationMessage
      priorAuthCodesActionValues {
        id
        code
        byCodeFound
        description
        notificationMessage
        isPARequired
        externalURL
        priorAuthRequirement
        primaryMessage
        secondaryMessage
        isDeleted
      }
    }
  }
}
    `;
export const GetCarePlanDocument = `
    query getCarePlan($request: PatientCarePlanFilterModelInput!) {
  getCarePlan(filterModel: $request) {
    checklist {
      id
      episodeId
      episodeNumber
      patientId
      author {
        fullName
      }
      type
      reasonForNote {
        name
      }
      owner {
        fullName
      }
      createdOn
      updatedOn
      updatedBy {
        fullName
      }
      finishedOn
      scenarioId
      scenarioName
      amended
      status
      canAmend
      updated
      lettersIsAvailableForUser
      categories {
        id
        name
        isVisible
        items {
          ...moduleItemCarePlan
        }
        subCategories {
          id
          name
          items {
            ...moduleItemCarePlan
          }
        }
      }
      bigTableValues {
        name
        values {
          id
          name
        }
      }
    }
    problems {
      id
      inCarePlan
      isActive
      name
      note
      selected
      sortIndex
    }
  }
}
    ${ModuleItemCarePlanFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCarePlan: build.query<GetCarePlanQuery, GetCarePlanQueryVariables>({
      query: (variables) => ({ document: GetCarePlanDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCarePlanQuery, useLazyGetCarePlanQuery } = injectedRtkApi;
