import { HealthServiceCodesActionValue } from 'graphql/graphqlTypes';
import { differenceInDays } from 'date-fns';
import { formatValidDate } from 'components/actions/sections/SectionBody/Items/HealthService/HealthService.helpers';
import moment from 'moment';
import { MOMENT_DATE_FORMAT } from 'components/constants';

export const getItemStartDate = (
  item: HealthServiceCodesActionValue,
  type: 'Requested' | 'Approved'
) =>
  type === 'Requested'
    ? item.healthServiceActionValue?.requestedStartDate
    : item.healthServiceActionValue?.approvedStartDate;

export const getItemEndDate = (
  item: HealthServiceCodesActionValue,
  type: 'Requested' | 'Approved'
) =>
  type === 'Requested'
    ? item.healthServiceActionValue?.requestedEndDate
    : item.healthServiceActionValue?.approvedEndDate;

export const getItemUnitType = (
  item: HealthServiceCodesActionValue,
  type: 'Requested' | 'Approved'
) =>
  type === 'Requested'
    ? item.healthServiceActionValue?.requestedUnitType
    : item.healthServiceActionValue?.approvedUnitType;

export const getItemUnits = (
  item: HealthServiceCodesActionValue,
  type: 'Requested' | 'Approved'
) =>
  type === 'Requested'
    ? item.healthServiceActionValue?.requestedUnits
    : item.healthServiceActionValue?.approvedUnits;

export const changeValue = (
  item: HealthServiceCodesActionValue,
  type: 'Requested' | 'Approved',
  value: number | null
): HealthServiceCodesActionValue => ({
  ...item,
  healthServiceActionValue: {
    ...item.healthServiceActionValue,
    requestedUnits:
      type === 'Requested'
        ? value
        : item.healthServiceActionValue?.requestedUnits,
    approvedUnits:
      type === 'Approved'
        ? value
        : item.healthServiceActionValue?.approvedUnits,
  },
});

export const getDifferenceInDays = (
  item: HealthServiceCodesActionValue,
  type: 'Requested' | 'Approved'
) => {
  const endDate = getItemEndDate(item, type);
  const startDate = getItemStartDate(item, type);

  const getDiff = () => {
    const days = differenceInDays(
      moment(endDate).toDate(),
      moment(startDate).toDate()
    );
    return days < 0 ? 0 : days;
  };

  return startDate && endDate ? getDiff() : null;
};

export const changeUnitType = (
  item: HealthServiceCodesActionValue,
  type: 'Requested' | 'Approved',
  name: string
): HealthServiceCodesActionValue => {
  let newState: HealthServiceCodesActionValue = {
    ...item,
    healthServiceActionValue: {
      ...item.healthServiceActionValue,
      requestedUnitType:
        type === 'Requested'
          ? name
          : item.healthServiceActionValue?.requestedUnitType,
      approvedUnitType:
        type === 'Approved'
          ? name
          : item.healthServiceActionValue?.approvedUnitType,
    },
  };

  if (name === 'Days') {
    const diffInDays = getDifferenceInDays(item, type);
    newState = changeValue(newState, type, diffInDays);
  }

  return newState;
};

export const changeEndDate = (
  item: HealthServiceCodesActionValue,
  type: 'Requested' | 'Approved',
  date: Date | null
): HealthServiceCodesActionValue => {
  let newState: HealthServiceCodesActionValue = {
    ...item,
    healthServiceActionValue: {
      ...item.healthServiceActionValue,
      requestedEndDate:
        type === 'Requested'
          ? formatValidDate(date)
          : item.healthServiceActionValue?.requestedEndDate,
      approvedEndDate:
        type === 'Approved'
          ? formatValidDate(date)
          : item.healthServiceActionValue?.approvedEndDate,
    },
  };

  newState = updateItemValue(newState, type);

  return newState;
};

const updateItemValue = (
  item: HealthServiceCodesActionValue,
  type: 'Requested' | 'Approved'
) => {
  if (getItemUnitType(item, type) === 'Days') {
    const diffInDays = getDifferenceInDays(item, type);
    return changeValue(item, type, diffInDays);
  }
  return item;
};

export const changeStartDate = (
  item: HealthServiceCodesActionValue,
  type: 'Requested' | 'Approved',
  date: Date | null
): HealthServiceCodesActionValue => {
  let newState: HealthServiceCodesActionValue = {
    ...item,
    healthServiceActionValue: {
      ...item.healthServiceActionValue,
      requestedStartDate:
        type === 'Requested'
          ? formatValidDate(date)
          : item.healthServiceActionValue?.requestedStartDate,
      approvedStartDate:
        type === 'Approved'
          ? formatValidDate(date)
          : item.healthServiceActionValue?.approvedStartDate,
    },
  };
  newState = updateItemValue(newState, type);

  return newState;
};

export const getNumberOrNull = (value: string) =>
  isNaN(+value) || value === '' ? null : Number(value);

export const formatDate = (value: Date) =>
  new Date(moment(value).format(MOMENT_DATE_FORMAT));
