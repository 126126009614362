import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { changeMfaStep } from 'store/user/middlewares';
import { IMfaStep } from 'store/user/types';
import { IState } from 'store';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { formatPhoneUS } from 'util/helpers/phoneHelpers';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const StyledWrapper = styled.div`
  min-width: 308px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: center;
`;

const StyledInputWrapper = styled.div`
  margin-top: 40px;
`;

export const PhoneVerifiedForm = () => {
  const { auth, phone, mfaType } = useSelector((state: IState) => state.user);
  const dispatch = useDispatch();
  const [values] = useState<IMfaStep>({
    login: auth.login,
    password: auth.password,
    phone: phone,
    mfaType: mfaType,
  });

  const nextHandler = () => {
    dispatch(changeMfaStep(values, 'confirm'));
  };

  return (
    <StyledWrapper>
      <CheckCircleOutlineIcon
        fontSize="large"
        style={{ color: COLORS.LIGHT_GREEN, marginBottom: '35px' }}
      />
      <form onSubmit={nextHandler}>
        <div>
          <Typography
            variant="h2"
            style={{
              color: COLORS.BLACK,
              fontWeight: 300,
              marginBottom: '35px',
            }}
          >
            Phone Verified!
          </Typography>
          <Typography variant="body1">
            <div>From now on, when you sign in you&apos;ll need to</div>
            <div style={{ marginBottom: '20px' }}>
              enter a security code from your phone.
            </div>
            <div>
              Username: <strong>{auth.login}</strong>
            </div>
            Phone number:
            <strong>{` ${formatPhoneUS(phone)}`}</strong>
          </Typography>
        </div>
        <StyledInputWrapper>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={nextHandler}
            data-cy="enter-phone"
            style={{ width: '100%' }}
          >
            Next
          </Button>
        </StyledInputWrapper>
      </form>
    </StyledWrapper>
  );
};
