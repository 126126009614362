import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Link, OutlinedInput } from '@mui/material';
import { changeMfaStep } from 'store/user/middlewares';
import { authChangeMfaStep } from 'store/user/action';
import ErrorComp from 'components/error';
import { IMfaStep } from 'store/user/types';
import { useForm } from 'react-hook-form';
import { IState } from 'store';
import Typography from '@mui/material/Typography';
import { COLORS } from 'consts/styles';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import styled from 'styled-components';
import { formatPhoneUS } from 'util/helpers/phoneHelpers';
import { useNavigate } from 'react-router';

const StyledWrapper = styled.div`
  min-width: 308px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: center;
`;

const StyledInputWrapper = styled.div`
  margin-top: 40px;
`;

const StyledOutlinedInput = styled(OutlinedInput)({
  '.MuiInputBase-input': {
    height: '40px',
  },
  color: COLORS.BLACK10,
  'input:-internal-autofill-selected': {
    backgroundColor: 'transparent!important',
  },
});

export const VerifyCodeForm = () => {
  const { auth, phone, mfaType } = useSelector((state: IState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backHandler = () => {
    if (mfaType == 'mfaRequire' || mfaType == 'passwordReset') {
      navigate(0);
    } else {
      dispatch(authChangeMfaStep(auth, phone, mfaType, 'enterPhone'));
    }
  };
  const resendHandler = () => {
    const mfaStep: IMfaStep = {
      login: auth.login,
      password: auth.password,
      phone: phone,
      mfaType: mfaType,
    };
    dispatch(changeMfaStep(mfaStep, 'phone'));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IMfaStep>();
  const onSubmit = (newValues: IMfaStep) => {
    newValues.login = auth.login;
    newValues.password = auth.password;
    newValues.phone = phone;
    newValues.mfaType = mfaType;
    dispatch(changeMfaStep(newValues, 'code'));
  };

  const errorMessage: { [key: string]: string } = {
    required: 'Please provide the 6-digit code',
    minLength: 'Your code must be be 6 digit',
  };

  const headerString = (mfaType: string) => {
    switch (mfaType) {
      case 'mfaRequire':
        return 'Enter the code';
      case 'passwordReset':
        return 'Password Reset';
      default:
        return 'Verify Phone Number';
    }
  };

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: '35px' }}>
        <div style={{ marginBottom: '35px' }}>
          <Typography
            variant="h2"
            style={{
              color: COLORS.BLACK,
              fontWeight: 300,
            }}
          >
            {headerString(mfaType)}
          </Typography>
          <Typography variant="body1">
            <div style={{ marginBottom: '20px' }}>
              Username: <strong>{auth.login}</strong>
            </div>
            <div>A text message with a verification code was</div>
            just sent to
            <strong>{` ${formatPhoneUS(phone)}`}</strong>.
          </Typography>
        </div>
        <StyledInputWrapper>
          <Typography
            style={{ color: COLORS.GREY80, paddingBottom: '9px' }}
            variant="body1"
          >
            Enter the 6-digit code
          </Typography>
          <StyledOutlinedInput
            id="code"
            type="text"
            fullWidth={true}
            {...register('code', { required: true, minLength: 6 })}
            style={{ height: '54px' }}
            inputProps={{ maxLength: 6 }}
          />
          <ErrorComp>
            {errors.code && <span>{errorMessage[errors.code.type]}</span>}
          </ErrorComp>
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={backHandler}
            style={{ width: '40%', marginRight: '20%' }}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleSubmit(onSubmit)}
            data-cy="enter-code"
            style={{ width: '40%' }}
          >
            Verify
          </Button>
        </StyledInputWrapper>
      </form>
      <div>
        <Typography variant="body1">
          Didn&apos;t get a code? {}
          <Link component="button" onClick={resendHandler}>
            Resend
          </Link>
        </Typography>
      </div>
      {(mfaType == 'mfaRequire' || mfaType == 'passwordReset') && (
        <div>
          <Typography variant="body1">
            Having Trouble? {}
            <Link
              component="button"
              onClick={() =>
                dispatch(
                  showErrorPopup({
                    title:
                      mfaType == 'mfaRequire'
                        ? 'Verification Process'
                        : 'Reset Password Process',
                    message:
                      'Please contact your administrator for further assistance',
                  })
                )
              }
            >
              Contact Support
            </Link>
          </Typography>
        </div>
      )}
    </StyledWrapper>
  );
};
