import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

const TooltipFormatter = (props: DataTypeProvider.ValueFormatterProps) => (
  <CustomTooltip title={props.value ?? ''}>
    <Typography
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      noWrap
    >
      {props.value}
    </Typography>
  </CustomTooltip>
);

const TooltipProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={TooltipFormatter} {...props} />
);

export default TooltipProvider;
