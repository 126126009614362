import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Autocomplete,
  Dialog,
  Box,
} from '@mui/material';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';
import { COLORS } from 'consts/styles';
import { validateMinAmountValues } from 'util/validationUtils';
import { usePrevious } from 'hooks';
import Icon, { ICONS } from 'components/icon';
import { debounce } from 'lodash';
import { MedicationCodeType, SearchResult } from 'graphql/graphqlTypes';
import { useLazySearchMedicationCodesQuery } from 'graphql/hooks/searchMedicationCodes';
import MedCodeOption from './MedCodeOption';
import { GridSearchLineRow } from 'components/actions/sections/SectionBody/Items/MedicationCodes/StyledComponents';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';

export interface IChecklistItemMedicationCodesProps {
  maxAllowed: number;
  minRequired: number;
  type: MedicationCodeType;
  selectedCodes: SearchResult[];
  readOnly?: boolean;
  onAddCode: (newValue: SearchResult) => void;
  style?: CSSProperties;
  showErrorBar?: boolean;
  errorMessage?: string;
}

const StyledIconButton = styled(IconButton)`
  padding: 4px;
`;
const StyledButton = styled(Button)({
  minWidth: '150px',
  height: '40px',
  marginRight: '20px',
  '&:hover': {
    minWidth: '150px',
    height: '40px',
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: '440px',
  height: '32px',
  backgroundColor: COLORS.WHITE,
  input: {
    padding: '0!important',
  },
  paper: {
    border: '1px solid #CECECE',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
  },
  '.MuiAutocomplete-inputRoot': {
    paddingLeft: '12px!important',
    paddingRight: '12px!important',
  },
  option: {
    backgroundColor: COLORS.WHITE,
    '&:hover': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
    '&:active': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
    '&[aria-selected="true"]': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
    '&[data-focus="true"]': {
      backgroundColor: COLORS.SYMPHONY_BLUE_HOVER,
    },
  },
});

const ErrorBar = styled(Typography)({
  paddingLeft: '20px',
  paddingTop: '5px',
  color: 'red',
});

const TypographyCode = styled(Typography)({
  fontSize: '16px',
  color: COLORS.WHITE,
});

const MedicationDialogContent = styled(DialogContent)({
  height: '500px',
  width: '550px',
  overflowX: 'auto',
  padding: '12px 16px 12px 32px',
});

const MedicationCodesSearch = (props: IChecklistItemMedicationCodesProps) => {
  const {
    maxAllowed,
    minRequired,
    type,
    selectedCodes,
    onAddCode,
    style,
    readOnly,
    showErrorBar,
    errorMessage,
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<SearchResult | null>(
    null
  );
  const [showSearchBar, updateShowSearchBar] = useState(false);
  const [searchTerm, changeSearchTerm] = useState<string>('');
  const [foundOptions, updateFoundOptions] = useState<SearchResult[]>(
    [] as SearchResult[]
  );
  const [autoValue, updateAutoValue] = useState<SearchResult | null>(null);
  const [error, setError] = useState<string>(
    validateMinAmountValues(selectedCodes.length, minRequired).message || ''
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let inputRef: any;

  const [
    searchMedicationCodes,
    { data: actualData, isFetching: isCodesFetching },
  ] = useLazySearchMedicationCodesQuery();

  useEffect(() => {
    if (showSearchBar) {
      inputRef?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSearchBar]);

  useEffect(
    () => {
      if (isCodesFetching) {
        updateFoundOptions([]);
      }
      if (actualData && !isCodesFetching) {
        const loadedOptions =
          actualData.searchMedicationCodes as SearchResult[];
        const filteredOptions = loadedOptions.filter(
          (x) => !selectedCodes.map((y) => y.id).includes(x.id)
        );
        updateFoundOptions(filteredOptions);
        setDropdownOpen(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCodesFetching, actualData]
  );

  const prevSearchTerm = usePrevious(searchTerm);

  useEffect(
    () => {
      if (searchTerm.length === 0 && prevSearchTerm !== searchTerm) {
        updateFoundOptions([]);
        setDropdownOpen(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm]
  );

  const handleValidation = (newCodes: SearchResult[]) => {
    const result = validateMinAmountValues(
      newCodes !== null ? newCodes.length : 0,
      minRequired
    );
    setError(result.message || '');
  };

  const handleSearchTermChange = debounce((term: string) => {
    if (term.length > 1) {
      searchMedicationCodes({
        request: {
          term,
          type,
        },
      });
    }
  }, 200);

  useEffect(() => {
    handleValidation(selectedCodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCodes]);

  const onSelectionChange = (value: SearchResult) => {
    if (value) {
      updateAutoValue(null);
      onAddCode(value);
    }
    changeSearchTerm('');
    updateFoundOptions([] as SearchResult[]);
  };

  const getOptionLabel = (option: SearchResult): string => {
    return `${option.code} ${option.description}`;
  };

  const handleClickClear = () => {
    updateFoundOptions([]);
    changeSearchTerm('');
    setDropdownOpen(false);
  };

  const itemOrItems = maxAllowed > 1 ? 'items' : 'item';
  const tooltipText = `Maximum ${maxAllowed} ${itemOrItems} can be added`;

  const handleClickReadMore = (
    event: React.MouseEvent<HTMLElement>,
    option: SearchResult
  ) => {
    event.stopPropagation();
    setDialogOpen(true);
    setSelectedOption(option);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <GridSearchLineRow item xs={12} style={style}>
      {selectedCodes.length < maxAllowed && !readOnly ? (
        <>
          <Button
            style={{
              display: showSearchBar || error ? 'none' : 'block',
            }}
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              updateShowSearchBar(true);
            }}
          >
            <Typography variant="body2">+ Add Code Item</Typography>
          </Button>
          <StyledAutocomplete
            getOptionLabel={(option) => getOptionLabel(option as SearchResult)}
            filterOptions={(x) => x}
            options={foundOptions}
            autoComplete
            open={dropdownOpen}
            forcePopupIcon={false}
            size="small"
            loading={isCodesFetching}
            includeInputInList
            clearOnBlur={false}
            clearOnEscape={true}
            autoHighlight={foundOptions.length === 1}
            style={{
              display: showSearchBar || error ? 'block' : 'none',
            }}
            filterSelectedOptions
            onBlur={() => {
              setDropdownOpen(false);
              updateShowSearchBar(false);
            }}
            onKeyDown={(e) => e.key === 'Escape' && handleClickClear()}
            onInputChange={(
              _event: ChangeEvent<unknown>,
              newTerm: string,
              reason: AutocompleteInputChangeReason
            ) => {
              changeSearchTerm(reason === 'input' ? newTerm : '');
              handleSearchTermChange(reason === 'input' ? newTerm : '');
            }}
            onChange={(_event, value) =>
              onSelectionChange(value as SearchResult)
            }
            inputValue={searchTerm}
            value={autoValue}
            renderOption={(params, option) => (
              <li {...params}>
                <MedCodeOption
                  value={option as SearchResult}
                  handleClickReadMore={(e: React.MouseEvent<HTMLElement>) =>
                    handleClickReadMore(e, option as SearchResult)
                  }
                />
              </li>
            )}
            renderInput={(params) => (
              <TextField
                type="text"
                inputRef={(input) => {
                  inputRef = input;
                }}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon
                        size={14}
                        icon={ICONS.Search}
                        style={{ marginTop: '-2px' }}
                      />
                    </InputAdornment>
                  ),
                  style: {
                    padding: '6px 0px!important',
                    paddingLeft: '6px!important',
                  },
                  endAdornment: (
                    <>
                      {searchTerm.length === 0 ? null : (
                        <InputAdornment position="end">
                          <StyledIconButton onClick={handleClickClear}>
                            <Icon size={18} icon={ICONS.Remove} />
                          </StyledIconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
                error={!!error}
                variant="outlined"
                fullWidth={true}
              />
            )}
          />
        </>
      ) : (
        <CustomTooltip title={tooltipText}>
          <div data-testid="medCodes-search-readOnly">
            <Button variant="text" color="primary" size="small" disabled>
              <Typography variant="body2" style={{ color: COLORS.GREY60 }}>
                + Add Code Item
              </Typography>
            </Button>
          </div>
        </CustomTooltip>
      )}
      {showErrorBar ? <ErrorBar>{errorMessage}</ErrorBar> : null}

      <Dialog
        data-testid="provider-search-dialog"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
        maxWidth="lg"
        style={{ height: '550px' }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <TypographyCode>{`Code : ${selectedOption?.code}`}</TypographyCode>
        </DialogTitle>
        <MedicationDialogContent dividers>
          <Box display="flex" flexDirection="column">
            <h4>Short Description</h4>
            <span>{selectedOption?.shortDescription}</span>
          </Box>
          <Box display="flex" flexDirection="column">
            <h4>Long Description</h4>
            <span>{selectedOption?.description}</span>
          </Box>
        </MedicationDialogContent>
        <DialogActions>
          <div>
            <StyledButton
              data-testid="provider-search-dialog-cancel-button"
              onClick={handleClose}
              color="primary"
              variant="outlined"
              data-cy="cancel"
            >
              Cancel
            </StyledButton>
            <StyledButton
              onClick={() => {
                onSelectionChange(selectedOption as SearchResult);
                setDialogOpen(false);
              }}
              color="primary"
              variant="contained"
              data-cy="select"
            >
              Add
            </StyledButton>
          </div>
        </DialogActions>
      </Dialog>
    </GridSearchLineRow>
  );
};

export default MedicationCodesSearch;
