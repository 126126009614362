import { Box, TextField, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { MOMENT_DATE_FORMAT } from 'components/constants';
import moment from 'moment';
import {
  HealthServiceCodesActionModel,
  ILookupValue,
  LookupValue,
} from 'graphql/graphqlTypes';
import {
  DeterminationDecision,
  HealthServiceAuthorizationType,
  UnitTypes,
} from '../types';
import { getNumberOrNull } from './ServiceRow.helpers';
import { healthServiceDisplayMode } from '../../../HealthServiceDisplayMode';
import { isUndefined } from 'lodash';
import styled from 'styled-components';

export interface ISummaryState {
  unitTypes: ILookupValue[];
  hsModel: HealthServiceCodesActionModel;
  episodeDecision: DeterminationDecision;
  onChange?: (state: HealthServiceCodesActionModel) => void;
  hsAuthorizationType: number;
  hSDisplayMode: number;
  defaultUnitType: ILookupValue | undefined;
}

export const HealthServiceSummary = (props: ISummaryState) => {
  const {
    unitTypes,
    hsModel,
    episodeDecision,
    onChange,
    hsAuthorizationType,
    hSDisplayMode,
    defaultUnitType,
  } = props;

  const unitType = unitTypes.find(
    (x) => x.id === hsModel?.overallApprUnitType?.id
  );

  const StyledTypography = styled(Typography)({
    marginRight: '4px',
  });

  const StyledTypographyDenial = styled(StyledTypography)({
    marginLeft: '30px',
  });

  const handleTotalApprUnitsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newModel: HealthServiceCodesActionModel = {
      ...hsModel,
    };
    newModel.totalApprUnits = getNumberOrNull(e.target.value);
    if (!isUndefined(onChange)) {
      onChange(newModel);
    }
  };

  const handleTotalDeniedUnitsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newModel: HealthServiceCodesActionModel = {
      ...hsModel,
    };
    newModel.totalDeniedUnits = getNumberOrNull(e.target.value);
    if (!isUndefined(onChange)) {
      onChange(newModel);
    }
  };

  const handleTotalReqUnitsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newModel: HealthServiceCodesActionModel = {
      ...hsModel,
      overallApprUnitType:
        hsAuthorizationType === HealthServiceAuthorizationType.Episode &&
        !isUndefined(defaultUnitType)
          ? (defaultUnitType as LookupValue)
          : hsModel.overallApprUnitType,
      overallReqUnitType:
        hsAuthorizationType === HealthServiceAuthorizationType.Episode &&
        !isUndefined(defaultUnitType)
          ? (defaultUnitType as LookupValue)
          : hsModel.overallReqUnitType,
    };
    newModel.totalReqUnits = getNumberOrNull(e.target.value);
    if (!isUndefined(onChange)) {
      onChange(newModel);
    }
  };

  return (
    <Box display="flex" flexDirection="column" pt="16px" rowGap="20px">
      <Typography variant="body2">Summary</Typography>
      <Box display="flex" columnGap="30px">
        <Typography variant="body2">Approved</Typography>

        <Box display="flex">
          <Typography variant="body1">Start Date:&nbsp;</Typography>
          <Typography variant="body2">
            {!!hsModel?.overallApprStartDate &&
              moment(hsModel.overallApprStartDate).format(MOMENT_DATE_FORMAT)}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography variant="body1">End Date:&nbsp;</Typography>
          <Typography variant="body2">
            {!!hsModel?.overallApprEndDate &&
              moment(hsModel.overallApprEndDate).format(MOMENT_DATE_FORMAT)}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography variant="body1">Unit Type:&nbsp;</Typography>
          <Typography variant="body2">{unitType?.name}</Typography>
        </Box>
      </Box>
      <Box display="flex" columnGap="30px" alignItems="center">
        <Box display="flex" alignItems="center">
          <StyledTypography>Requested:</StyledTypography>
          {hsAuthorizationType === HealthServiceAuthorizationType.Episode &&
          hSDisplayMode === healthServiceDisplayMode.requestingCodes &&
          hsModel.overallReqUnitType?.name !== UnitTypes.days ? (
            <TextField
              type="tel"
              size="medium"
              variant="outlined"
              value={hsModel?.totalReqUnits ?? 0}
              onChange={handleTotalReqUnitsChange}
              style={{ width: '68px' }}
              data-testid="hs-requnits"
            />
          ) : (
            <Typography variant="body2">{hsModel?.totalReqUnits}</Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <StyledTypography>Approved:</StyledTypography>
          {episodeDecision === 'Partial' &&
          hSDisplayMode === healthServiceDisplayMode.manualReview ? (
            <>
              <TextField
                type="tel"
                size="medium"
                variant="outlined"
                value={hsModel?.totalApprUnits ?? 0}
                onChange={handleTotalApprUnitsChange}
                style={{ width: '68px' }}
                data-testid="hs-apprunits"
              />
              <StyledTypographyDenial>Denial:</StyledTypographyDenial>
              <TextField
                type="tel"
                size="medium"
                variant="outlined"
                value={hsModel?.totalDeniedUnits ?? 0}
                onChange={handleTotalDeniedUnitsChange}
                style={{ width: '68px' }}
                data-testid="hs-deniedunits"
              />
            </>
          ) : (
            <Typography variant="body2">{hsModel?.totalApprUnits}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
