import React, { useEffect, useRef } from 'react';
import { v4 as uuid4 } from 'uuid';
import {
  Box,
  IconButton,
  Snackbar as MuiSnackbar,
  SnackbarContent,
  Typography,
} from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

const CloseButton = styled(IconButton)({
  padding: 0,
});

const TextArea = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 8px',
});

const ContentBox = styled(Box)({
  display: 'flex',
});

const StyledSnackbar = styled(MuiSnackbar)<{
  $isSnackbarInsideContainer: boolean;
}>`
  .MuiPaper-root {
    background-color: ${COLORS.WHITE};
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    opacity: 1;
    color: ${COLORS.BLACK};
    display: flex;
    align-items: flex-start;
  }
  .MuiSnackbarContent-action {
    margin-top: 6px;
  }

  ${({ $isSnackbarInsideContainer }) =>
    $isSnackbarInsideContainer &&
    `
      bottom: -30px;
      left: -30px;
      position: absolute;
    `}
`;

export interface ISnackbarProps {
  open: boolean;
  duration: 'infinite' | number;
  icon?: ICONS;
  type?: 'error' | 'warning' | 'success' | 'info';
  title: string;
  text: string;
  origin?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' };
  onClose: () => void;
  isSnackbarInsideContainer?: boolean;
}

const Content = ({ icon, title, text, type }: ISnackbarProps) => (
  <>
    <ContentBox>
      {icon && (
        <Box>
          <Icon
            icon={icon}
            size={14}
            color={
              {
                error: COLORS.RED100,
                warning: COLORS.YELLOW100,
                success: COLORS.GREEN100,
                info: COLORS.SYMPHONY_BLUE,
              }[type ?? 'info']
            }
          />
        </Box>
      )}
      <TextArea>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          {title}
        </Typography>
        <Typography variant="body1">
          {renderTextWithLineBreaks(text)}
        </Typography>
      </TextArea>
    </ContentBox>
  </>
);
// Function to convert newline characters to <br> tags
const renderTextWithLineBreaks = (text: string) => {
  return text.split('\n').map((line) => (
    <React.Fragment key={uuid4()}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const CloseModalComponent = ({ onClose }: ISnackbarProps) => (
  <Box>
    <CloseButton onClick={onClose}>
      <Icon icon={ICONS.Close} size={14} />
    </CloseButton>
  </Box>
);

const Snackbar = (props: ISnackbarProps) => {
  const { onClose, open, duration, origin, isSnackbarInsideContainer } = props;
  const timeout = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (duration === 'infinite') {
      return;
    }

    timeout.current = setTimeout(() => onClose(), duration);
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, open, props.text, props.title, props.icon]);

  if (!open) {
    return null;
  }

  return (
    <StyledSnackbar
      anchorOrigin={origin ?? { vertical: 'bottom', horizontal: 'left' }}
      open={open}
      data-testid="snackbar-component"
      $isSnackbarInsideContainer={Boolean(isSnackbarInsideContainer)}
    >
      <SnackbarContent
        message={<Content {...props} />}
        action={<CloseModalComponent {...props} />}
      />
    </StyledSnackbar>
  );
};

export default Snackbar;
