import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChecklistStorageType,
  IAllDocumentsState,
  IChecklistError,
  IChecklistState,
  IDiagnosisCodes,
  IRemoveStoplights,
  ISetStoplightValidationBodyAction,
  IVisitedSections,
  ServiceLocationType,
} from './types';
import {
  findCategoryItem,
  findHsConditionItemCategory,
  findHsConditionItemIndex,
  getHsConditionItemDecision,
  initChecklistComponents,
  mapItem,
  mapLinks,
  updateStoplightsVisibility,
} from './actionsSlice.helpers';
import { recalculateExpressions } from './expressions';
import { calculateActions } from './conditions';
import { ICheckBoxRadioSelection } from 'components/actions/sections/SectionBody/Items/Orderable';
import { CHECKBOX_RADIOBUTTON_TYPE } from 'components/actions/sections/SectionBody/Components/RadioCheckBoxButton/types';
import {
  ActionButtonComponent,
  AttributeType,
  BaseComponent,
  CheckboxRadioButtonComponent,
  ChecklistItem,
  ChecklistItemMedicalCodes,
  ChecklistLookupViewModel,
  ChecklistProblemViewModel,
  ChecklistViewModel,
  Entity,
  MedicationCodesActionValue,
  ReminderComponent,
  ChecklistItemHealthServices,
  HealthServiceCodesActionModel,
  ConditionViewModel,
} from 'graphql/graphqlTypes';
import moment from 'moment';
import {
  updateLinkedComponents,
  updateLinkedPendingReminders,
  updateLinkedReminders,
} from 'store/actions/linkedComponents';
import { DeterminationDecision } from 'components/actions/sections/SectionBody/Items/HealthService/types';

export const initialActionState: IChecklistState = {
  checklist: null,
  problems: null,
  isSaving: false,
  patientId: null,
  episodeId: null,
  showErrorBar: false,
  showMozartActionLockErrorBar: false,
  providers: {},
  checklistComponents: {},
  checklistErrors: {},
  scoringValues: {},
  linkedComponents: {},
  stoplightsRemaining: [],
  selectedActions: {},
  activeSectionIndex: 1,
  visitedSections: [],
  remindersInitialized: false,
  conditionalItems: [],
  isAmendingChecklist: false,
  serviceLocation: null,
  diagnosisCodes: [],
  actionsBeingSetVisible: null,
};

export const allDocumentsInitialState: IAllDocumentsState = {
  documentsState: {
    [ChecklistStorageType.CHECKLIST]: initialActionState,
    [ChecklistStorageType.MOZART]: initialActionState,
    [ChecklistStorageType.CAREPLAN]: initialActionState,
    [ChecklistStorageType.QUICKNOTE]: initialActionState,
    [ChecklistStorageType.VIEW]: initialActionState,
  },
};

const checklistSlice = createSlice({
  name: 'checklistState',
  initialState: allDocumentsInitialState,
  reducers: {
    addServiceLocation(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        serviceLocation: ServiceLocationType;
      }>
    ) {
      state.documentsState[action.payload.storageType].serviceLocation =
        action.payload.serviceLocation;
    },
    resetServiceLocationAndDiagnosisCodes(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
      }>
    ) {
      state.documentsState[action.payload.storageType].serviceLocation = null;
      state.documentsState[action.payload.storageType].diagnosisCodes = [];
    },
    setChecklistComponentValue(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        uniqueID: string;
        value: string;
        field?: BaseComponent;
        careSiteNow: moment.Moment;
      }>
    ) {
      const component =
        state.documentsState[action.payload.storageType].checklistComponents[
          action.payload.uniqueID
        ];

      if (!component) {
        return;
      }
      component.component.value = action.payload.value;

      calculateActions(
        state,
        action.payload.storageType,
        action.payload.careSiteNow
      );

      const modifiedComponents = recalculateExpressions(
        state,
        action.payload.storageType
      );
      updateLinkedReminders(
        state,
        action.payload.storageType,
        modifiedComponents
      );

      if (action.payload.field) {
        updateLinkedComponents(
          state,
          action.payload.storageType,
          action.payload.field,
          action.payload.value
        );
      }
      updateStoplightsVisibility(state, action.payload.storageType);
    },
    setChecklistCheckboxRadioButtonComponentValue(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        uniqueID: string;
        value: string;
        selectedValue: ICheckBoxRadioSelection[];
        field: BaseComponent;
        careSiteNow: moment.Moment;
      }>
    ) {
      const checklistComponents =
        state.documentsState[action.payload.storageType].checklistComponents;
      const uniqueID = action.payload.uniqueID;
      const value = action.payload.value;

      const component = checklistComponents[uniqueID];

      if (!component) {
        return;
      }
      const checkboxRadioButtonComponent =
        component.component as CheckboxRadioButtonComponent;
      if (
        checkboxRadioButtonComponent.type ===
        CHECKBOX_RADIOBUTTON_TYPE.RADIOBUTTON
      ) {
        [{ uniqueID, value }]
          .concat(
            action.payload.selectedValue
              .filter((cbr) => cbr.uniqueID !== uniqueID)
              .map((cbr) => {
                return {
                  uniqueID: cbr.uniqueID,
                  value: 'false',
                };
              })
          )
          .forEach((c) => {
            const checklistComponent = checklistComponents[c.uniqueID];
            if (checklistComponent) {
              (
                checklistComponent.component as CheckboxRadioButtonComponent
              ).selected = c.value === 'true';
            }
          });
      } else {
        checkboxRadioButtonComponent.selected = value === 'true';
      }

      calculateActions(
        state,
        action.payload.storageType,
        action.payload.careSiteNow
      );

      const modifiedComponents = recalculateExpressions(
        state,
        action.payload.storageType
      );
      updateLinkedReminders(
        state,
        action.payload.storageType,
        modifiedComponents
      );

      updateLinkedComponents(
        state,
        action.payload.storageType,
        action.payload.field,
        action.payload.value
      );
      updateStoplightsVisibility(state, action.payload.storageType);
    },
    setChecklistReminderValue(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        uniqueID: string;
        value: string;
        componentId: string;
      }>
    ) {
      const component =
        state.documentsState[action.payload.storageType].checklistComponents[
          action.payload.uniqueID
        ];

      if (!component) {
        return;
      }
      if (action.payload.componentId.endsWith('-DateDue')) {
        (component.component as ReminderComponent).dateDue = action.payload
          .value
          ? moment(action.payload.value).toDate()
          : undefined;
      } else if (action.payload.componentId.endsWith('-TeamMemberIdSelected')) {
        (component.component as ReminderComponent).teamMemberIdSelected =
          Number(action.payload.value);
      } else if (action.payload.componentId.endsWith('-Comment')) {
        (component.component as ReminderComponent).comment =
          action.payload.value;
      }
    },
    setComponentValueByUserDefinedId(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        userDefinedId: string;
        value: string;
      }>
    ) {
      const { storageType, userDefinedId, value } = action.payload;
      const components = state.documentsState[storageType].checklistComponents;
      Object.values(components).forEach((component) => {
        if (component.component.userDefinedId === userDefinedId) {
          component.component.value = value;
        }
      });
    },
    setComponentValuesByUserDefinedIds(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        values: { userDefinedId: string; value: string | null }[];
        actionId: string | undefined | null;
      }>
    ) {
      const { storageType, values, actionId } = action.payload;
      const components = state.documentsState[storageType].checklistComponents;

      Object.values(components).forEach((component) => {
        if (
          (actionId && component.uuid !== actionId) ||
          !component.component.userDefinedId
        ) {
          return;
        }

        const update = values.find(
          (val) => val.userDefinedId === component.component.userDefinedId
        );
        if (update) {
          component.component.value = update.value;
        }
      });
    },
    setErrorBarVisible(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        visible: boolean;
      }>
    ) {
      state.documentsState[action.payload.storageType].showErrorBar =
        action.payload.visible;
    },
    setGlobalParams(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        patientId: number;
        episodeId: number;
      }>
    ) {
      const actionState = state.documentsState[action.payload.storageType];
      actionState.patientId = action.payload.patientId;
      actionState.episodeId = action.payload.episodeId;
    },
    resetChecklist(state, action: PayloadAction<ChecklistStorageType>) {
      state.documentsState[action.payload] = initialActionState;
    },
    setChecklistComponentValidation(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        error: IChecklistError;
      }>
    ) {
      const { error, storageType } = action.payload;
      const checklistError =
        state.documentsState[storageType].checklistErrors[error.uuid];
      const isNewError = !checklistError && error.error;
      const isDifferentError =
        error.isValid !== checklistError?.isValid ||
        error.error !== checklistError?.error;
      updateStoplightsVisibility(state, action.payload.storageType);

      if (isNewError || isDifferentError) {
        state.documentsState[storageType].checklistErrors[error.uuid] = {
          ...error,
        };
      }
    },
    addChecklistScoringValue(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        scoringGroupId: string;
        score: number;
        initial?: boolean;
      }>
    ) {
      const { storageType, scoringGroupId, score, initial } = action.payload;

      const currentGroup =
        state.documentsState[storageType].scoringValues[
          action.payload.scoringGroupId
        ];
      if (initial) {
        state.documentsState[storageType].scoringValues[scoringGroupId] = {
          scoringTotal: score,
        };
      }
      if (!currentGroup) {
        state.documentsState[storageType].scoringValues[scoringGroupId] = {
          scoringTotal: score,
        };
        return;
      }
      state.documentsState[storageType].scoringValues[scoringGroupId] = {
        scoringTotal: currentGroup.scoringTotal + score,
      };

      const modifiedComponents = recalculateExpressions(
        state,
        action.payload.storageType
      );
      updateLinkedReminders(
        state,
        action.payload.storageType,
        modifiedComponents
      );
    },
    setSelectedProvider(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        role: string;
        provider: number;
        providerAddressId?: number | null;
        isSetByUserAction: boolean;
      }>
    ) {
      state.documentsState[action.payload.storageType].providers[
        action.payload.role
      ] = {
        id: action.payload.provider,
        providerAddressId: action.payload.providerAddressId,
        isSetByUserAction: action.payload.isSetByUserAction,
      };
    },
    updateSameAsSourceProvider(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        attributeForUpdate: string;
        provider: number;
        uniqueID: string | undefined;
      }>
    ) {
      // SYM-18294: This is a bugfix for a scenario where a document was
      // created in legacy UI (providers still use it at the moment of fix)
      // and then it is updated (not amended) in the new UI
      // What happens is that "Same as ..." is implemented differently in
      // legacy and new UI.
      // New UI incorporates this button in normal provider search component
      // while legacy UI has a dedicated component.
      // The problem happens because this legacy "Same as ..." component
      // is connected to "Episode.XXXProvider" thus if it has value (coming
      // from legacy UI) then when finishing checklist we will first update
      // provider from the search and _then_ overwrite it with value from
      // "Same as ..." component.
      const checklistComponents =
        state.documentsState[action.payload.storageType].checklistComponents;

      const links = Object.values(checklistComponents).filter((s) => {
        return (
          s.component.uniqueID !== action.payload.uniqueID &&
          s.component.componentType === 'ActionButtonComponent' &&
          (s.component as ActionButtonComponent).attributeForUpdate ===
            action.payload.attributeForUpdate
        );
      });

      for (const item of links) {
        // No need to update this component if it is empty
        if (item.component.value) {
          item.component.value = action.payload.provider.toString();
        }
      }
    },
    setChecklistItemSaving(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        isSaving: boolean;
      }>
    ) {
      state.documentsState[action.payload.storageType].isSaving =
        action.payload.isSaving;
    },
    addChecklistItem(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        item: ChecklistItem;
        sectionIndex: number;
        itemIndex: number;
        bigTableValues: Entity[];
        lookups: ChecklistLookupViewModel[];
        entities: Entity[];
        attributeTypes: AttributeType[];
      }>
    ) {
      const {
        storageType,
        item,
        sectionIndex,
        itemIndex,
        bigTableValues,
        lookups,
        entities,
        attributeTypes,
      } = action.payload;
      const documentState = state.documentsState[storageType];
      const { checklist, checklistComponents, linkedComponents } =
        documentState;
      if (!checklist || !checklistComponents || !linkedComponents) {
        return;
      }
      checklist.categories[sectionIndex].items.splice(itemIndex, 0, item);

      mapItem(
        item,
        sectionIndex,
        itemIndex,
        checklistComponents,
        linkedComponents
      );
      checklist.bigTableValues = [
        ...new Set([...(checklist.bigTableValues ?? []), ...bigTableValues]),
      ];
      checklist.lookups = [
        ...new Set([...(checklist.lookups ?? []), ...lookups]),
      ];
      checklist.entities = [
        ...new Set([...(checklist.entities ?? []), ...entities]),
      ];
      checklist.attributeTypes = [
        ...new Set([...(checklist.attributeTypes ?? []), ...attributeTypes]),
      ];
      documentState.selectedActions[item.uid] = item.isSelected;
    },
    removeChecklistItem(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        itemId: string;
      }>
    ) {
      const { storageType, itemId } = action.payload;

      const { checklist, checklistComponents, linkedComponents } =
        state.documentsState[storageType];
      if (!checklist || !checklistComponents || !linkedComponents) {
        return;
      }

      const category = checklist.categories.find((c) =>
        c.items.some((item) => item.uid === itemId)
      );

      if (!category) {
        return;
      }

      // Remove from hierarchy
      category.items = category.items.filter((item) => item.uid !== itemId);

      // Remove values (flattened components)
      const componentsToRemove = Object.values(checklistComponents).filter(
        (comp) => comp.uuid === itemId
      );

      componentsToRemove.forEach((comp) => {
        delete checklistComponents[comp.component.uniqueID];
      });

      // Remove linked components
      const linksToDelete: { [mirrorGroup: string]: string[] } = {};
      componentsToRemove.forEach((comp) =>
        mapLinks(comp.component, linksToDelete)
      );

      Object.keys(linksToDelete).forEach((key) => {
        linkedComponents[key] = (linkedComponents[key] || []).filter((x) =>
          linksToDelete[key].includes(x)
        );
      });
    },
    updateCheckListItemIsVisible(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        itemId: string;
        decision: DeterminationDecision;
        condition: ConditionViewModel;
      }>
    ) {
      const { storageType, itemId, decision, condition } = action.payload;
      const { checklist, checklistComponents, linkedComponents } =
        state.documentsState[storageType];
      if (!checklist || !checklistComponents || !linkedComponents) {
        return;
      }
      const category = findHsConditionItemCategory(itemId, checklist);

      if (!category) {
        return;
      }
      const ind = findHsConditionItemIndex(itemId, category);
      const item = category.items[ind];
      const newItem: ChecklistItem = {
        ...item,
      } as ChecklistItem;
      newItem.isVisible = getHsConditionItemDecision(
        decision,
        condition.value as string
      );
      category.items[ind] = newItem;
    },
    updateCarePlanModelSuccess(
      state,
      action: PayloadAction<{
        checklist: ChecklistViewModel;
        problems: ChecklistProblemViewModel[];
      }>
    ) {
      if (action.payload.checklist) {
        initChecklistComponents(
          state.documentsState[ChecklistStorageType.CAREPLAN],
          action.payload.checklist
        );
        state.documentsState[ChecklistStorageType.CAREPLAN].problems =
          action.payload.problems;
      } else {
        state.documentsState[ChecklistStorageType.CAREPLAN] = {
          ...initialActionState,
          problems: action.payload.problems,
        };
      }
    },
    getActionsModelSuccess(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        checklist: ChecklistViewModel;
      }>
    ) {
      const documentState = state.documentsState[action.payload.storageType];
      initChecklistComponents(documentState, action.payload.checklist);
      if (action.payload.checklist.includeCarePlan) {
        if (action.payload.checklist.carePlan) {
          initChecklistComponents(
            state.documentsState[ChecklistStorageType.CAREPLAN],
            action.payload.checklist.carePlan
          );
          state.documentsState[ChecklistStorageType.CAREPLAN].problems = action
            .payload.checklist.carePlanProblems as ChecklistProblemViewModel[];
        } else {
          state.documentsState[ChecklistStorageType.CAREPLAN] = {
            ...initialActionState,
            problems: action.payload.checklist
              .carePlanProblems as ChecklistProblemViewModel[],
          };
        }
      }
      updateStoplightsVisibility(state, action.payload.storageType);
    },
    addStoplights(
      state,
      action: PayloadAction<ISetStoplightValidationBodyAction>
    ) {
      state.documentsState[action.payload.storageType].stoplightsRemaining.push(
        ...action.payload.stoplights
      );
    },
    removeStoplights(state, action: PayloadAction<IRemoveStoplights>) {
      state.documentsState[action.payload.storageType].stoplightsRemaining =
        state.documentsState[
          action.payload.storageType
        ].stoplightsRemaining.filter(
          (x) => !action.payload.ids.includes(x.uuid)
        );
    },
    updateStoplightsState(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        actions: { actionId: string; selected: boolean }[];
      }>
    ) {
      if (!state.documentsState[action.payload.storageType]) {
        return;
      }
      const stoplights =
        state.documentsState[action.payload.storageType].stoplightsRemaining;

      action.payload.actions.forEach(({ actionId, selected }) => {
        const updatedItem = stoplights
          .flatMap((x) => x.itemsWithStoplights)
          .find((x) => x.id === actionId);

        if (updatedItem) {
          updatedItem.isCompleted = selected;
        }
      });
    },
    updateSelectedActions(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        actions: { actionId: string; selected: boolean }[];
        isDirectSelect?: boolean;
        careSiteNow: moment.Moment;
      }>
    ) {
      if (action.payload.isDirectSelect) {
        state.documentsState[
          action.payload.storageType
        ].actionsBeingSetVisible = action.payload.actions;
      }

      let changed = false;

      action.payload.actions.forEach(({ actionId, selected }) => {
        if (
          state.documentsState[action.payload.storageType].selectedActions[
            actionId
          ] !== selected
        ) {
          changed = true;
          state.documentsState[action.payload.storageType].selectedActions[
            actionId
          ] = selected;
        }
      });
      if (changed) {
        calculateActions(
          state,
          action.payload.storageType,
          action.payload.careSiteNow
        );

        const modifiedComponents = recalculateExpressions(
          state,
          action.payload.storageType
        );
        updateLinkedReminders(
          state,
          action.payload.storageType,
          modifiedComponents
        );

        updateStoplightsVisibility(state, action.payload.storageType);
      }
    },
    addMedicationCode(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        itemUid: string;
        code: MedicationCodesActionValue;
      }>
    ) {
      const { storageType, code, itemUid } = action.payload;
      const checklist = state.documentsState[storageType].checklist;
      if (!checklist) {
        return;
      }

      const medCodes = findCategoryItem<ChecklistItemMedicalCodes>(
        checklist,
        (item) => item.type === 'medical codes' && item.uid === itemUid
      );
      if (!medCodes) {
        return;
      }

      medCodes.value = medCodes.value ? [...medCodes.value, code] : [code];
      const medCodeState =
        state.documentsState[action.payload.storageType].diagnosisCodes;
      state.documentsState[action.payload.storageType].diagnosisCodes =
        medCodeState.length
          ? [
              ...medCodeState,
              { code: code.code, description: code.description },
            ]
          : [{ code: code.code, description: code.description }];
    },
    setMedicationCodes(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        codes: IDiagnosisCodes[];
      }>
    ) {
      const { codes } = action.payload;

      state.documentsState[action.payload.storageType].diagnosisCodes = codes;
    },
    updateHealthServiceValues(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        itemUid: string;
        code: HealthServiceCodesActionModel;
      }>
    ) {
      const { storageType, code, itemUid } = action.payload;
      const checklist = state.documentsState[storageType].checklist;
      if (!checklist) {
        return;
      }

      const hsValues = findCategoryItem<ChecklistItemHealthServices>(
        checklist,
        (item) => item.type === 'healthservices' && item.uid === itemUid
      );
      if (!hsValues) {
        return;
      }
      hsValues.hSValue = code;
    },
    removeMedicationCode(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        itemUid: string;
        id: number;
        deletedCode: string;
      }>
    ) {
      const { storageType, id, itemUid, deletedCode } = action.payload;
      const checklist = state.documentsState[storageType].checklist;
      if (!checklist) {
        return;
      }

      const medCodes = findCategoryItem<ChecklistItemMedicalCodes>(
        checklist,
        (item) => item.type === 'medical codes' && item.uid === itemUid
      );
      if (!medCodes?.value) {
        return;
      }

      medCodes.value = medCodes.value.filter((x) => x?.id !== id);
      const stateMedCodes =
        state.documentsState[action.payload.storageType].diagnosisCodes;
      const newStateMedCodes = stateMedCodes.filter(
        (code) => code.code !== deletedCode
      );

      state.documentsState[action.payload.storageType].diagnosisCodes =
        newStateMedCodes;
    },
    updateExpressions(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
      }>
    ) {
      const modifiedComponents = recalculateExpressions(
        state,
        action.payload.storageType
      );
      updateLinkedReminders(
        state,
        action.payload.storageType,
        modifiedComponents
      );
      updateLinkedPendingReminders(state, action.payload.storageType);
    },
    setActiveSectionIndex(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        activeSection: IVisitedSections;
      }>
    ) {
      state.documentsState[action.payload.storageType].activeSectionIndex =
        action.payload.activeSection.id;
      const currentVisitedSections =
        state.documentsState[action.payload.storageType].visitedSections;
      const newSection = action.payload.activeSection;
      state.documentsState[action.payload.storageType].visitedSections =
        !currentVisitedSections.some(
          (section: IVisitedSections) =>
            section.categoryId === newSection.categoryId
        )
          ? [...currentVisitedSections, newSection]
          : currentVisitedSections;
    },
    setIsAmendingChecklist(
      state,
      action: PayloadAction<{
        storageType: ChecklistStorageType;
        isAmendingChecklist: boolean;
      }>
    ) {
      state.documentsState[action.payload.storageType].isAmendingChecklist =
        action.payload.isAmendingChecklist;
    },
  },
});
export const {
  setMedicationCodes,
  addServiceLocation,
  resetServiceLocationAndDiagnosisCodes,
  addChecklistItem,
  removeChecklistItem,
  setChecklistComponentValue,
  setChecklistReminderValue,
  setChecklistCheckboxRadioButtonComponentValue,
  setErrorBarVisible,
  setGlobalParams,
  resetChecklist,
  setChecklistComponentValidation,
  addChecklistScoringValue,
  setSelectedProvider,
  updateSameAsSourceProvider,
  setChecklistItemSaving,
  updateCarePlanModelSuccess,
  getActionsModelSuccess,
  addStoplights,
  removeStoplights,
  updateStoplightsState,
  updateSelectedActions,
  updateExpressions,
  setComponentValueByUserDefinedId,
  setComponentValuesByUserDefinedIds,
  addMedicationCode,
  removeMedicationCode,
  setActiveSectionIndex,
  updateHealthServiceValues,
  setIsAmendingChecklist,
  updateCheckListItemIsVisible,
} = checklistSlice.actions;

export default checklistSlice.reducer;
