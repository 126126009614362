import * as Types from '../graphqlTypes.js';

import {
  ModuleItemFragmentDoc,
  EntityAttributeFragmentDoc,
} from './getChecklist';
import { api } from 'graphql/baseApi';
export type AddChecklistActionMutationVariables = Types.Exact<{
  checklistId: Types.Scalars['Int'];
  actionId: Types.Scalars['String'];
}>;

export type AddChecklistActionMutation = {
  __typename?: 'Mutation';
  addChecklistAction?: {
    __typename?: 'ChecklistViewModel';
    categories: Array<{
      __typename?: 'ChecklistCategory';
      id: string;
      name: string;
      isVisible: boolean;
      items: Array<
        | { __typename?: 'ChecklistItemConditions' }
        | {
            __typename?: 'ChecklistItemDuplicateCheck';
            uid: string;
            orderableId: string;
            type: string;
            isVisible: boolean;
            isSelected: boolean;
            options: {
              __typename?: 'DuplicateCheckOrderableOptions';
              duplicateFound: string;
              duplicateFoundConfirmation: string;
            };
            dcValue?: {
              __typename?: 'DuplicateCheckActionValue';
              duplicateFound?: boolean | null;
              duplicateFoundConfirmation?: string | null;
            } | null;
          }
        | {
            __typename?: 'ChecklistItemHealthServices';
            uid: string;
            orderableId: string;
            type: string;
            isVisible: boolean;
            isSelected: boolean;
            options: {
              __typename?: 'HealthServiceCodesOrderableOptions';
              minRequired: number;
              maxAllowed: number;
              type: Types.MedicationCodeType;
              itemType: Types.MedicationCodeItemType;
              codeLabel: string;
              descriptionLabel: string;
              hSDisplayMode: number;
              hSAuthorizationType: number;
              hSDisplayEpisodeLevelRequestedParam?: boolean | null;
              hSAllowAddingCodes?: boolean | null;
              hSAllowDeletingCode?: boolean | null;
              hSUnitTypeEditable: boolean;
              hSDefaultUnit?: string | null;
              hSAllowApproved: boolean;
              hSAllowPartial: boolean;
              hSAllowPending: boolean;
              hSAllowDenied: boolean;
              hSOverallApprovedEndDate?: string | null;
              hSOverallDeterminationDecision?: string | null;
              hSOverallDeterminationReason?: string | null;
              hSOverallApprovedStartDate?: string | null;
              hSOverallRequestedEndDate?: string | null;
              hSOverallRequestedStartDate?: string | null;
              hSTotalApprovedUnit: string;
              hSTotalApprovedUnitType: string;
              hSApprovedReasonLookup?: string | null;
              hSDeniedReasonLookup?: string | null;
              hSPartialReasonLookup?: string | null;
              hSPendingReasonLookup?: string | null;
              hSTotalRequestedUnit: string;
              hSTotalRequestedUnitType: string;
              category?: {
                __typename?: 'LookupValue';
                id: number;
                name?: string | null;
              } | null;
            };
            conditions: Array<{
              __typename?: 'ConditionViewModel';
              type: Types.OrderableDependentConditionType;
              targetType: Types.ConditionTarget;
              value?: string | null;
              categoryId?: string | null;
              orderableId?: string | null;
            }>;
            hSValue?: {
              __typename?: 'HealthServiceCodesActionModel';
              totalReqUnits?: number | null;
              totalApprUnits?: number | null;
              totalDeniedUnits?: number | null;
              overallReqStartDate?: any | null;
              overallReqEndDate?: any | null;
              overallApprStartDate?: any | null;
              overallApprEndDate?: any | null;
              isReqOverriden?: boolean | null;
              isApprOverriden?: boolean | null;
              overallReqUnitType?: {
                __typename?: 'LookupValue';
                id: number;
                name?: string | null;
              } | null;
              overallApprUnitType?: {
                __typename?: 'LookupValue';
                id: number;
                name?: string | null;
              } | null;
              healthServiceCodesActionValues: Array<{
                __typename?: 'HealthServiceCodesActionValue';
                id: number;
                code: string;
                description?: string | null;
                notificationMessage?: string | null;
                healthServiceActionValue?: {
                  __typename?: 'HealthServiceActionValue';
                  approvedStartDate?: any | null;
                  approvedEndDate?: any | null;
                  approvedUnits?: number | null;
                  approvedUnitType?: string | null;
                  requestedStartDate?: any | null;
                  requestedEndDate?: any | null;
                  requestedUnits?: number | null;
                  requestedUnitType?: string | null;
                  determinationDecision?: string | null;
                  determinationReason?: {
                    __typename?: 'LookupValue';
                    id: number;
                    name?: string | null;
                  } | null;
                  modifier?: {
                    __typename?: 'LookupValue';
                    id: number;
                    name?: string | null;
                  } | null;
                } | null;
              } | null>;
            } | null;
          }
        | {
            __typename?: 'ChecklistItemLetterSummary';
            uid: string;
            orderableId: string;
            type: string;
            isVisible: boolean;
            isSelected: boolean;
            options: {
              __typename?: 'LetterSummaryOrderableOptions';
              codeLabel: string;
            };
            lsValue?: Array<{
              __typename?: 'LetterSummaryActionValue';
              id: number;
              name?: string | null;
              createdOn?: any | null;
              description?: string | null;
              isDeleted?: boolean | null;
              isVisible?: boolean | null;
              fileExtension?: string | null;
              createdByUser?: {
                __typename?: 'LetterSummaryActionValueCreatedByUser';
                fullName?: string | null;
              } | null;
            } | null> | null;
          }
        | {
            __typename?: 'ChecklistItemMedicalCodes';
            uid: string;
            orderableId: string;
            type: string;
            isSelected: boolean;
            isVisible: boolean;
            options: {
              __typename?: 'MedicationCodesOrderableOptions';
              minRequired: number;
              maxAllowed: number;
              itemType: Types.MedicationCodeItemType;
              type: Types.MedicationCodeType;
              descriptionLabel: string;
              codeLabel: string;
              allowedCodeCategory: Types.MedicationCodeCategoryType;
              disAllowedCodeMessage?: string | null;
              category?: {
                __typename?: 'LookupValue';
                id: number;
                name?: string | null;
              } | null;
            };
            value?: Array<{
              __typename?: 'MedicationCodesActionValue';
              id: number;
              code: string;
              description?: string | null;
              isPrimary: boolean;
              notificationMessage?: string | null;
              healthServiceActionValue?: {
                __typename?: 'HealthServiceActionValue';
                approvedStartDate?: any | null;
                approvedEndDate?: any | null;
                approvedUnits?: number | null;
                approvedUnitType?: string | null;
                requestedStartDate?: any | null;
                requestedEndDate?: any | null;
                requestedUnits?: number | null;
                requestedUnitType?: string | null;
                determinationDecision?: string | null;
                determinationReason?: {
                  __typename?: 'LookupValue';
                  id: number;
                  name?: string | null;
                } | null;
                modifier?: {
                  __typename?: 'LookupValue';
                  id: number;
                  name?: string | null;
                } | null;
              } | null;
            } | null> | null;
          }
        | { __typename?: 'ChecklistItemMedications' }
        | {
            __typename?: 'ChecklistItemNote';
            uid: string;
            orderableId: string;
            type: string;
            preview?: string | null;
            isSelected: boolean;
            isVisible: boolean;
          }
        | {
            __typename?: 'ChecklistItemOrderable';
            uid: string;
            orderableId: string;
            type: string;
            listStyleType: Types.ListStyleType;
            score?: number | null;
            scoringSystemGroup?: number | null;
            isSelected: boolean;
            isVisible: boolean;
            isReadOnly: boolean;
            hasRepeater: boolean;
            createdOn?: any | null;
            createdBy?: string | null;
            repeaterOptions?: {
              __typename?: 'RepeaterOrderableOptions';
              buttonLabel?: string | null;
              canRepeat?: boolean | null;
              readOnlyOnFinish?: boolean | null;
              showDescription?: boolean | null;
            } | null;
            qualityMeasure?: {
              __typename?: 'QualityMeasure';
              displayName?: string | null;
              id: string;
              isReadOnly: boolean;
              measureLevel?: string | null;
              name?: string | null;
              userChoice: number;
            } | null;
            conditions: Array<{
              __typename?: 'ConditionViewModel';
              type: Types.OrderableDependentConditionType;
              targetType: Types.ConditionTarget;
              value?: string | null;
              categoryId?: string | null;
              orderableId?: string | null;
            }>;
            lines: Array<{
              __typename?: 'ComponentsLine';
              components: Array<
                | {
                    __typename?: 'ActionButtonComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    uniqueID: string;
                    userDefinedId?: string | null;
                    componentType: string;
                    name?: string | null;
                    value?: string | null;
                    required: boolean;
                    type?: string | null;
                    actionButtonStyle?: string | null;
                    entity?: string | null;
                    providerAuthorizationRole?: string | null;
                    attributeForUpdate?: string | null;
                    attributeForSource?: string | null;
                    metComponentId?: string | null;
                    comment?: string | null;
                    isDrawableOnFinish: boolean;
                    displayOnlyWhenSelected: boolean;
                  }
                | { __typename?: 'AutobalancerComponent' }
                | {
                    __typename?: 'CheckboxRadioButtonComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    uniqueID: string;
                    userDefinedId?: string | null;
                    componentType: string;
                    name?: string | null;
                    value?: string | null;
                    required: boolean;
                    type?: string | null;
                    editable: boolean;
                    groupId?: string | null;
                    selected: boolean;
                    scoringGroup?: string | null;
                    score?: string | null;
                    groupRequired: boolean;
                    checked: boolean;
                    problem?: string | null;
                    hasValue: boolean;
                    clearAfterFinish: boolean;
                    labelSource?: string | null;
                    displayOnlyWhenSelected: boolean;
                    hidden: boolean;
                  }
                | { __typename?: 'ClipToolComponent' }
                | {
                    __typename?: 'ComputedValueComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    uniqueID: string;
                    userDefinedId?: string | null;
                    componentType: string;
                    name?: string | null;
                    value?: string | null;
                    required: boolean;
                    computedValueType?: Types.ComputedValueType | null;
                    scoringGroupId?: number | null;
                    mode?: Types.TimerValueType | null;
                    fieldId?: string | null;
                    fieldSourceId?: string | null;
                    attributeForUpdate?: string | null;
                    showTargetDate?: boolean | null;
                    daysType?: Types.TimerDaysType | null;
                    dangerValue?: number | null;
                    additionalValue?: number | null;
                    timeScale?: Types.TimerScaleType | null;
                    displayOnlyWhenSelected: boolean;
                    targetDate?: any | null;
                  }
                | {
                    __typename?: 'DateComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    uniqueID: string;
                    userDefinedId?: string | null;
                    componentType: string;
                    name?: string | null;
                    value?: string | null;
                    linkMessage?: string | null;
                    mirrorGroup?: string | null;
                    oneWaySrc?: string | null;
                    required: boolean;
                    useCurrentDate: boolean;
                    pastDatesNotAllowed: boolean;
                    daysBeforeLimit?: number | null;
                    daysAfterLimit?: number | null;
                    displayOnlyWhenSelected: boolean;
                  }
                | {
                    __typename?: 'DateTimeComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    uniqueID: string;
                    userDefinedId?: string | null;
                    componentType: string;
                    name?: string | null;
                    value?: string | null;
                    linkMessage?: string | null;
                    mirrorGroup?: string | null;
                    oneWaySrc?: string | null;
                    required: boolean;
                    useSeconds: boolean;
                    useCurrentDate: boolean;
                    pastDatesNotAllowed: boolean;
                    daysBeforeLimit?: number | null;
                    daysAfterLimit?: number | null;
                    displayOnlyWhenSelected: boolean;
                  }
                | {
                    __typename?: 'DropdownComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    uniqueID: string;
                    userDefinedId?: string | null;
                    componentType: string;
                    name?: string | null;
                    value?: string | null;
                    dropDownType: Types.DropdownType;
                    linkMessage?: string | null;
                    mirrorGroup?: string | null;
                    oneWaySrc?: string | null;
                    required: boolean;
                    autocomplete: boolean;
                    displayOnlyWhenSelected: boolean;
                    isBigTable: boolean;
                    choices?: Array<string> | null;
                    lookupId?: string | null;
                    entityName?: string | null;
                  }
                | {
                    __typename?: 'EntityAttributeComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    uniqueID: string;
                    userDefinedId?: string | null;
                    componentType: string;
                    name?: string | null;
                    value?: string | null;
                    linkMessage?: string | null;
                    mirrorGroup?: string | null;
                    oneWaySrc?: string | null;
                    required: boolean;
                    readonly: boolean;
                    displayOnlyWhenSelected: boolean;
                    entityName?: string | null;
                    fieldName?: string | null;
                    multiValueType?: string | null;
                    showDateTime: boolean;
                    selectDateTime: boolean;
                    multiline: boolean;
                    useSeconds: boolean;
                    useCurrentDate: boolean;
                    daysBeforeLimit?: number | null;
                    daysAfterLimit?: number | null;
                    maxWidth: number;
                    autocomplete: boolean;
                    isBigTable: boolean;
                    attributeType?: string | null;
                  }
                | {
                    __typename?: 'ExpressionComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    componentType: string;
                    userDefinedId?: string | null;
                    uniqueID: string;
                    name?: string | null;
                    value?: string | null;
                    required: boolean;
                    attributeForUpdate?: string | null;
                    expressionString?: string | null;
                    readonlyAfterFinish?: boolean | null;
                    isReadonlyAfterFinishSet?: boolean | null;
                    isDrawableOnFinish: boolean;
                    displayOnlyWhenSelected: boolean;
                  }
                | {
                    __typename?: 'ExternalUrlComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    componentType: string;
                    userDefinedId?: string | null;
                    uniqueID: string;
                    name?: string | null;
                    value?: string | null;
                    required: boolean;
                    isDrawableOnFinish: boolean;
                    displayOnlyWhenSelected: boolean;
                  }
                | {
                    __typename?: 'FixedTextComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    uniqueID: string;
                    userDefinedId?: string | null;
                    componentType: string;
                    value?: string | null;
                    displayOnlyWhenSelected: boolean;
                    hidden: boolean;
                  }
                | {
                    __typename?: 'ReminderComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    uniqueID: string;
                    userDefinedId?: string | null;
                    componentType: string;
                    name?: string | null;
                    required: boolean;
                    notes?: string | null;
                    daysForward: number;
                    dayType: Types.DayType;
                    daysForwardFrom?: string | null;
                    reasonIdSelected: number;
                    reasonDisplayValue?: string | null;
                    reasonImageValue?: string | null;
                    importanceIdSelected: Types.ReminderComponentImportance;
                    defaultUserToRemind: Types.ReminderComponentDefaultUser;
                    reminderType: Types.ReminderType;
                    teamMemberIdSelected: number;
                    teamMemberDisplayValue?: string | null;
                    reminderId: number;
                    dateDue: any;
                    comment?: string | null;
                    displayOnlyWhenSelected: boolean;
                    isReminderCreated?: boolean | null;
                    isReminderModified?: boolean | null;
                    isDateDuePending?: boolean | null;
                  }
                | {
                    __typename?: 'UserFreeTextComponent';
                    id: string;
                    style?: string | null;
                    color?: string | null;
                    width: number;
                    uniqueID: string;
                    userDefinedId?: string | null;
                    componentType: string;
                    name?: string | null;
                    value?: string | null;
                    linkMessage?: string | null;
                    mirrorGroup?: string | null;
                    oneWaySrc?: string | null;
                    useAutocomplete: boolean;
                    required: boolean;
                    type?: string | null;
                    maxLen: number;
                    minLen: number;
                    maxVal: number;
                    minVal: number;
                    maxWidth: number;
                    multiline: boolean;
                    displayAsIcon: boolean;
                    displayOnlyWhenSelected: boolean;
                    readonly: boolean;
                  }
              >;
            }>;
          }
        | {
            __typename?: 'ChecklistItemPriorAuthCodes';
            uid: string;
            orderableId: string;
            type: string;
            isVisible: boolean;
            isSelected: boolean;
            options: {
              __typename?: 'PriorAuthCodesOrderableOptions';
              minRequired: number;
              maxAllowed: number;
              type: Types.MedicationCodeType;
              itemType: Types.MedicationCodeItemType;
              codeLabel: string;
              descriptionLabel: string;
              pAAllowSelection: boolean;
              pAShowCheckPolicy: boolean;
              allowedCodeCategory: Types.MedicationCodeCategoryType;
              disAllowedCodeMessage?: string | null;
              category?: {
                __typename?: 'LookupValue';
                id: number;
                name?: string | null;
              } | null;
            };
            pAValue?: {
              __typename?: 'PriorAuthCodesActionModel';
              id: number;
              reqStartDate?: any | null;
              reqEndDate?: any | null;
              status?: Types.PriorAuthInsuranceStatus | null;
              notificationMessage?: string | null;
              priorAuthCodesActionValues: Array<{
                __typename?: 'PriorAuthCodesActionValue';
                id: number;
                code: string;
                byCodeFound: boolean;
                description?: string | null;
                isPARequired: boolean;
                externalURL?: string | null;
                priorAuthRequirement?: Types.PaRequirement | null;
                primaryMessage?: string | null;
                secondaryMessage?: string | null;
                isDeleted?: boolean | null;
                notificationMessage?: string | null;
              }>;
            } | null;
          }
        | { __typename?: 'ChecklistItemReminder' }
        | {
            __typename?: 'ChecklistItemSubheader';
            uid: string;
            orderableId: string;
            type: string;
            preview?: string | null;
            isSelected: boolean;
            isVisible: boolean;
          }
      >;
      subCategories: Array<{
        __typename?: 'ChecklistCategory';
        id: string;
        name: string;
        items: Array<
          | { __typename?: 'ChecklistItemConditions' }
          | {
              __typename?: 'ChecklistItemDuplicateCheck';
              uid: string;
              orderableId: string;
              type: string;
              isVisible: boolean;
              isSelected: boolean;
              options: {
                __typename?: 'DuplicateCheckOrderableOptions';
                duplicateFound: string;
                duplicateFoundConfirmation: string;
              };
              dcValue?: {
                __typename?: 'DuplicateCheckActionValue';
                duplicateFound?: boolean | null;
                duplicateFoundConfirmation?: string | null;
              } | null;
            }
          | {
              __typename?: 'ChecklistItemHealthServices';
              uid: string;
              orderableId: string;
              type: string;
              isVisible: boolean;
              isSelected: boolean;
              options: {
                __typename?: 'HealthServiceCodesOrderableOptions';
                minRequired: number;
                maxAllowed: number;
                type: Types.MedicationCodeType;
                itemType: Types.MedicationCodeItemType;
                codeLabel: string;
                descriptionLabel: string;
                hSDisplayMode: number;
                hSAuthorizationType: number;
                hSDisplayEpisodeLevelRequestedParam?: boolean | null;
                hSAllowAddingCodes?: boolean | null;
                hSAllowDeletingCode?: boolean | null;
                hSUnitTypeEditable: boolean;
                hSDefaultUnit?: string | null;
                hSAllowApproved: boolean;
                hSAllowPartial: boolean;
                hSAllowPending: boolean;
                hSAllowDenied: boolean;
                hSOverallApprovedEndDate?: string | null;
                hSOverallDeterminationDecision?: string | null;
                hSOverallDeterminationReason?: string | null;
                hSOverallApprovedStartDate?: string | null;
                hSOverallRequestedEndDate?: string | null;
                hSOverallRequestedStartDate?: string | null;
                hSTotalApprovedUnit: string;
                hSTotalApprovedUnitType: string;
                hSApprovedReasonLookup?: string | null;
                hSDeniedReasonLookup?: string | null;
                hSPartialReasonLookup?: string | null;
                hSPendingReasonLookup?: string | null;
                hSTotalRequestedUnit: string;
                hSTotalRequestedUnitType: string;
                category?: {
                  __typename?: 'LookupValue';
                  id: number;
                  name?: string | null;
                } | null;
              };
              conditions: Array<{
                __typename?: 'ConditionViewModel';
                type: Types.OrderableDependentConditionType;
                targetType: Types.ConditionTarget;
                value?: string | null;
                categoryId?: string | null;
                orderableId?: string | null;
              }>;
              hSValue?: {
                __typename?: 'HealthServiceCodesActionModel';
                totalReqUnits?: number | null;
                totalApprUnits?: number | null;
                totalDeniedUnits?: number | null;
                overallReqStartDate?: any | null;
                overallReqEndDate?: any | null;
                overallApprStartDate?: any | null;
                overallApprEndDate?: any | null;
                isReqOverriden?: boolean | null;
                isApprOverriden?: boolean | null;
                overallReqUnitType?: {
                  __typename?: 'LookupValue';
                  id: number;
                  name?: string | null;
                } | null;
                overallApprUnitType?: {
                  __typename?: 'LookupValue';
                  id: number;
                  name?: string | null;
                } | null;
                healthServiceCodesActionValues: Array<{
                  __typename?: 'HealthServiceCodesActionValue';
                  id: number;
                  code: string;
                  description?: string | null;
                  notificationMessage?: string | null;
                  healthServiceActionValue?: {
                    __typename?: 'HealthServiceActionValue';
                    approvedStartDate?: any | null;
                    approvedEndDate?: any | null;
                    approvedUnits?: number | null;
                    approvedUnitType?: string | null;
                    requestedStartDate?: any | null;
                    requestedEndDate?: any | null;
                    requestedUnits?: number | null;
                    requestedUnitType?: string | null;
                    determinationDecision?: string | null;
                    determinationReason?: {
                      __typename?: 'LookupValue';
                      id: number;
                      name?: string | null;
                    } | null;
                    modifier?: {
                      __typename?: 'LookupValue';
                      id: number;
                      name?: string | null;
                    } | null;
                  } | null;
                } | null>;
              } | null;
            }
          | {
              __typename?: 'ChecklistItemLetterSummary';
              uid: string;
              orderableId: string;
              type: string;
              isVisible: boolean;
              isSelected: boolean;
              options: {
                __typename?: 'LetterSummaryOrderableOptions';
                codeLabel: string;
              };
              lsValue?: Array<{
                __typename?: 'LetterSummaryActionValue';
                id: number;
                name?: string | null;
                createdOn?: any | null;
                description?: string | null;
                isDeleted?: boolean | null;
                isVisible?: boolean | null;
                fileExtension?: string | null;
                createdByUser?: {
                  __typename?: 'LetterSummaryActionValueCreatedByUser';
                  fullName?: string | null;
                } | null;
              } | null> | null;
            }
          | {
              __typename?: 'ChecklistItemMedicalCodes';
              uid: string;
              orderableId: string;
              type: string;
              isSelected: boolean;
              isVisible: boolean;
              options: {
                __typename?: 'MedicationCodesOrderableOptions';
                minRequired: number;
                maxAllowed: number;
                itemType: Types.MedicationCodeItemType;
                type: Types.MedicationCodeType;
                descriptionLabel: string;
                codeLabel: string;
                allowedCodeCategory: Types.MedicationCodeCategoryType;
                disAllowedCodeMessage?: string | null;
                category?: {
                  __typename?: 'LookupValue';
                  id: number;
                  name?: string | null;
                } | null;
              };
              value?: Array<{
                __typename?: 'MedicationCodesActionValue';
                id: number;
                code: string;
                description?: string | null;
                isPrimary: boolean;
                notificationMessage?: string | null;
                healthServiceActionValue?: {
                  __typename?: 'HealthServiceActionValue';
                  approvedStartDate?: any | null;
                  approvedEndDate?: any | null;
                  approvedUnits?: number | null;
                  approvedUnitType?: string | null;
                  requestedStartDate?: any | null;
                  requestedEndDate?: any | null;
                  requestedUnits?: number | null;
                  requestedUnitType?: string | null;
                  determinationDecision?: string | null;
                  determinationReason?: {
                    __typename?: 'LookupValue';
                    id: number;
                    name?: string | null;
                  } | null;
                  modifier?: {
                    __typename?: 'LookupValue';
                    id: number;
                    name?: string | null;
                  } | null;
                } | null;
              } | null> | null;
            }
          | { __typename?: 'ChecklistItemMedications' }
          | {
              __typename?: 'ChecklistItemNote';
              uid: string;
              orderableId: string;
              type: string;
              preview?: string | null;
              isSelected: boolean;
              isVisible: boolean;
            }
          | {
              __typename?: 'ChecklistItemOrderable';
              uid: string;
              orderableId: string;
              type: string;
              listStyleType: Types.ListStyleType;
              score?: number | null;
              scoringSystemGroup?: number | null;
              isSelected: boolean;
              isVisible: boolean;
              isReadOnly: boolean;
              hasRepeater: boolean;
              createdOn?: any | null;
              createdBy?: string | null;
              repeaterOptions?: {
                __typename?: 'RepeaterOrderableOptions';
                buttonLabel?: string | null;
                canRepeat?: boolean | null;
                readOnlyOnFinish?: boolean | null;
                showDescription?: boolean | null;
              } | null;
              qualityMeasure?: {
                __typename?: 'QualityMeasure';
                displayName?: string | null;
                id: string;
                isReadOnly: boolean;
                measureLevel?: string | null;
                name?: string | null;
                userChoice: number;
              } | null;
              conditions: Array<{
                __typename?: 'ConditionViewModel';
                type: Types.OrderableDependentConditionType;
                targetType: Types.ConditionTarget;
                value?: string | null;
                categoryId?: string | null;
                orderableId?: string | null;
              }>;
              lines: Array<{
                __typename?: 'ComponentsLine';
                components: Array<
                  | {
                      __typename?: 'ActionButtonComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      required: boolean;
                      type?: string | null;
                      actionButtonStyle?: string | null;
                      entity?: string | null;
                      providerAuthorizationRole?: string | null;
                      attributeForUpdate?: string | null;
                      attributeForSource?: string | null;
                      metComponentId?: string | null;
                      comment?: string | null;
                      isDrawableOnFinish: boolean;
                      displayOnlyWhenSelected: boolean;
                    }
                  | { __typename?: 'AutobalancerComponent' }
                  | {
                      __typename?: 'CheckboxRadioButtonComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      required: boolean;
                      type?: string | null;
                      editable: boolean;
                      groupId?: string | null;
                      selected: boolean;
                      scoringGroup?: string | null;
                      score?: string | null;
                      groupRequired: boolean;
                      checked: boolean;
                      problem?: string | null;
                      hasValue: boolean;
                      clearAfterFinish: boolean;
                      labelSource?: string | null;
                      displayOnlyWhenSelected: boolean;
                      hidden: boolean;
                    }
                  | { __typename?: 'ClipToolComponent' }
                  | {
                      __typename?: 'ComputedValueComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      required: boolean;
                      computedValueType?: Types.ComputedValueType | null;
                      scoringGroupId?: number | null;
                      mode?: Types.TimerValueType | null;
                      fieldId?: string | null;
                      fieldSourceId?: string | null;
                      attributeForUpdate?: string | null;
                      showTargetDate?: boolean | null;
                      daysType?: Types.TimerDaysType | null;
                      dangerValue?: number | null;
                      additionalValue?: number | null;
                      timeScale?: Types.TimerScaleType | null;
                      displayOnlyWhenSelected: boolean;
                      targetDate?: any | null;
                    }
                  | {
                      __typename?: 'DateComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      linkMessage?: string | null;
                      mirrorGroup?: string | null;
                      oneWaySrc?: string | null;
                      required: boolean;
                      useCurrentDate: boolean;
                      pastDatesNotAllowed: boolean;
                      daysBeforeLimit?: number | null;
                      daysAfterLimit?: number | null;
                      displayOnlyWhenSelected: boolean;
                    }
                  | {
                      __typename?: 'DateTimeComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      linkMessage?: string | null;
                      mirrorGroup?: string | null;
                      oneWaySrc?: string | null;
                      required: boolean;
                      useSeconds: boolean;
                      useCurrentDate: boolean;
                      pastDatesNotAllowed: boolean;
                      daysBeforeLimit?: number | null;
                      daysAfterLimit?: number | null;
                      displayOnlyWhenSelected: boolean;
                    }
                  | {
                      __typename?: 'DropdownComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      dropDownType: Types.DropdownType;
                      linkMessage?: string | null;
                      mirrorGroup?: string | null;
                      oneWaySrc?: string | null;
                      required: boolean;
                      autocomplete: boolean;
                      displayOnlyWhenSelected: boolean;
                      isBigTable: boolean;
                      choices?: Array<string> | null;
                      lookupId?: string | null;
                      entityName?: string | null;
                    }
                  | {
                      __typename?: 'EntityAttributeComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      linkMessage?: string | null;
                      mirrorGroup?: string | null;
                      oneWaySrc?: string | null;
                      required: boolean;
                      readonly: boolean;
                      displayOnlyWhenSelected: boolean;
                      entityName?: string | null;
                      fieldName?: string | null;
                      multiValueType?: string | null;
                      showDateTime: boolean;
                      selectDateTime: boolean;
                      multiline: boolean;
                      useSeconds: boolean;
                      useCurrentDate: boolean;
                      daysBeforeLimit?: number | null;
                      daysAfterLimit?: number | null;
                      maxWidth: number;
                      autocomplete: boolean;
                      isBigTable: boolean;
                      attributeType?: string | null;
                    }
                  | {
                      __typename?: 'ExpressionComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      componentType: string;
                      userDefinedId?: string | null;
                      uniqueID: string;
                      name?: string | null;
                      value?: string | null;
                      required: boolean;
                      attributeForUpdate?: string | null;
                      expressionString?: string | null;
                      readonlyAfterFinish?: boolean | null;
                      isReadonlyAfterFinishSet?: boolean | null;
                      isDrawableOnFinish: boolean;
                      displayOnlyWhenSelected: boolean;
                    }
                  | {
                      __typename?: 'ExternalUrlComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      componentType: string;
                      userDefinedId?: string | null;
                      uniqueID: string;
                      name?: string | null;
                      value?: string | null;
                      required: boolean;
                      isDrawableOnFinish: boolean;
                      displayOnlyWhenSelected: boolean;
                    }
                  | {
                      __typename?: 'FixedTextComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      value?: string | null;
                      displayOnlyWhenSelected: boolean;
                      hidden: boolean;
                    }
                  | {
                      __typename?: 'ReminderComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      required: boolean;
                      notes?: string | null;
                      daysForward: number;
                      dayType: Types.DayType;
                      daysForwardFrom?: string | null;
                      reasonIdSelected: number;
                      reasonDisplayValue?: string | null;
                      reasonImageValue?: string | null;
                      importanceIdSelected: Types.ReminderComponentImportance;
                      defaultUserToRemind: Types.ReminderComponentDefaultUser;
                      reminderType: Types.ReminderType;
                      teamMemberIdSelected: number;
                      teamMemberDisplayValue?: string | null;
                      reminderId: number;
                      dateDue: any;
                      comment?: string | null;
                      displayOnlyWhenSelected: boolean;
                      isReminderCreated?: boolean | null;
                      isReminderModified?: boolean | null;
                      isDateDuePending?: boolean | null;
                    }
                  | {
                      __typename?: 'UserFreeTextComponent';
                      id: string;
                      style?: string | null;
                      color?: string | null;
                      width: number;
                      uniqueID: string;
                      userDefinedId?: string | null;
                      componentType: string;
                      name?: string | null;
                      value?: string | null;
                      linkMessage?: string | null;
                      mirrorGroup?: string | null;
                      oneWaySrc?: string | null;
                      useAutocomplete: boolean;
                      required: boolean;
                      type?: string | null;
                      maxLen: number;
                      minLen: number;
                      maxVal: number;
                      minVal: number;
                      maxWidth: number;
                      multiline: boolean;
                      displayAsIcon: boolean;
                      displayOnlyWhenSelected: boolean;
                      readonly: boolean;
                    }
                >;
              }>;
            }
          | {
              __typename?: 'ChecklistItemPriorAuthCodes';
              uid: string;
              orderableId: string;
              type: string;
              isVisible: boolean;
              isSelected: boolean;
              options: {
                __typename?: 'PriorAuthCodesOrderableOptions';
                minRequired: number;
                maxAllowed: number;
                type: Types.MedicationCodeType;
                itemType: Types.MedicationCodeItemType;
                codeLabel: string;
                descriptionLabel: string;
                pAAllowSelection: boolean;
                pAShowCheckPolicy: boolean;
                allowedCodeCategory: Types.MedicationCodeCategoryType;
                disAllowedCodeMessage?: string | null;
                category?: {
                  __typename?: 'LookupValue';
                  id: number;
                  name?: string | null;
                } | null;
              };
              pAValue?: {
                __typename?: 'PriorAuthCodesActionModel';
                id: number;
                reqStartDate?: any | null;
                reqEndDate?: any | null;
                status?: Types.PriorAuthInsuranceStatus | null;
                notificationMessage?: string | null;
                priorAuthCodesActionValues: Array<{
                  __typename?: 'PriorAuthCodesActionValue';
                  id: number;
                  code: string;
                  byCodeFound: boolean;
                  description?: string | null;
                  isPARequired: boolean;
                  externalURL?: string | null;
                  priorAuthRequirement?: Types.PaRequirement | null;
                  primaryMessage?: string | null;
                  secondaryMessage?: string | null;
                  isDeleted?: boolean | null;
                  notificationMessage?: string | null;
                }>;
              } | null;
            }
          | { __typename?: 'ChecklistItemReminder' }
          | {
              __typename?: 'ChecklistItemSubheader';
              uid: string;
              orderableId: string;
              type: string;
              preview?: string | null;
              isSelected: boolean;
              isVisible: boolean;
            }
        >;
      }>;
    }>;
    bigTableValues: Array<{
      __typename?: 'Entity';
      name?: string | null;
      values?: Array<
        | { __typename?: 'CareSite'; id: number; name?: string | null }
        | { __typename?: 'EpisodeType'; id: number; name?: string | null }
        | { __typename?: 'Lookup'; id: number; name?: string | null }
        | { __typename?: 'LookupValue'; id: number; name?: string | null }
        | { __typename?: 'Pharmacy'; id: number; name?: string | null }
        | { __typename?: 'Problem'; id: number; name?: string | null }
        | { __typename?: 'Provider'; id: number; name?: string | null }
        | { __typename?: 'Role'; id: number; name?: string | null }
        | { __typename?: 'Scenario'; id: number; name?: string | null }
        | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
        | { __typename?: 'Tag'; id: number; name?: string | null }
        | { __typename?: 'User'; id: number; name?: string | null }
      > | null;
    }>;
    entities: Array<{
      __typename?: 'Entity';
      name?: string | null;
      values?: Array<
        | { __typename?: 'CareSite'; id: number; name?: string | null }
        | { __typename?: 'EpisodeType'; id: number; name?: string | null }
        | { __typename?: 'Lookup'; id: number; name?: string | null }
        | { __typename?: 'LookupValue'; id: number; name?: string | null }
        | { __typename?: 'Pharmacy'; id: number; name?: string | null }
        | { __typename?: 'Problem'; id: number; name?: string | null }
        | { __typename?: 'Provider'; id: number; name?: string | null }
        | { __typename?: 'Role'; id: number; name?: string | null }
        | { __typename?: 'Scenario'; id: number; name?: string | null }
        | { __typename?: 'SimpleLookup'; id: number; name?: string | null }
        | { __typename?: 'Tag'; id: number; name?: string | null }
        | { __typename?: 'User'; id: number; name?: string | null }
      > | null;
    }>;
    lookups: Array<{
      __typename?: 'ChecklistLookupViewModel';
      id: number;
      name: string;
      enabledValues?: Array<number> | null;
      values: Array<{
        __typename?: 'LookupValue';
        id: number;
        name?: string | null;
        isDeleted?: boolean | null;
      }>;
    }>;
    attributeTypes: Array<
      | {
          __typename?: 'BoolAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
        }
      | {
          __typename?: 'DateAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
        }
      | {
          __typename?: 'DateTimeAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
        }
      | { __typename?: 'ExpressionAttributeType' }
      | {
          __typename?: 'FloatAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
        }
      | {
          __typename?: 'IntAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
          maxLength?: number | null;
          regexMask?: string | null;
          regexErrorText?: string | null;
        }
      | {
          __typename?: 'LookupAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
          multiValues: boolean;
          lookup?: {
            __typename?: 'Lookup';
            id: number;
            name?: string | null;
            isDeleted?: boolean | null;
          } | null;
        }
      | {
          __typename?: 'ReferenceAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
          tableName?: string | null;
          isBigTable: boolean;
          required: boolean;
        }
      | {
          __typename?: 'TextAttributeType';
          type: string;
          name?: string | null;
          displayName: string;
          fullName: string;
          dataType?: string | null;
          maxLength?: number | null;
          regexMask?: string | null;
          regexErrorText?: string | null;
        }
    >;
  } | null;
};

export const AddChecklistActionDocument = `
    mutation addChecklistAction($checklistId: Int!, $actionId: String!) {
  addChecklistAction(checklistId: $checklistId, actionId: $actionId) {
    categories {
      id
      name
      isVisible
      items {
        ...moduleItem
      }
      subCategories {
        id
        name
        items {
          ...moduleItem
        }
      }
    }
    bigTableValues {
      name
      values {
        id
        name
      }
    }
    entities {
      name
      values {
        id
        name
      }
    }
    lookups {
      id
      name
      values {
        id
        name
        isDeleted
      }
      enabledValues
    }
    attributeTypes {
      ...entityAttribute
    }
  }
}
    ${ModuleItemFragmentDoc}
${EntityAttributeFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    addChecklistAction: build.mutation<
      AddChecklistActionMutation,
      AddChecklistActionMutationVariables
    >({
      query: (variables) => ({
        document: AddChecklistActionDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddChecklistActionMutation } = injectedRtkApi;
