import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientRiskTabQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientRiskTabQuery = {
  __typename?: 'EntitiesQuery';
  getPatientRiskTab: {
    __typename?: 'PatientEditRiskTabViewModel';
    patientCareGap: Array<{
      __typename?: 'PatientCareGap';
      id: number;
      patientId: number;
      createdOn: string;
      careGap?: string | null;
      updatedOn?: string | null;
      createdBy: {
        __typename?: 'SimpleLookup';
        id: number;
        name?: string | null;
      };
      priority?: {
        __typename?: 'SimpleLookup';
        id: number;
        name?: string | null;
      } | null;
      status?: {
        __typename?: 'SimpleLookup';
        id: number;
        name?: string | null;
      } | null;
      updatedBy?: {
        __typename?: 'SimpleLookup';
        id: number;
        name?: string | null;
      } | null;
    }>;
    patientRiskClinicalClassification: Array<{
      __typename?: 'PatientRiskClinicalClassification';
      clinicalLabel: string;
      count: number;
    }>;
    patientRiskHistory: Array<{
      __typename?: 'PatientRisk';
      id: number;
      createdOn: string;
      modelName: string;
      tot: any;
      med: any;
      rx: any;
      ip: any;
      op: any;
      phy: any;
      eR: any;
      oth: any;
      pcp: any;
      brx: any;
      risingRiskFlag: any;
      acuityLevel: string;
    }>;
  };
};

export const GetPatientRiskTabDocument = `
    query getPatientRiskTab($patientId: Int!) {
  getPatientRiskTab(patientId: $patientId) {
    patientCareGap {
      id
      patientId
      createdOn
      createdBy {
        id
        name
      }
      careGap
      priority {
        id
        name
      }
      status {
        id
        name
      }
      updatedOn
      updatedBy {
        id
        name
      }
    }
    patientRiskClinicalClassification {
      clinicalLabel
      count
    }
    patientRiskHistory {
      id
      createdOn
      modelName
      tot
      med
      rx
      ip
      op
      phy
      eR
      oth
      pcp
      brx
      rx
      risingRiskFlag
      acuityLevel
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientRiskTab: build.query<
      GetPatientRiskTabQuery,
      GetPatientRiskTabQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientRiskTabDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPatientRiskTabQuery, useLazyGetPatientRiskTabQuery } =
  injectedRtkApi;
