import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import ProviderSearchDialog from 'components/providerSearchDialog/ProviderSearchDialog';
import { IRecentProvider } from 'components/providerSearchDialog/types';
import {
  setSelectedProvider,
  updateSameAsSourceProvider,
} from 'store/actions/checklistSlice';
import ProviderQuickSearch from 'components/providerQuickSearch/ProviderQuickSearch';
import { Box, styled, Typography } from '@mui/material';
import Loader from 'components/loader';
import { COLORS } from 'consts/styles';
import { IActionButtonProps } from './ActionButton';
import { useGetProviderSearchModelQuery } from 'graphql/hooks/getProviderSearchModel';
import { useLazyGetProviderAttributesQuery } from 'graphql/hooks/getProviderAttributes';
import { useLazyGetDummyProviderQuery } from 'graphql/hooks/getDummyProvider';
import moment from 'moment';
import { useLazySearchProvidersQuery } from 'graphql/hooks/searchProviders';
import {
  DummyProvider,
  KeyValuePairOfStringAndString,
} from 'graphql/graphqlTypes';
import { SameAsSourceActionButtonsContainer } from './SameAsSourceActionButtonsContainer';
import { useTimer } from 'react-timer-hook';
import { getProviderSearchDisplayValue } from 'util/helpers/provider.helpers';
import { useAddProviderToRecentProvidersMutation } from 'graphql/hooks/addProviderToRecentProviders';
import Icon, { ICONS } from 'components/icon';
import {
  getProviderRows,
  mapProviders,
  providerType,
  updateProviderAuthRoleHiddenCheckbox,
  updateLinkedComponents,
  updateLinkedRadioButtons,
} from './ProviderSearchActionButton.helper';
import { ADDRESS_COMPONENT_CODE } from 'components/constants';

const ParWarningText = styled(Typography)({
  color: COLORS.RED100,
  marginBottom: '8px',
});
const LimitedSearchWarningStyledBox = styled('div')({
  borderRadius: '16px',
  padding: '8px 18px',
  display: 'flex',
  margin: '16px 32px 16px 64px',
  alignItems: 'center',
  backgroundColor: COLORS.GREY10,
  width: '1000px',
  marginLeft: '1px',
});
const ProviderSearchContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ProviderSearchActionButton = (props: IActionButtonProps) => {
  const {
    component: { uniqueID, value, actionButtonStyle, attributeForUpdate, name },
    storageType,
    actionId,
    item,
    handleChecklistInputChange,
    isReadOnly,
    isUpdateActionValueRequestFailed,
  } = props;
  const providerAuthorizationRole =
    props.component.providerAuthorizationRole ?? '';
  const dispatch = useDispatch();
  const patientId = Number(
    useSelector(
      (state: IState) => state.checklist.documentsState[storageType].patientId
    ) ?? 0
  );
  const buttonProvider = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].providers[
        providerAuthorizationRole
      ]
  );
  const selectedProviderId = buttonProvider?.id ?? 0;
  const selectedProviderAddressId = buttonProvider?.providerAddressId ?? 0;
  const selectedIsSetByUserAction = buttonProvider?.isSetByUserAction ?? false;

  const [searchTerm, setSearchTerm] = useState('');
  const [isAdvancedSearch, setIsAdvancedSearch] = useState<boolean>(false);
  const [favorites, setFavorites] = useState<string[]>([]);
  const [recent, setRecent] = useState<IRecentProvider[]>([]);
  const [searchInformation, setSearchInformation] = useState<
    string | undefined | null
  >();
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [providerAddressUiId, setProviderAddressUiId] = useState<string>(
    selectedProviderId > 0 && selectedProviderAddressId > 0
      ? `${selectedProviderId}_${selectedProviderAddressId}`
      : ''
  );
  const [parWarning, setParWarning] = useState<string>('');

  const modelQuery = useGetProviderSearchModelQuery(
    { patientId, authRole: providerAuthorizationRole },
    { skip: !(patientId > 0), refetchOnMountOrArgChange: true }
  );

  const [getProviderAttributes, providerAttributesQuery] =
    useLazyGetProviderAttributesQuery();
  const [getDummyProvider, dummyProviderQuery] = useLazyGetDummyProviderQuery();

  const [searchProviders, searchQuery] = useLazySearchProvidersQuery();
  const { restart } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => {
      if (
        selectedProviderId === 0 ||
        !selectedIsSetByUserAction ||
        isAdvancedSearch
      ) {
        searchProviders({
          request: {
            searchTerm,
            patientId,
            authRole: providerAuthorizationRole,
          },
        });
      }
    },
  });

  const [addProviderToRecentProviders] =
    useAddProviderToRecentProvidersMutation();

  useEffect(() => {
    const providerId = Number(value);
    if (
      providerId > 0 &&
      providerId !== selectedProviderId &&
      providerAuthorizationRole
    ) {
      handleProviderSelectionInternal(providerId, undefined, false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, providerAuthorizationRole]);

  useEffect(() => {
    if (modelQuery.isFetching || !modelQuery.isSuccess) {
      return;
    }

    const favorite = (modelQuery.data?.getProviderSearchDialogModel
      ?.favorites ?? []) as string[];
    const recentProv = (modelQuery?.data?.getProviderSearchDialogModel
      ?.recent ?? []) as IRecentProvider[];
    const preSelectedProvider =
      modelQuery.data?.getProviderSearchDialogModel?.preSelectedProvider;
    if (
      !Number(value) &&
      preSelectedProvider &&
      preSelectedProvider.providerAddressUiId
    ) {
      setProviderAddressUiId(preSelectedProvider.providerAddressUiId);
      handleProviderSelectionInternal(
        Number(preSelectedProvider.id),
        preSelectedProvider.addressId,
        true,
        false
      );
    }

    setFavorites(favorite);
    setRecent(recentProv);
    setSearchInformation(
      modelQuery.data?.getProviderSearchDialogModel?.searchInformation
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelQuery.isFetching, modelQuery.isSuccess]);

  useEffect(() => {
    if (
      providerAttributesQuery.isFetching ||
      !providerAttributesQuery.isSuccess ||
      !providerAttributesQuery.data?.getProviderAttributes
    ) {
      return;
    }

    setParWarning(
      providerAttributesQuery.data.getProviderAttributes.find(
        (attrib) => attrib?.key === 'Provider.PAR_Warning'
      )?.value ?? ''
    );
    updateLinkedComponents(
      providerAttributesQuery.data
        .getProviderAttributes as KeyValuePairOfStringAndString[],
      dispatch,
      storageType,
      actionId
    );
    updateLinkedRadioButtons(
      providerAttributesQuery.data
        .getProviderAttributes as KeyValuePairOfStringAndString[],
      item,
      handleChecklistInputChange
    );
    updateProviderAuthRoleHiddenCheckbox(
      providerAttributesQuery.data
        .getProviderAttributes as KeyValuePairOfStringAndString[],
      item,
      handleChecklistInputChange
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerAttributesQuery.isFetching, providerAttributesQuery.isSuccess]);

  useEffect(() => {
    if (dummyProviderQuery.isFetching || !dummyProviderQuery.isSuccess) {
      return;
    }

    const dummyProvider = dummyProviderQuery.data.getDummyProvider;
    if (dummyProvider) {
      handleDummyProviderSelection(dummyProvider);
    } else {
      dispatch(
        showErrorPopup({
          message: 'Dummy Provider is not configured for this care site',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dummyProviderQuery.isFetching, dummyProviderQuery.isSuccess]);

  useEffect(() => {
    if (searchTerm.length < 3) {
      return;
    }

    const now = new Date();
    restart(new Date(now.getTime() + 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, patientId]);

  useEffect(() => {
    const handleSelectedByUserAction = async () => {
      getProviderAttributes({
        providerId: selectedProviderId,
        providerAddressId: selectedProviderAddressId,
        patientId,
      });
      await addProviderToRecentProviders({ providerId: selectedProviderId });
      const oldRecent = recent.filter((r) => r.id !== selectedProviderId);
      setRecent([
        ...oldRecent,
        {
          id: selectedProviderId,
          added: moment().toDate(),
        } as IRecentProvider,
      ]);
    };
    if (selectedProviderId > 0 && selectedIsSetByUserAction) {
      handleChecklistInputChange(
        selectedProviderId.toString(),
        props.component
      );
      if (selectedProviderAddressId > 0) {
        handleChecklistInputChange(
          selectedProviderAddressId.toString(),
          props.component,
          `${props.component.id}-${ADDRESS_COMPONENT_CODE}`
        );
      }
      handleSelectedByUserAction().catch((error) =>
        // eslint-disable-next-line no-console
        console.error(error)
      );
      if (attributeForUpdate) {
        dispatch(
          // SYM-18294: See comment in reducer
          updateSameAsSourceProvider({
            storageType,
            attributeForUpdate,
            provider: selectedProviderId,
            uniqueID,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProviderId,
    selectedProviderAddressId,
    selectedIsSetByUserAction,
  ]);

  const handleProviderSelection = (
    selectedProvider: number,
    providerAddressId: number
  ) => {
    handleProviderSelectionInternal(
      selectedProvider,
      providerAddressId,
      true,
      true
    );
  };

  const handleCantFindProvider = () => {
    getDummyProvider({
      patientId,
      authRole: providerAuthorizationRole,
    });
  };

  const handleProviderSelectionInternal = (
    selectedProvider: number,
    providerAddressId: number | null | undefined,
    isSetByUserAction: boolean,
    isSetSearchTerm: boolean
  ) => {
    if (isSetSearchTerm) {
      const row = filteredRows.find(
        (p) =>
          Number(p.id) === selectedProvider && p.addressId === providerAddressId
      );
      const newDisplayValue = getProviderSearchDisplayValue(row);
      setSearchTerm(newDisplayValue);
    }

    dispatch(
      setSelectedProvider({
        storageType,
        role: providerAuthorizationRole,
        provider: selectedProvider,
        providerAddressId: providerAddressId,
        isSetByUserAction: isSetByUserAction,
      })
    );
  };

  const handleDummyProviderSelection = (dummyProvider: DummyProvider) => {
    const newDisplayValue = getProviderSearchDisplayValue(dummyProvider);
    setSearchTerm(newDisplayValue);
    setProviderAddressUiId(dummyProvider.providerAddressUiId ?? '');
    dispatch(
      setSelectedProvider({
        storageType,
        role: providerAuthorizationRole,
        provider: Number(dummyProvider.id),
        providerAddressId: dummyProvider.addressId,
        isSetByUserAction: true,
      })
    );
  };

  const useDefaultProviders =
    searchTerm.length < 3 || searchQuery.isFetching || !searchQuery.isSuccess;

  const filteredRows = getProviderRows(
    useDefaultProviders,
    modelQuery.data,
    searchQuery.data,
    providerAuthorizationRole
  );

  const providers = mapProviders(filteredRows, favorites, recent);

  return (
    <>
      <ProviderSearchContainer data-testid="ProviderSearchActionButton">
        {parWarning && <ParWarningText>{parWarning}</ParWarningText>}
        {searchInformation && (
          <LimitedSearchWarningStyledBox>
            <Icon
              icon={ICONS.Information}
              size={16}
              color={COLORS.GREY100}
              style={{ marginTop: '-2px' }}
            />
            <Typography style={{ paddingLeft: '10px', color: COLORS.GREY100 }}>
              {searchInformation}
            </Typography>
          </LimitedSearchWarningStyledBox>
        )}
        <Box display="flex">
          <Box marginTop="6px" marginRight="16px">
            <Typography variant="body2" style={{ color: COLORS.GREY100 }}>
              {name}
            </Typography>
          </Box>
          <ProviderQuickSearch
            isLoading={modelQuery.isFetching || searchQuery.isFetching}
            providers={providers}
            searchTerm={searchTerm}
            useDefaultProviders={useDefaultProviders}
            onSelect={handleProviderSelection}
            onSearch={setSearchTerm}
            onViewAllClick={() => setAdvancedSearchOpen(true)}
            onCantFindClick={handleCantFindProvider}
            providerAddressUiId={providerAddressUiId}
            onSelectProviderAddressUiId={setProviderAddressUiId}
            isReadOnly={isReadOnly}
            isUpdateActionValueRequestFailed={isUpdateActionValueRequestFailed}
            providerType={providerType(providerAuthorizationRole)}
          />
          <Button
            data-testid="Button"
            color="primary"
            variant={actionButtonStyle === 'button' ? 'contained' : 'text'}
            aria-required={props.component.required}
            onClick={() => setAdvancedSearchOpen(true)}
            disabled={isReadOnly}
          >
            Advanced Search
          </Button>
        </Box>
      </ProviderSearchContainer>
      <SameAsSourceActionButtonsContainer
        {...props}
        onProviderSelect={(id, addressId) =>
          handleProviderSelection(id, addressId)
        }
      />
      <ProviderSearchDialog
        open={advancedSearchOpen}
        patientId={patientId}
        providers={filteredRows}
        recent={recent}
        favorites={favorites}
        providerType={providerType(providerAuthorizationRole)}
        isLoading={
          (searchQuery.isFetching || modelQuery.isFetching) && searchTerm !== ''
        }
        onClose={() => {
          setAdvancedSearchOpen(false);
        }}
        onSelect={handleProviderSelection}
        onCantFind={handleCantFindProvider}
        onSelectProviderAddressUiId={setProviderAddressUiId}
        onSearch={setSearchTerm}
        updateFavorites={setFavorites}
        setIsAdvancedSearch={setIsAdvancedSearch}
      />
      <Loader
        active={
          dummyProviderQuery?.isFetching ||
          (providerAttributesQuery?.isFetching && searchTerm !== '')
        }
      />
    </>
  );
};
