export type DeterminationDecision =
  | 'Approved'
  | 'Partial'
  | 'Pending'
  | 'Denied'
  | 'NonSet';

export enum HealthServiceAuthorizationType {
  NonSet = 0,
  LineItem = 1,
  Episode = 2,
}

export enum HealthServiceConditionId {
  approved = '1',
  denied = '2',
  pending = '3',
  partial = '4',
}

export const hsVersion = 'HSV3';

export enum DecisionAction {
  nonSet = 'NonSet',
  approved = 'Approved',
  pending = 'Pending',
  partial = 'Partial',
  denied = 'Denied',
}

export enum UnitTypes {
  days = 'Days',
  units = 'Units',
}
