import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';

import { ChangeSet } from '@devexpress/dx-react-grid';
import { getChangedRows, useData } from 'components/gridFormatters';
import {
  updateDirtyTabs,
  updatePatientRisks,
} from 'store/patientdetails/patientDetailsSlice';
import { MOMENT_DATE_FORMAT } from 'components/constants';
import moment from 'moment';
import {
  GetPatientRiskTabQuery,
  useLazyGetPatientRiskTabQuery,
} from 'graphql/hooks/getPatientRiskTab';
import {
  ILookupValue,
  PatientCareGap,
  PatientEditRiskTabViewModel,
} from 'graphql/graphqlTypes';

const updateCareGapsRows = <T extends PatientCareGap>(
  changeSet: ChangeSet,
  rows: T[]
): T[] => {
  if (!changeSet) {
    return rows;
  }
  return getChangedRows(changeSet, rows);
};

export const useCommitRisksChanges = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: IState) => state.user.currentUser);
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const { data: risksData } = useData(
    (state: IState) => state.patientDetails.risksTab,
    useLazyGetPatientRiskTabQuery,
    { patientId },
    (data: GetPatientRiskTabQuery) =>
      updatePatientRisks(data.getPatientRiskTab as PatientEditRiskTabViewModel),
    !patientId
  );

  if (!risksData) {
    return {
      risksData,
      careGapsCommitChanges: () => void 0,
      saveCareGapsChanges: () => void 0,
    };
  }

  const careGapsCommitChanges = (changeSet: ChangeSet) => {
    const changedIds: string[] = changeSet.changed
      ? Object.keys(changeSet['changed'])
      : [];
    const careGaps = updateCareGapsRows(changeSet, risksData.patientCareGap);
    const updatedCareGaps = careGaps.map((careGap) =>
      careGap.id > 0 && changedIds.includes(careGap.id.toString())
        ? {
            ...careGap,
            updatedOn: moment().format(MOMENT_DATE_FORMAT),
            updatedBy: {
              id: currentUser.id,
              name: currentUser.name,
            } as ILookupValue,
          }
        : careGap
    );
    dispatch(
      updatePatientRisks({ ...risksData, patientCareGap: updatedCareGaps })
    );
  };

  const saveCareGapsChanges = (changeSet: ChangeSet) => {
    careGapsCommitChanges(changeSet);
    dispatch(updateDirtyTabs('Background'));
  };

  return {
    risksData,
    careGapsCommitChanges,
    saveCareGapsChanges,
  };
};
